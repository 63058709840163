@import '../global/variables';
@import 'bootstrap/scss/mixins/breakpoints';

[class*="col-"] {
    padding-right: $grid-gutter-width/2;
    padding-left: $grid-gutter-width/2;
}

.row {
    margin-right: -($grid-gutter-width/2);
    margin-left: -($grid-gutter-width/2);
}

#maincontent .full-width {
    @include media-breakpoint-down(md) {
        margin-left: -($grid-gutter-width);
        margin-right: -($grid-gutter-width);
    }
}
