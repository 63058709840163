.single-shipping {
    .access-points-search-container {
        width: 840px;
        height: auto;
    }

    .access-points-locations {
        width: 100%;
        padding-right: 5px;
        display: inline-block;
        height: 500px;
        overflow: auto;
        overflow-x: hidden;
    }

    .access-points-map {
        display: inline-block;
        border: 2px solid #999;
        width: 100%;
        height: 456px;
    }

    .ap-tile-body {
        .name {
            font-weight: 500;
        }

        .name,
        .address,
        .phone,
        .distance {
            margin-bottom: 5px;
        }
    }

    .ap-tile-select {
        margin-top: 2px;
    }

    .display-name {
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
}

.addressAutocompleteContainer {
    position: absolute;
    min-width: 250px; // set width programatically by input width
    min-height: 60px;
    top: 52px;
    left: 7px;
    background: #fff;
    border: solid 1px #e5e5e5;
    border-top: none;
    padding-bottom: 18px; // reserved room for the google credit
    z-index: 999;
    display: none;

    .addressSuggestion {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        border-bottom: solid 1px #e5e5e5;
        font-size: 12px;
        padding: 10px 5px;
        cursor: pointer;

        &:hover,
        &.hascursor {
            background: #323232;
            color: #fff;
        }

        &:active,
        &.hascursor.selecting {
            background: #e21836;
        }

        .match {
            font-weight: 500;
        }

        &.current-location {
            position: relative;
            padding-left: 24px;

            &.after {
                content: "\e60d";
                position: absolute;
                left: 8px;
                top: 7px;
                font-size: 130%;
            }
        }
    }

    .googleCredit {
        width: 100%;
        height: 16px;
        position: absolute;
        bottom: 0;
        background: #fff url(../images/powered-by-google-on-white.png) no-repeat center right;
    }
}
