@import "../variables";

$search-field-min: 1130px;

.search-mobile.search-active {
    .site-search {
        .input-wrapper {
            @include media-breakpoint-down(md) {
                position: fixed;
                z-index: 10;
                width: 90%;
                background: $white;
            }
        }

        .close {
            opacity: 1;

            &:hover {
                opacity: 1;
            }
        }
    }
}

.suggestions {
    border: 1px solid transparent;
    top: 1.32rem;
    right: -15rem;
    width: auto;
    color: $black;
    @include media-breakpoint-down(md) {
        width: 100% !important;
        top: 2.5rem !important; // overriding the base positioning
    }

    &.four-tile-width {
        width: 70rem;
    }

    &.two-tile-width {
        width: 70rem;

        .tile-items {
            width: 65%;

            .item {
                max-width: 100%;
                flex: 0 0 100%;
            }
        }
    }

    &.small-desktop {
        width: 60rem;
        right: -10rem;
    }

    &.no-tiles {
        width: 50rem;
    }

    .container {
        padding-left: rem(16);

        @include media-breakpoint-up(lg) {
            padding: 0 0 3rem 7.5rem;
        }
    }

    .item {
        padding-bottom: 1rem;
        padding-right: rem(24);

        @include media-breakpoint-down(lg) {
            padding-bottom: rem(8);
            padding-right: rem(8);
        }

        .name {
            font-size: $font-size16;
            line-height: $font-size32;
            margin: 0;
        }

        .search-phrase {
            font-size: $font-size16;
            line-height: $font-size24;
            font-weight: $fontweight-regular;

            a {
                font-weight: $fontweight-semibold;
            }

            @include media-breakpoint-down(md) {
                font-size: $font-size14;
                line-height: $font-size40;
            }
        }
    }

    .modal-background {
        display: block;
        top: 36%;
    }

    .header {
        font-size: $font-size20;
        font-weight: $fontweight-medium;
        line-height: $font-size32;
        color: $black;
        @include media-breakpoint-down(md) {
            font-size: $font-size16;
            line-height: $font-size20;
        }

        &:not(:first-child) {
            border-top: 1px solid transparent;
        }
    }

    .search-terms-suggestions {
        float: right;
        width: 33%;
        margin-bottom: 3rem;
        @include media-breakpoint-down(md) {
            width: 100%;
            float: left;
            margin-bottom: 0.5rem;
            padding-top: 0;
        }

        .item {
            padding-bottom: 0;
            padding-right: rem(24);
        }

        .items {
            width: 100%;
        }
    }

    .product-items,
    .trending-products {
        float: left;
        width: 67%;
        @include media-breakpoint-down(md) {
            width: 100%;
        }

        &.no-products-found {
            font-size: $font-size20;
            font-weight: $fontweight-medium;
            line-height: $font-size32;
            text-align: center;
            width: 65%;
            @include media-breakpoint-down(md) {
                text-align: left;
                padding: 1rem;
                font-size: $font-size16;
                line-height: $font-size24;
                width: 100%;
            }
        }

        &.no-tiles,
        &.search-focus {
            width: 65%;
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }
    }

    .product-items {
        #product-4 {
            display: none;
        }
    }

    .product-tile {
        min-height: auto;
        line-height: 0px;

        .wishListToggle,
        .quickview,
        .badges,
        .arTryOn-badge,
        .tile-badges,
        .tile-body-footer {
            display: none;
        }

        .quick-view-button-ctn {
            display: none;
        }

        .image-container {
            width: rem(152);
            display: inline-block;
            padding-right: 1rem;
            box-sizing: border-box;

            @include media-breakpoint-down(md) {
                width: rem(88);
                padding-right: rem(8);
            }

            .next-main-image {
                display: none;
            }
        }

        .tile-body {
            width: 49%;
            display: inline-block;
            vertical-align: top;
            padding: 0;
            @include media-breakpoint-down(md) {
                width: 66%;
            }

            .search-tile {
                justify-content: flex-start !important;
            }

            .pdp-link {
                a {
                    font-size: $font-size16;
                    font-weight: $fontweight-semibold !important; // overridind bootstrap css
                    line-height: $font-size24;
                    color: $black;
                    @include media-breakpoint-down(md) {
                        font-size: $font-size14;
                        line-height: $font-size20;
                    }
                }

                .category-name {
                    line-height: $font-size20;
                    padding-top: 0 !important;
                }
            }

            .price,
            .sales,
            .value {
                color: $black;
                padding-top: $font-size16;
                font-size: $font-size16;
                font-weight: $fontweight-semibold !important;
                line-height: $font-size24;
                @include media-breakpoint-down(md) {
                    font-size: $font-size14;
                    line-height: $font-size20;
                    padding-top: 0;
                }
            }

            .strike-through {
                display: flex;

                .value {
                    padding-top: 0;
                    color: $dark-grey;
                    font-weight: $fontweight-regular !important; //overriding parent with !important
                }
            }

            .pgp-reviews-wrapper {
                height: unset;
            }

            .promotions {
                padding-top: 5px;

                .callout {
                    font-size: 0.75rem;
                    line-height: 1rem;
                }
            }
        }

        .pgp-grid {
            .fw-search {
                width: 100%;
                display: inline-block;
                max-width: 100%;
                flex: 0 0 100%;
            }

            &.pb-2 {
                padding-bottom: 0 !important;
            }
        }
    }
}

.site-search {
    height: 4rem;

    @include media-breakpoint-up(sm) {
        height: 2.5rem;
    }

    .search-field {
        font-size: $font-size16;
        line-height: rem(24);
        @media only screen and (min-width: $search-field-min) {
            font-size: $font-size16;
        }
    }
}

.seach-modal-bg {
    background-color: $black;
    height: 100%;
    position: fixed;
    opacity: 0.5;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
}

.search-mobile {
    &.search-active {
        @include media-breakpoint-down(md) {
            .suggestions {
                position: static;
            }

            .site-search {
                position: fixed;
                top: 0;
                width: 100% !important;
                left: 0;
                background: $white;
                height: 100%;
                overflow-y: auto;
            }

            .search-button {
                display: none;
            }

            input.search-field {
                width: calc(100% - 32px);
                margin: rem(8) 1rem;
            }

            .close-search {
                position: fixed;
                right: 0;
                top: rem(10);
                z-index: 21;
                padding: 0.5rem 1rem !important; // bootstrap override
            }
        }
    }
}

.error-page {
    .logo-icon {
        height: rem(24);
    }

    .header-navigation {
        border-bottom: 1px solid $silver-grey;
    }

    .navbar-header {
        height: rem(48);
    }

    @include media-breakpoint-up(md) {
        .error-heading {
            font-family: $body-font-family;
            font-size: rem(20);
            color: $black;
            letter-spacing: 0;
            line-height: rem(32);
            font-weight: $fontweight-semibold;
        }
    }

    .maintanence-container-error {
        .error-logo {
            margin-left: 43%;
            margin-bottom: -40px;
            margin-top: 30px;
            @include media-breakpoint-only(md) {
                margin-left: 38%;
            }
            @include media-breakpoint-down(sm) {
                margin-left: 25%;
            }
        }
    }
}
