@import "~base/components/formFields";
@import "../global/colors";
@import "../mixins/util";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: $nb-black !important;
    -webkit-box-shadow: 0 0 0 100px white inset !important;
}

.form-control {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $nb-black;
    padding: rem(24) 0 rem(3);
    font-size: $font-size14;
    font-weight: $fontweight-regular;
    line-height: $font-size20;
    min-height: 1.5rem;
    color: $black;
    height: auto;
}

.custom-checkbox {
    .custom-control-input + .custom-control-label {
        top: 0; // To override form input label properties
        pointer-events: all;

        &::before {
            color: $white;
            border-color: none; // overriding bootstrap style
            background-color: transparent;
            box-shadow: none;
        }

        &::after {
            background-image: url('../../images/unselected.svg');
            background-size: cover;
            background-color: $white;
        }
    }

    .custom-control-input:focus ~ .custom-control-label,
    .custom-control-input:focus ~ .custom-control-label,
    .custom-control-input:focus:not(:checked) ~ .custom-control-label,
    .custom-control-input:focus:not(:checked) ~ .custom-control-label {
        &::after {
            outline: 2px auto highlight;
            outline: 2px auto -webkit-focus-ring-color;
            background: transparent;
            outline-offset: 2px;
        }
    }

    .custom-control-input:focus:not(:checked) ~ .custom-control-label,
    .custom-control-input:focus:not(:checked) ~ .custom-control-label {
        &::before,
        &::after {
            border: none;
        }
    }

    .custom-control-input:checked + .custom-control-label {
        &::after {
            background-image: url('../../images/selected.svg');
        }
    }
}

.custom-radio {
    position: relative;

    .form-check-input,
    .custom-check-input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    .form-check-label {
        pointer-events: all;

        &::before {
            position: absolute;
            top: 0.25rem;
            left: -1.5rem;
            display: block;
            width: 1rem;
            height: 1rem;
            pointer-events: none;
            content: "";
            background-color: $white;
            border: transparent solid 1px;
        }

        &::after {
            position: absolute;
            top: rem(2);
            left: 0;
            display: block;
            content: "";
        }
    }

    .form-check-input + .form-check-label,
    .custom-check-input + .form-check-label {
        &::after {
            background-image: url('../../images/inactive.svg');
            width: 16px;
            height: 16px;
            background-size: cover;
        }
    }

    .form-check-input:focus ~ .form-check-label,
    .form-check-input:focus:not(:checked) ~ .form-check-label,
    .form-check-input:focus ~ .form-check-label,
    .form-check-input:focus:not(:checked) ~ .form-check-label {
        &::after {
            outline: 2px auto highlight;
            outline: 2px auto -webkit-focus-ring-color;
            outline-offset: 2px;
        }
    }

    .form-check-input:checked + .form-check-label,
    .custom-check-input:checked + .form-check-label {
        &::after {
            background-image: url('../../images/active.svg');
            width: 16px;
            height: 16px;
            background-size: cover;
        }
    }
}

// floating label styles
.form-group {
    position: relative;
}

.form-group > input,
> label {
    padding: var(--input-padding-y) var(--input-padding-x);
}

.form-group > label {
    position: absolute;
    top: 1.5rem;
    left: 0;
    display: block;
    //   width: 100%;
    font-size: $font-size14;
    font-weight: $fontweight-semibold;
    line-height: $font-size20;
    margin-bottom: 0; /* Override default `<label>` margin */
    transition: all 0.1s ease-in-out;
    pointer-events: none;
}

.form-group {
    input:not([type="radio"]):not([type="checkbox"]),
    textarea {
        &::-webkit-input-placeholder,
        &::placeholder {
            color: transparent;
        }

        &:focus,
        &:not(:placeholder-shown) {
            padding: rem(24) 0 rem(3);
            min-height: rem(20);
            color: $black;
            font-weight: $fontweight-regular;
            background: transparent;
        }

        &:focus ~ label,
        &:-webkit-autofill ~ label,
        &:not(:placeholder-shown) ~ label {
            padding-top: 0;
            padding-bottom: 0;
            font-size: $font-size12;
            line-height: 1.333;
            height: 1rem;
            color: $black;
            top: 0.5rem;
            font-weight: $fontweight-regular;
        }
    }

    &.required {
        .form-control-label {
            &::after {
                content: '*';
                color: $danger;
            }

            &::before {
                content: none;
                color: $white;
            }
        }
    }

    select:not([value=""]):valid ~ label {
        top: 0.5rem;
        font-size: $font-size12;
        color: $black;
        font-weight: $fontweight-regular;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.asterisk {
    color: $red;
}

#login,
#register,
#email-form {
    .form-group {
        &.required {
            .form-control-label {
                &::after {
                    content: none;
                    color: $white;
                }
            }
        }
    }
}
