@import "menu";

$banner-padding: 0.3125em;
$menu-padding: 0.5em;
$menu-item-margin: 0.625em;
$search-input-min: 1240px;
$tablet-breakpoint: 991px;

.nav-chevron-right {
    @include media-breakpoint-down(md) {
        position: relative;

        &::before {
            content: '';
            background-image: url('../images/right-icon.svg');
            height: 16px;
            width: 16px;
            background-size: contain;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

body {
    &.fixed {
        position: fixed;
    }
}

.navbar-header {
    height: 4.375em; /* 70/16 */

    .user,
    .country-selector,
    .link-selector,
    .minicart,
    .navbar-toggler {
        line-height: 2.25em; /* 36/16 */
        height: auto;
    }

    &--has-scrolled {
        background: white;
    }

    .navbar-toggler {
        font-size: 1.6em;
        width: auto;
        transition: 0.3s cubic-bezier(0.64, 0.04, 0.35, 1);
        padding: 0;
        margin: 0;
        height: 18px;
        right: 1rem;
        line-height: 0;

        &--is-active {
            position: unset;
        }

        &__container {
            height: 16px;
            margin-left: rem(24);
            position: relative;
        }
    }

    .search-field {
        background-color: transparent;

        &:focus {
            background-color: #ededed;
        }
    }

    .user {
        position: relative;

        .popover {
            position: absolute;
            display: none;
            padding: 1em;
            top: 85%;
            left: 0;

            a {
                white-space: nowrap;
                margin-bottom: 0.5em;
            }

            &::before {
                left: 1.5rem;
            }

            &::after {
                left: 1.5rem;
            }

            &.show {
                display: block;
            }
        }
    }
}

.brand {
    position: absolute;
    left: 50%;
    display: block;
    text-align: center;

    img {
        width: 100%;
    }

    @include media-breakpoint-up(lg) {
        width: 14.125em; /* 226/16 */
        margin-left: -7.0625em; /* 113/16 */
        padding-top: 0.5em;
    }

    @include media-breakpoint-down(md) {
        width: 4em; /* 64/16 */
        margin-left: -2em;
        padding-top: 0.8em;
    }
}

.main-menu {
    .navbar .close-menu button,
    .navbar .close-button button {
        background-color: #efefef;
        border: 0;
        -webkit-appearance: none;
        padding: 5px 8px 7px !important;
    }
}

.header-banner {
    background-color: $nb-black;
    text-align: center;
    color: $white;

    > .container {
        min-height: rem(38);
        max-width: none;
        @include media-breakpoint-up(lg) {
            min-height: rem(52);
        }
    }

    .close-button {
        width: 1.5em + $banner-padding * 2;

        .close {
            opacity: 1;
            color: $white;
            width: 100%;
            height: 100%;
            background-color: $primary;
        }
    }

    .content {
        padding-top: $banner-padding;
        padding-bottom: $banner-padding;
    }

    .header-promotion {
        color: $white;
    }
}

.minicart {
    vertical-align: top;

    .minicart-icon {
        font-size: 1.5em;
    }

    a.minicart-link {
        &:hover {
            text-decoration: none;
        }
    }

    .minicart-quantity {
        background-color: $nb-red;
        border-radius: 50%;
        min-width: 1.3em; /* 20/16 */
        min-height: 1.3em; /* 20/16 */
        line-height: normal;
        display: inline-block;
        text-align: center;
        font-size: 0.8125em; /* 13/16 */
        position: absolute;
        top: -0.3rem; /* 15/16 */
        right: -0.68rem; /* 11/16 */
        color: $white;
        padding: 1px;
        box-sizing: content-box;
        @include media-breakpoint-up(lg) {
            top: 1rem;
            right: -0.25rem;
        }
    }
}

a.normal {
    color: $primary;
    text-decoration: underline;
}

.slide-up {
    transition-duration: 0.3s;
    transition-timing-function: ease-in;
    max-height: 100px;
    overflow: hidden;

    &.hide {
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
}

.dropdown-country-selector,
.dropdown-link-selector {
    margin-top: -0.0625em;

    .dropdown-item:focus {
        background-color: transparent;
    }
}

.cookie-warning-messaging.cookie-warning {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    display: none;
}

.valid-cookie-warning {
    background-color: $blue;
    color: $white;
    white-space: nowrap;

    p {
        margin-top: 0;
        margin-bottom: 0.2em;
        padding-right: 2em;
    }
}

/* adding the custom styles to header */

.header-container {
    .header-navigation {
        background: $white;
        padding: 0;
        margin: 0;
        border-bottom: 0;

        &.hide-navigation {
            margin: -120px;
            visibility: hidden;
        }

        @include media-breakpoint-up(md) {
            border-bottom: solid 1px $gallery-solid;
        }

        .header-nav {
            @include media-breakpoint-up(lg) {
                max-width: none !important;
                padding-left: rem(40);
                padding-right: rem(40);
            }

            @include media-breakpoint-down(md) {
                padding-left: rem(8);
                padding-right: rem(8);
            }
        }

        .search {
            width: 100%;
            max-width: 100%;
            @include media-breakpoint-up(lg) {
                max-width: 207px;
            }
        }

        .search-mobile.search-active .site-search .input-wrapper {
            width: 90%;
        }
    }

    &--overlapped {
        min-height: 0 !important;
    }

    @include media-breakpoint-up(md) {
        &--transition {
            &.open-search .header-navigation {
                background: $white;
            }

            .header-navigation {
                background: transparent;
                border-color: transparent;
            }
        }
    }

    .header-fixed {
        z-index: 999;
        left: 0;
        top: 0;
    }

    .find-a-store {
        font-weight: $fontweight-medium;
    }

    .select-country:hover,
    .select-country:focus-within {
        .dropdown-menu {
            display: block;
        }
    }

    .select-country,
    .select-header-links {
        height: 52px;
        display: flex;
    }

    .locale-selector:hover,
    .locale-selector:focus-within {
        .language-selector {
            display: block;
        }

        .dropdown-open::after {
            transform: translateY(-50%) rotate(180deg);
        }
    }

    .help-links:hover,
    .help-links:focus-within {
        .link-selector {
            display: block;
        }

        .dropdown-open::after {
            transform: translateY(-50%) rotate(180deg);
        }
    }

    .country-selector-one {
        font-weight: $fontweight-medium;

        .country-img {
            min-width: 45px;
            min-height: 23px;

            img {
                height: rem(16);
            }

            @include media-breakpoint-down(md) {
                display: initial;
                justify-content: initial;
                max-width: 1.03rem;

                img {
                    max-width: 100%;
                    height: 0.75rem;
                }
            }
        }

        .country-code {
            padding-top: 2px;
        }

        .canada {
            display: flex;
            justify-content: flex-end;

            img {
                height: rem(16);

                @include media-breakpoint-up(md) {
                    height: rem(20);
                }
            }
        }

        &.no-arrow {
            &::after {
                content: none;
            }
        }

        .country-name {
            color: $white;

            @include media-breakpoint-down(md) {
                line-height: rem(20);
            }
        }

        .link-name {
            color: $white;

            @include media-breakpoint-down(md) {
                line-height: rem(20);
            }
        }

        .dropdown-open {
            &::after {
                content: '';
                background-image: url('../images/cheveron-down.svg');
                height: 12px;
                width: 12px;
                background-size: contain;
                position: relative;
                left: 5px;
                bottom: -4px;
                transform: translateY(-50%);
            }
        }

        a {
            padding: rem(2) 0;
        }
    }

    .dropdown-country-selector {
        left: auto;
        right: 3.8rem;
        top: 52px;
        background-color: $white;
        min-width: 200px;

        .dropdown-item {
            color: $nb-black;
            padding: 0.25rem 1.5rem 0.25rem 1rem;
            letter-spacing: 0.4px;
        }

        .dropdown-item:hover {
            text-decoration: underline;
            background-color: #f2f2f3b5;
            border: 0 #f2f2f3b5;
            border-radius: 5px;
        }
    }

    .dropdown-link-selector {
        left: auto;
        right: -12px;
        top: 52px;
        background-color: $white;
        min-width: 200px;
        z-index: 9999;

        .dropdown-item {
            color: $nb-black;
            letter-spacing: 0.4px;
            @include media-breakpoint-up(lg) {
                padding: 0.25rem 1.5rem 0.25rem 1rem;
            }
        }

        .dropdown-item:hover {
            text-decoration: underline;
            @include media-breakpoint-up(lg) {
                background-color: #f2f2f3b5;
                border: 0 #f2f2f3b5;
                border-radius: 5px;
            }
        }
    }

    .search-mobile {
        margin-bottom: rem(8);

        &.hide {
            display: none;
        }

        &.show {
            display: block;

            @include media-breakpoint-down(md) {
                margin-bottom: 2px;
            }
        }
    }

    @include media-breakpoint-up($next-breakpoint) {
        .logo-home {
            padding: 0 1rem 0 0;
        }
    }

    .site-search {
        .reset-button {
            right: 18%;
            top: 20px;
            font-weight: $fontweight-bold;
        }
    }
}

.search-input-mr {
    margin-right: 1.5rem !important;
}

.search-mobile.search-active .reset-button {
    @include media-breakpoint-down(md) {
        z-index: 21;
        position: fixed;
    }
}

.site-search {
    width: 100%;
    max-width: 100%;
    z-index: 1;
    @include media-breakpoint-up(lg) {
        display: flex !important;
    }

    .input-wrapper {
        width: 100%;
    }

    .search-field {
        padding: 7px;
        border: 1px solid #d7d7d7;
        font-size: rem(16);
        font-weight: $fontweight-light;
        height: rem(42);
        @include media-breakpoint-up(lg) {
            height: rem(35);
            background-color: #ededed;
        }

        &::placeholder {
            color: $black;
        }

        &:focus {
            box-shadow: none;
            color: $black;
            border-bottom-color: #d7d7d7;
        }
    }

    .search-button {
        .svg-icon {
            padding-bottom: rem(4);
            margin-top: rem(2);
        }
    }
}

.nb-search {
    .site-search {
        .search-field {
            padding: 8px;
            padding-left: 2.625rem;
            border-radius: 4px !important;
            font-weight: 400;
            font-family: 'Proxima Nova', sans-serif;
        }

        .search-button {
            left: 6px;
            top: 9px;
            @include media-breakpoint-up(md) {
                top: 7px;
            }
        }
    }
}

.user {
    .separator {
        width: 1px;
        height: 13px;
        bottom: -1px;
        background-color: $nb-black;
    }

    .user-message,
    .myaccount {
        font-size: 1rem;
        font-weight: $fontweight-medium;
        line-height: 1rem;
    }

    .auth-user {
        .user-message,
        .myaccount {
            font-size: $base-font;
        }

        .user-message {
            flex: 0;
        }
    }

    span.profile {
        max-width: 25%;
    }
}

.account-links {
    a {
        span.login-bubble {
            width: 8px;
            height: 8px;
            transform: translate(-50%, -50%);
        }
    }
}

.promobarClose.show {
    + {
        .promobarOpen {
            pointer-events: none;
        }
    }
}

.banner-right {
    position: absolute;
    top: 0;
    right: 2rem;

    button,
    a {
        color: $white;
    }

    .help-links {
        .nav-link {
            padding: 0;
        }
    }

    .d-lg-block {
        margin: 7px 0 0 10px;
    }

    .dropdown-menu {
        div.d-none.py-2 {
            display: flex !important;
            flex-direction: column;

            .find-a-store {
                color: black;
            }
        }
    }

    .separator {
        width: 0.0625rem;
        background-color: #fff;
        height: 0.75rem;
        position: relative;
        top: 1.2rem;
    }
}

.banner-left {
    top: 0;
    left: 0;
    z-index: 1;

    .selected-store {
        line-height: rem(18);
        vertical-align: middle;
    }

    .store-locator-icon {
        width: 1rem;
    }

    button,
    a {
        color: $white !important;
    }
}

#Promo_bar_details_Container {
    background-color: $white-smoke;
    z-index: 99;
    max-height: 50vh;
    left: 0;
    overflow: hidden scroll;
}

.header-fixed {
    .overlay {
        left: 0;
        top: 0;
        height: 100vh;
        background: $grey-transparent;
    }
}

.header-part-store {
    width: 150px;
}

/* Need Help CSS for service cloud chat */

.emea-help-chat {
    .trigger {
        position: fixed;
        right: rem(20);
        bottom: rem(10);
        background-color: $nb-red;
        color: $white;
        text-align: center;
        border-radius: rem(56);
        height: rem(48);
        cursor: pointer;
        border: none;
        z-index: 99;
        line-height: 1;
        @include media-breakpoint-up(md) {
            right: rem(65);
            bottom: rem(65);
        }
    }

    .links-survery {
        cursor: pointer;
    }

    .modal {
        &:not(.nb-modal) {
            position: fixed;
            top: auto;
            right: auto;
            left: auto;
            bottom: rem(90);
            height: rem(300);

            .service-content {
                border-radius: rem(20) rem(20) 0 0;
                overflow: hidden;
                @include media-breakpoint-up(md) {
                    border-radius: rem(20);
                    border: 1px solid $nb-black;
                }
            }

            .modal-header {
                align-items: center;
            }

            .modal-body {
                flex: inherit;
            }
        }
    }

    .service-close {
        right: rem(8);
        font-size: 2.5rem;
        top: rem(24);
        line-height: 1;
        width: rem(40);
        @include media-breakpoint-up(md) {
            top: rem(14);
        }
    }

    #emeaHelpModal {
        bottom: 0;
        height: 100vh;
    }

    .js-question-mark {
        &:active {
            background: $black !important;
            border: $black !important;
        }
    }

    .chat-icon-footer {
        svg {
            filter: invert(1);
            fill: $white;
        }
    }

    .modal.bottom {
        .modal-dialog {
            transform: translate3d(0%, 0, 0);
        }
    }

    .modal.bottom.fade {
        .modal-dialog {
            transform: translate3d(0, 100vh, 0);
            transition: transform 0.2s ease-in-out;
        }
    }

    .modal.bottom.fade.show {
        .modal-dialog {
            height: auto;
            max-width: 100%;
            transform: translate3d(0, 0, 0);
            right: 0;
            bottom: 0;

            /* cookie policy button overriding the chat modal so set higher z-index */
            z-index: 3147483646;

            @include media-breakpoint-up(md) {
                right: 1rem;
                max-width: rem(360);
                transform: translate3d(0, -1rem, 0);
            }
        }
    }

    .slider-serive-cloud {
        @include media-breakpoint-down(sm) {
            width: rem(81);
            margin: 0 auto;
            height: rem(4);
            background: #dee2e6;
            margin-top: rem(10);
        }
    }
}

.cs-header-banner {
    background-color: #fff3cd;
}

.free-shipping-member {
    color: $dark-grey;
}

/* Mobile search bar styles */
.search-mobile {
    display: none;
    background-color: #fff;
}

/* Show search bar when body has 'search-visible' class */
body.search-visible .search-mobile {
    display: block;
}

@media (max-width: $tablet-breakpoint) {
    /* Adjust logo positioning in mobile header */
    .mobile-logo-container {
        position: absolute;
        left: 50%;
        margin-left: -24px;
        z-index: 1;
    }
}

/* Further adjusting mobile header positioning */
#search-toggle-icon,
.mini-cart {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;

    @include media-breakpoint-up(lg) {
        margin-left: 0 !important;
    }
}

.navbar-toggler__container {
    margin-right: 0.5rem !important;
}

.navbar-toggler,
.account-icon {
    align-items: center;
    justify-content: center;
}
