.minicart {
    .close {
        font-size: 2rem;
        font-weight: $fontweight-light;
    }

    &-items-container {
        .removeBtn {
            display: contents;
        }

        .product-summary {
            .removeBtn {
                display: contents;
            }

            -ms-overflow-style: none;
            max-height: calc(100vh - 25.5rem) !important;
            @include media-breakpoint-down(md) {
                max-height: none !important;
            }

            .product-row {
                @include media-breakpoint-down(md) {
                    display: none;
                }

                &:first-child {
                    display: inline-block;
                }
            }

            .line-item-pricing-info,
            .price {
                font-size: $font-size16;

                .value {
                    font-weight: $fontweight-semibold;
                }
            }

            .quantity {
                height: 1.7rem;
                width: 2.1rem;
                font-size: $font-14px;
                border-bottom: 1px solid $nb-grey;
                z-index: 2;
                background-color: transparent;
                @include media-breakpoint-down(md) {
                    width: 4rem;
                    border-bottom: none;
                    background-color: $white;
                    background-image: none;
                }
            }
        }

        .product-line-item-details {
            overflow-y: unset;
        }

        .line-item-promo {
            line-height: 1rem;
            color: $black;
        }
    }

    .checkout-continue {
        div {
            padding-bottom: 0 !important;
        }
    }

    .popover {
        position: fixed !important;
        box-sizing: content-box;
        min-width: 34.625rem !important;
        max-width: 34.625rem !important;
        font-family: $body-font-family;
        right: -42rem !important;
        opacity: 0;
        @include media-breakpoint-down(md) {
            border: none;
        }

        h4 {
            font-size: 1rem;
        }

        .cart {
            width: auto;
        }

        .remove-btn {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        .item-image {
            width: 8.5rem;
            height: 8.5rem;

            &.oos-cart-item {
                img {
                    opacity: 0.5;
                }
            }

            .product-image {
                max-height: 8.5rem;
            }

            .oos-message {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                font-size: $font-size14;
                font-weight: $fontweight-semibold;
                line-height: $font-size20;
                color: $black;
                text-align: center;
                @include media-breakpoint-up(lg) {
                    width: 100%;
                }
                @include media-breakpoint-down(md) {
                    top: 25%;
                }
            }
        }

        .minicart-success-msg {
            font-size: 1rem;
            color: $black;
            font-weight: $fontweight-semibold;
            padding-top: 0 !important;
            padding-bottom: 0.5rem !important;
        }

        &.show {
            right: 0 !important;
            opacity: 1;
            transition: 0.3s;
        }

        @include media-breakpoint-down(md) {
            min-width: 45rem !important;
        }

        @include media-breakpoint-down(xs) {
            left: 0 !important;
            min-width: 100vw !important;
            min-height: 100vh !important;
            top: 0 !important;
        }

        .checkmark {
            &::before {
                content: '';
                display: inline-block;
                width: 0.6rem;
                height: 1.1rem;
                border: solid $black;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
            }
        }

        .line-item-name {
            font-size: $font-size16 !important;
            font-weight: $fontweight-bold;
            letter-spacing: 0.01rem;
            line-height: 1.5rem;
            color: $black;
        }

        .line-item-unit-price {
            font-size: $font-size16 !important;
            font-weight: $fontweight-semibold;
            line-height: 1.5rem;
            color: $black;
        }

        .strike-through {
            font-size: $font-size16;
            font-weight: $fontweight-semibold;
            line-height: $font-size24;
            margin-right: 0;
            color: $dark-grey;
            text-decoration: line-through;
            @include media-breakpoint-down(md) {
                font-size: $font-size14;
                line-height: $font-size20;
                display: inline-block;
            }
        }

        .gender-name {
            font-size: $font-size14;
        }

        .minicart-footer {
            font-size: $font-size16;
        }

        .line-item-quantity {
            @include media-breakpoint-down(md) {
                display: none;
            }

            select {
                &.custom-select {
                    background: none;
                    min-width: 2rem;
                    width: 2rem;
                    height: 1.13rem;
                    border-bottom: 1px solid $silver-grey;
                    position: relative;
                    z-index: 1;
                }

                &.error {
                    border: 1px solid $nb-red !important;
                }
            }

            .triangle {
                position: absolute;
                left: 6rem;
                top: 4%;
                z-index: 0;
            }

            .quantity-label {
                font-size: $font-size14 !important;
                width: 4rem;
            }
        }

        .item-attributes {
            .product-content {
                font-size: $font-14px;
                line-height: 1.25rem;
            }

            .attribute-label {
                width: 4rem;
                text-transform: capitalize;
            }

            .attribute-value {
                width: calc(100% - 4rem);
            }
        }

        .line-item-divider {
            color: $nb-grey;
        }

        .minicart-footer {
            font-weight: $fontweight-medium;

            @include media-breakpoint-down(md) {
                border: 0;
            }

            .applepay {
                background-color: $black;
            }

            .minicart-promo-display {
                .approaching-discounts {
                    &-info:not(:first-child) {
                        display: none;
                    }
                }
            }
        }

        &.product-recommendator {
            .product-summary {
                @include media-breakpoint-up(md) {
                    max-height: calc(100vh - 44rem) !important;

                    @media screen and (max-height: 800px) { // stylelint-disable-line
                        max-height: calc(100vh - 25.5rem) !important;
                    }
                }
            }

            overflow-y: auto;
            height: 100vh;
        }
    }

    .sub-total-label,
    .sub-total {
        font-size: $font-size16 !important;
        font-weight: $fontweight-semibold !important;
    }

    .free-shipping {
        font-size: $font-size14 !important;
        font-weight: $fontweight-semibold;
    }

    .btn {
        font-weight: $fontweight-bold;
    }

    .minicart-paypal-button-holder {
        background-color: $paypal-blue;
        height: rem(48);
        overflow: hidden;
        @include media-breakpoint-up(lg) {
            height: rem(56);
            padding-top: rem(4);
        }
    }

    .btn-outline-dark {
        height: rem(48);
        @include media-breakpoint-up(lg) {
            height: rem(56);
        }
    }

    /** When Apple pay is not compiled hide it **/

    /* csslint ignore:start */
    isapplepay.bg-dark {
        display: none;
    }

    /* csslint ignore:end */
}

#removeProductModal {
    .modal-header {
        @include media-breakpoint-down(md) {
            padding: rem(16) rem(8) 0 rem(16);
        }
    }

    .modal-body {
        @include media-breakpoint-down(md) {
            padding: rem(8) rem(8) 0;
            min-height: 100vh;
        }

        .product-image {
            @include media-breakpoint-down(md) {
                width: 100%;
            }
        }

        .product-price-details {
            min-width: rem(344);

            @include media-breakpoint-down(md) {
                min-width: 100%;
            }
        }

        .cart-add-to-wishlist {
            line-height: rem(20);// To override base btn styles

            .icon.svg-icon {
                width: rem(24);
                height: rem(21);
            }
        }
    }

    .modal-footer {
        position: static;
        background: $white;

        @include media-breakpoint-down(md) {
            position: sticky;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: rem(16) rem(8);
        }
    }
}

@include media-breakpoint-up(lg) {
    .removeButtonCart {
        position: absolute;
        right: 0;
    }

    .quantyTotalCart {
        max-width: 100% !important;
        flex: 100% !important;
    }

    .popover-bottom.show {
        .miniCartPageShowPrice {
            display: block !important;
        }

        .miniCartPageHidePrice {
            display: none;
        }
    }
}

.hide-no-link {
    display: none;
}

.mini-cart-shift-left {
    .popover {
        &.show {
            @media only screen and (min-width: $xxl) {
                right: calc((100vw - 1440px) / 2) !important;
            }
        }
    }
}
