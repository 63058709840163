//---------------------------------------------------------------------------------------------
// custom-select box code
//---------------------------------------------------------------------------------------------

.custom-dropdown {
    width: 100%;
    float: left;
    color: inherit;
    font-size: inherit;
    position: relative;
    border: none;
    background: inherit;

    &.current_item {
        border: 1px solid $nb-black;

        .selection-list {
            display: block;
            border: 1px solid #000;
        }

        .selected-option {
            &::before {
                top: 47%;
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
                border-color: #000 transparent transparent;
            }
        }
    }

    select {
        position: absolute;
        left: -9999px;
        top: 0;
        z-index: 0;
        width: 100%;
        height: 1px; // To hide select properties
        padding: 0; // To hide select properties
        border: none; // To hide select properties
    }

    &.current_item .selected-option {
        border-color: $black;
    }

    .selection-list {
        background-color: $white;
        list-style: none;
        display: none;
        left: -1px;
        margin: 0;
        padding: 0;
        position: absolute;
        width: 100.4%;
        z-index: 9;
        overflow: auto;

        &.show {
            display: block;
        }

        .pt_checkout & {
            background-color: $black;
        }

        li {
            line-height: 25px;
            outline: none;
            float: left;
            width: 100%;
            padding: 10px 20px;
            cursor: pointer;
            border-bottom: 1px solid $black;

            &:last-child {
                border-bottom: 0;
            }

            &:hover,
            &:active,
            &.selected,
            &.hover {
                color: $nb-black;
                background: inherit;
            }

            &.selected {
                display: none;
            }
        }
    }
}

.custom-selection-list {
    top: 75%;
    position: static;

    &:focus {
        outline: none;
    }

    li {
        list-style: none;

        &:active {
            outline: none;
        }

        &:focus {
            .custom-radio {
                label::before {
                    content: none;
                }

                .form-check-input {
                    left: -9999px;
                }
            }
        }
    }

    label {
        color: $black !important;
    }

    .custom-radio {
        label {
            display: inline-block;
        }
    }

    .selected-option {
        border-top: 1px solid $silver-grey;
    }
}

.selected-option {
    &.active + .custom-selection-list {
        display: block;
        border-bottom: 1px solid $silver-grey;
    }
}

.custom-dropdown {
    color: $black;

    .display-name {
        font-size: $font-size14;
        font-weight: $fontweight-semibold;
        line-height: $font-size20;
        text-transform: capitalize;
    }

    .address-line {
        font-size: $font-size16;
        line-height: $font-size24;
        font-weight: $fontweight-regular;
    }

    .list-item {
        label {
            font-size: $font-size14;
            line-height: $font-size20;
            color: $black;

            .display-name {
                font-weight: $fontweight-semibold;
                text-transform: capitalize;
            }

            .address-line {
                font-size: $font-size14;
                font-weight: $fontweight-regular;
            }
        }
    }

    .selected-option {
        border-top: 1px solid $silver-grey;
        border-bottom: 1px solid $silver-grey;
        background: transparent;
        background-size: cover;
        position: relative;
        width: 100%;
        text-align: left;

        &.active {
            border-bottom: none;
        }

        &::after {
            content: "";
            background-image: url('../../images/down-icon.svg');
            width: 18px;
            height: 16px;
            display: inline-block;
            position: absolute;
            background-size: cover;
            right: 3%;
            top: 50%;
            transform: translateY(-50%);
        }

        &.active {
            &::after {
                background-image: url('../../images/up-icon.svg');
            }
        }
    }
}

.card-selected-option {
    &.active + .creditcard-select {
        display: block;
    }
}

.stored-payments {
    display: none;
}

.selected-card-display {
    &.active + .stored-payments {
        display: block;
    }
}
// normal dropdown background image
select {
    &.custom-select {
        background: none;
        position: relative;
        background-size: 1rem;
        padding-left: rem(4);
        color: $black;
        font-weight: $fontweight-regular;
        background-position: right 75%;

        .disabled-dropdown-selection & {
            color: $dark-grey !important;
            pointer-events: none;
            background: none;
        }

        &:focus {
            border-bottom: 1px solid $nb-black;
        }
    }
}

select.custom-select + label {
    .disabled-dropdown-selection & {
        color: $dark-grey !important;
        pointer-events: none;
        background: none;
    }
}

.form-group-select {
    position: relative;

    &.active {
        &::after {
            background-image: url('../../images/up-icon.svg');
        }
    }

    &::after {
        content: '';
        pointer-events: none;
        position: absolute;
        background-image: url('../../images/down-icon.svg');
        background-size: cover;
        top: rem(24);
        right: rem(4);
        height: rem(16);
        width: rem(18);
        transform-origin: center center;
        transition: 0.3s;
    }
}
