// Shared Styles for country selector
.country-selector-one {
    .separator {
        width: rem(1);
        background-color: $white;
        height: rem(12);
        position: relative;
        top: rem(1);
        margin: 0 rem(4);
    }

    &.has-multiple-locale:hover {
        .country-name {
            text-decoration: underline;
        }
    }
}
