// Buy online and Ship to Store

@import '../global/variables';
@import "bootstrap/scss/mixins/breakpoints";

.boss-store,
.cvs-store {
    display: flex;
    border: 2px solid $silver-grey;
    padding: 15px;
    cursor: pointer;

    @include media-breakpoint-up(sm) {
        width: calc((100% - 15px) / 3);
        flex-basis: calc((100% - 15px) / 3);
        min-height: 200px;
        margin-right: 5px;
    }

    .boss-tile-select,
    .cvs-tile-select {
        margin-top: 6px;
    }

    .font-body-large {
        font-size: 14px;
        margin: 0;

        &.boss-store-phone,
        &.cvs-store-phone {
            margin-top: 1rem;
        }
    }

    .custom-radio .form-check-label::before {
        background-color: transparent;
    }
}
