@import "~base/components/productTiles";
@import "../global/variables";
@import "../global/colors";

.experience-component {
    .slick-list {
        .image-container {
            overflow: initial;
        }

        .product-tile {
            padding: 1px;
        }
    }

    &.experience-commerce_layouts-Tabs .tabbed-carousel,
    .horizontal-product-carousel {
        .product-tile {
            .product-name,
            .product-category,
            .product-price,
            .sub-badges {
                font-size: $font-size14;
                font-style: normal;
                line-height: 18px !important;
                text-decoration: none;
            }

            .product-name,
            .sub-badges {
                margin-bottom: 0 !important;
                padding-bottom: 4px !important;
                font-weight: $fontweight-medium !important;
            }

            .product-price span,
            .product-category {
                line-height: 18px !important;
                color: $gray-blue !important;
                font-weight: $fontweight-regular !important;
            }

            @include media-breakpoint-down(md) {
                padding: 1px;
            }
        }

        &:not(.slick-initialized) {
            .odd .product-tile,
            .p-tile:nth-child(odd) .product-tile {
                @include media-breakpoint-down(md) {
                    padding-left: 0 !important;
                }
            }

            .even .product-tile,
            .p-tile:nth-child(even) .product-tile {
                @include media-breakpoint-down(md) {
                    padding-right: 0 !important;
                }
            }
        }

        .product-tile > .flex-column > div:not(.image-container) {
            margin-top: 0 !important;
            padding: 12px 24px 0 !important;

            @include media-breakpoint-down(md) {
                padding: 8px 16px 0 !important;
            }
        }

        .product-tile > .flex-column > div:not(.image-container) ~ div {
            padding-top: 0 !important;
        }
    }
}
