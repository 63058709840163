// Control the default styling of most elements by modifying these
// You can add more entries to the $spacers map, should you need more variation.

$nb-spacer: 0.5rem !default;

$nb-spacing-1: $nb-spacer * 0.5; // 4px
$nb-spacing-2: $nb-spacer * 1; // 8px
$nb-spacing-25: $nb-spacer * 1.5; // 12px
$nb-spacing-3: $nb-spacer * 2; // 16px
$nb-spacing-4: $nb-spacer * 3; // 24px
$nb-spacing-5: $nb-spacer * 4; // 32px
$nb-spacing-55 : $nb-spacer * 4.5; // 36px
$nb-spacing-6: $nb-spacer * 5; // 40px
$nb-spacing-7: $nb-spacer * 6; // 48px
$nb-spacing-8: $nb-spacer * 7; // 56px
$nb-spacing-9: $nb-spacer * 8; // 64px
$nb-spacing-10: $nb-spacer * 9; // 72px
$nb-spacing-11: $nb-spacer * 10; // 80px
$nb-spacing-12: $nb-spacer * 11; // 88px
$nb-spacing-13: $nb-spacer * 12; // 96px
$nb-spacing-14: $nb-spacer * 13; // 104px
$nb-spacing-15: $nb-spacer * 14; // 112px
