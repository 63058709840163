:root {
    /* colors */
    --nb-color-ada-gray: #767676;
    --nb-color-black: #000;
    --nb-color-gray: #737b82;
    --nb-color-gray-ccc: #ccc;
    --nb-color-primary: #cf0a2c;
    --nb-color-primary-hover: #b90020;
    --nb-color-white: #fff;

    /* font-size */
    --nb-font-size-01: 10px;
    --nb-font-size-02: 12px;
    --nb-font-size-03: 14px;
    --nb-font-size-04: 16px;
    --nb-font-size-05: 18px;
    --nb-font-size-06: 20px;
    --nb-font-size-07: 24px;
    --nb-font-size-08: 28px;
    --nb-font-size-09: 32px;

    /* font-weight */
    --nb-font-weight-01: 100;
    --nb-font-weight-02: 200;
    --nb-font-weight-03: 300;
    --nb-font-weight-04: 400;
    --nb-font-weight-05: 500;
    --nb-font-weight-06: 600;
    --nb-font-weight-07: 700;
    --nb-font-weight-08: 800;
    --nb-font-weight-09: 900;

    /* spacing */
    --nb-spacing-01: 2px;
    --nb-spacing-02: 4px;
    --nb-spacing-03: 6px;
    --nb-spacing-04: 8px;
    --nb-spacing-05: 10px;
    --nb-spacing-06: 12px;
    --nb-spacing-07: 14px;
    --nb-spacing-08: 18px;
    --nb-spacing-09: 20px;
    --nb-spacing-10: 22px;
    --nb-spacing-11: 24px;
}
