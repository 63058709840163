@import "~base/variables";

//colors
$white: #fff;
$black: #000;
$black-2: #222;
$trans: transparent;
$nb-black: #151415;
$nb-light-black: #0f0f10;
$nb-red: #cf0a2c;
$nb-grey: #737b82;
$nb-grey-light: #ebeced;
$nb-grey-lighter: #f8f9f9;
$darker-grey: #5f5f5f;
$dark-grey: #767676;
$silver-grey: #ccc;
$silver-gray-2: #c7cacd;
$silver-light-grey: #c2c2c2;
$fog-white: #f1f1f1;
$silver-dark-gray: #abb0b4;
$lightest-grey: #f3f3f3;
$lighter-grey: #f5f5f5;
$light-grey: #f6f6f6;
$medium-grey: #f8f8f8;
$white-smoke: #efefef;
$ghost-white: #f2f2f3;
$dim-gray: #727272;
$gray-blue: #5c6268;
$Matterhorn : #4f4f4f;
$nb-hover-red: #b90020;
$nb-alert-red: #f9dfe1;
$alabaster: #fafafa;
$brick-red: #bf2d37;
$mercury: #e9e9e9;
$Blumine: #21538d;
$nb-alto-grey: #d8d8d8;
$paypal-blue: #009cde;
$nb-fire-engine-red: #cf0a2c;
$nb-grey-80: #ccc;
$grey-transparent: rgba(43, 36, 25, 0.4) !default;
$focus-outline-color: #015ecc; // This is the default color of chrome focus outline.

$theme-colors: (
    "primary": $nb-red,
    "secondary": $trans,
    "danger": $nb-red
);

//NB font family- updated
$nb-page-heading-font : "ITC Garamond W01";
$linotype-didotw01-n3 : "Linotype Didot W01_n3";
$linotype-didot-w01-i3 : "Linotype Didot W01_i3";
$linotype-didot-w01-n7 : "Linotype Didot W01_n7";
$body-font-family : "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"; // used for "Gotham HTF medium"
$proxima-nova-w01-n2 : "Proxima Nova W01_n1", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
$proxima-nova-w01-n3 : "Proxima Nova W01_n3", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
$proxima-nova-w01-n4 : "Proxima Nova W01_n4", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
$proxima-nova-w01-n5 : "Proxima Nova W01_n5", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
$proxima-nova-w01-n6 : "Proxima Nova W01_n6", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
$proxima-nova-w01-n7 : "Proxima Nova W01_n7", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
$proxima-nova-w01-n8 : "Proxima Nova W01_n8", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";

//error message mixin

@mixin error-message {
    font-size: $font-size14;
    color: $nb-red;
    letter-spacing: 0;
    line-height: $font-size24;
}

//Font sizes
$font-size-base: 0.875rem;
$base-font: 14px;
$font-size10: 0.625rem;
$font-size12: 0.75rem;
$font-size14: 0.875rem;
$font-size16: 1rem;
$font-size18: 1.125rem;
$font-size20: 1.25rem;
$font-size24: 1.5rem;
$font-size32: 2rem;
$font-size40: 2.5rem;
$font-size44: 2.8rem;
$font-size48: 3rem;
$font-size56: 3.5rem;
$font-size38: 2.375rem;
$font-14px: 0.875rem;

//Line height
$line-height-s-grid-heading: 1.1;

//Font weights
$fontweight-light: 300;
$fontweight-regular: 300;
$fontweight-medium: 500;
$fontweight-semibold: 500;
$fontweight-bold: 700;

//Buttons
$btn-font-weight: $fontweight-medium;
$btn-padding-y: 0;
$btn-padding-x: $font-size20;
$btn-font-size: $font-size14;
$btn-line-height: calc(3.5rem - 2px);
$btn-border-radius: 0;
$button-height: 56px;

//Modals
$modal-header-padding: 1rem;
$modal-footer-border-width: 0;

//Body themes
$body-bg: $white;
$body-color: $nb-black;
$font-family-base: $body-font-family;
$font-weight-base: $fontweight-light;
$line-height-base: 1.5;

/* overriding the bootstrap header sizes */

$h1-font-size: $font-size32;
$h2-font-size: $font-size24;
$h3-font-size: $font-size16;
$h4-font-size: $font-size14;
$h5-font-size: $font-size12;
$h6-font-size: $font-size12;

$headings-font-family: $body-font-family;
$headings-color: $black;

// Forms
$form-feedback-font-size: $font-size10;
$form-feedback-margin-top: 0.5rem;
$input-padding-x: 0;

$xxl: 1680px;

// variables to deal with widths
$width-1440: 1440px;
