@import "bootstrap/scss/mixins/breakpoints";
@import "../../../variables";

.content-text-top {
    top: 50%;
    transform: translateY(-50%) translateX(50%);
    margin: 0;
    position: absolute;
    right: 50%;
}

.content-text-hero {
    position: relative;
    @include media-breakpoint-up(lg) {
        top: 50%;
        transform: translateY(-50%) translateX(50%);
        margin: 0;
        position: absolute;
        right: 50%;
    }
}

.light-color-mode {
    color: $white !important;
}

.z-index-banner {
    z-index: -1;
}

.light-color-mode-hero,
.light-color-mode-hero a {
    @include media-breakpoint-up(lg) {
        color: $white !important;
    }
}

.disclaimer-hero {
    position: relative;
    @include media-breakpoint-up(lg) {
        position: absolute;
        bottom: 0;
    }
}

.word-break {
    word-wrap: break-word;
}

.overlay-image {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.disclaimer-position {
    @include media-breakpoint-up(lg) {
        transform: translateY(0%) translateX(0%);
        position: relative;
        z-index: 2;
    }
}

.header-container--overlapped-js ~ div#maincontent .experience-commerce_assets-HeroComponent:first-child {
    @include media-breakpoint-up(lg) {
        margin-top: -120px;
    }
}

.markup-container p {
    margin-bottom: 0;
}
