@charset "UTF-8";
[class*="col-"] {
  padding-right: 8px;
  padding-left: 8px; }

.row {
  margin-right: -8px;
  margin-left: -8px; }

@media (max-width: 991.98px) {
  #maincontent .full-width {
    margin-left: -16px;
    margin-right: -16px; } }

.pull-left-xs {
  float: left; }

.pull-right-xs {
  float: right; }

@media (min-width: 544px) and (max-width: 768.98px) {
  .pull-left-sm {
    float: left; }
  .pull-right-sm {
    float: right; } }

@media (min-width: 769px) and (max-width: 991.98px) {
  .pull-left-md {
    float: left; }
  .pull-right-md {
    float: right; } }

@media (min-width: 992px) and (max-width: 1199.98px) {
  .pull-left-lg {
    float: left; }
  .pull-right-lg {
    float: right; } }

@media (min-width: 1200px) {
  .pull-left-xl {
    float: left; }
  .pull-right-xl {
    float: right; } }

@media (max-width: 543.98px) {
  .hidden-xs-down {
    display: none !important; } }

@media (max-width: 768.98px) {
  .hidden-sm-down {
    display: none !important; } }

@media (max-width: 991.98px) {
  .hidden-md-down {
    display: none !important; } }

@media (max-width: 1199.98px) {
  .hidden-lg-down {
    display: none !important; } }

.hidden-xl-down {
  display: none !important; }

.row.equal-height > [class^="col"] {
  display: flex; }

body {
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  color: #151415;
  line-height: 1rem;
  background-color: #fff;
  -webkit-font-smoothing: antialiased; }

button {
  background: none;
  border: 0; }

ul {
  list-style: none;
  padding: 0;
  margin: 0; }

@media (max-width: 991.98px) {
  input[type='text'],
  input[type='number'],
  input[type='password'],
  input[type='tel'],
  input[type='email'],
  select.custom-select {
    font-size: 1rem; } }

/* overriding the bootstrap header sizes */
a.nb-button:hover {
  text-decoration: none; }

.nb-button {
  display: inline-block;
  min-width: 6.5rem;
  line-height: 1.5rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
  padding: 0.75rem 1.5rem;
  border-radius: 0.25rem;
  border: 0.125rem solid transparent;
  box-sizing: border-box;
  text-decoration: none !important;
  transition: all 0.2s;
  user-select: none;
  vertical-align: middle; }
  .nb-button.button-short {
    padding: 0.2rem 1rem !important;
    height: 2.5rem; }
  .nb-button.button-full-width {
    width: 100%; }
  @media (min-width: 769px) {
    .nb-button.button-medium {
      min-width: 10.5rem; } }
  @media (min-width: 769px) {
    .nb-button.button-large {
      min-width: 14rem; } }
  @media (min-width: 769px) {
    .nb-button.button-xlarge {
      min-width: 21.5rem; } }
  @media (max-width: 991.98px) {
    .nb-button.button-full-width-mobile {
      width: 100%; } }
  @media (max-width: 991.98px) {
    .nb-button.button-mxlarge {
      min-width: 21.5rem; } }
  @media (max-width: 991.98px) {
    .nb-button.button-mlarge {
      min-width: 14rem; } }
  @media (max-width: 991.98px) {
    .nb-button.button-mmedium {
      min-width: 10.5rem; } }
  .nb-button.button-primary {
    background-color: #cf0a2c;
    border-color: #cf0a2c;
    color: #fff !important; }
    .nb-button.button-primary:hover {
      background-color: #b90020;
      border-color: #b90020;
      outline: none; }
    .nb-button.button-primary.disabled, .nb-button.button-primary:disabled {
      pointer-events: none;
      background-color: #ccc;
      color: #767676;
      border-color: #ccc;
      color: #767676 !important; }
      .nb-button.button-primary.disabled:hover, .nb-button.button-primary.disabled:focus, .nb-button.button-primary:disabled:hover, .nb-button.button-primary:disabled:focus {
        background-color: #ccc;
        color: #000;
        border-color: #ccc; }
      .nb-button.button-primary.disabled:active, .nb-button.button-primary:disabled:active {
        background-color: #ccc;
        color: #000;
        border-color: #ccc; }
    .nb-button.button-primary.black {
      background-color: #000;
      border-color: #000; }
  .nb-button.button-secondary {
    border-color: #cf0a2c;
    color: #cf0a2c !important;
    background-color: #fff; }
    .nb-button.button-secondary:hover, .nb-button.button-secondary:focus {
      outline: none;
      box-shadow: inset 0 0 0 0.125rem #cf0a2c; }
    .nb-button.button-secondary.disabled, .nb-button.button-secondary:disabled {
      pointer-events: none;
      background-color: transparent;
      color: #767676;
      border-color: #ccc; }
      .nb-button.button-secondary.disabled:hover, .nb-button.button-secondary.disabled:focus, .nb-button.button-secondary:disabled:hover, .nb-button.button-secondary:disabled:focus {
        background-color: #fff;
        color: #000;
        border-color: #000; }
      .nb-button.button-secondary.disabled:active, .nb-button.button-secondary:disabled:active {
        background-color: #fff;
        color: #000;
        border-color: #000; }
  .nb-button.button-tertiary {
    border-color: #c2c2c2; }

.nb-button-secondary {
  background-color: #fff;
  color: #151415;
  border: 1px #abb0b4 solid;
  padding: 5px 16px;
  border-radius: 4px;
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 14px;
  line-height: 20px;
  font-weight: 500; }
  .nb-button-secondary.icon {
    padding: 5px 7px; }
  .nb-button-secondary.gray-button {
    background-color: #f2f2f3;
    border: none; }

.tertiary-link {
  color: #000;
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-decoration: underline;
  line-height: 24px;
  padding: 0; }
  .tertiary-link-large {
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: 0.025rem; }
  .tertiary-link-medium {
    font-size: 0.875rem;
    font-weight: 300;
    letter-spacing: 0.025rem; }
  .tertiary-link-small {
    font-size: 0.75rem;
    font-weight: 300;
    letter-spacing: 0.025rem; }

/* overriding the bootstrap header sizes */
strong {
  font-weight: 700; }

.veil {
  z-index: 1001; }

/* Works on Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #ccc #fff; }

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px; }

*::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 8px; }

*::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 8px; }

*::-webkit-scrollbar-thumb:hover {
  background: #555; }

body {
  color: #000; }
  body .font-hero {
    font-family: "ITC Garamond W01";
    font-size: 6rem;
    line-height: 6.5rem; }
    html[data-site="NBJP"] body .font-hero,
    html[data-site="JPOUTLET"] body .font-hero {
      font-size: 4.5rem;
      line-height: 5rem; }
    @media (max-width: 991.98px) {
      body .font-hero {
        font-size: 3.5rem;
        line-height: 3.75rem; }
        html[data-site="NBJP"] body .font-hero,
        html[data-site="JPOUTLET"] body .font-hero {
          font-size: 2.625rem;
          line-height: 2.875rem; } }
  body .didot-font {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.5rem;
    line-height: 2rem; }
    @media (min-width: 992px) {
      body .didot-font {
        font-family: "ITC Garamond W01";
        font-size: 3rem;
        line-height: 3.5rem; } }
  body .font-header .font-header-1, body .font-header .font-header-2, body .font-header .font-header-3 {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: 0;
    font-weight: 500; }
  body .font-header-1 {
    font-size: 2rem;
    color: #000;
    line-height: 2.5rem; }
    html[data-site="NBJP"] body .font-header-1,
    html[data-site="JPOUTLET"] body .font-header-1 {
      font-size: 1.5rem;
      line-height: 2rem; }
    @media (max-width: 991.98px) {
      body .font-header-1--md {
        font-size: 1.5rem;
        line-height: 2rem; } }
  body .font-header-2 {
    font-size: 1.5rem;
    color: #000;
    line-height: 2rem; }
    html[data-site="NBJP"] body .font-header-2,
    html[data-site="JPOUTLET"] body .font-header-2 {
      font-size: 1.125rem;
      line-height: 1.625rem; }
    @media (max-width: 991.98px) {
      body .font-header-2.mobile-font-small {
        font-size: 0.875rem;
        line-height: 1rem; } }
  body .font-header-3 {
    font-size: 1rem;
    color: #000;
    line-height: 1.5rem; }
    @media (min-width: 769px) {
      body .font-header-3.desktop-subhead-2 {
        font-size: 0.875rem;
        line-height: 1rem; } }
  body .font-header-4 {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    letter-spacing: 0;
    font-size: 1rem;
    color: #fff;
    line-height: 1.5rem;
    font-weight: 700; }
    @media (min-width: 769px) {
      body .font-header-4.desktop-subhead-2 {
        font-size: 0.875rem;
        line-height: 1rem; } }
  body .font-subheader-1 {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 3rem;
    color: #000;
    letter-spacing: 0;
    line-height: 3.5rem; }
  body .font-subheader-2 {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.25rem;
    color: #000;
    letter-spacing: 0;
    line-height: 2rem;
    font-weight: 500; }
  body .font-subheader-3 {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.875rem;
    color: #000;
    letter-spacing: 0;
    line-height: 1.5rem; }
  body .font-subheader-4 {
    color: #000;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 1rem; }
  body .font-body {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.875rem;
    color: #000;
    letter-spacing: 0;
    line-height: 1.5rem;
    font-weight: 300; }
    body .font-body-small {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 0.75rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1.125rem; }
    body .font-body-large {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1.5rem; }
      @media (max-width: 991.98px) {
        body .font-body-large.mobile-font-small {
          font-size: 0.875rem;
          line-height: 1.25rem; } }
    body .font-body-medium {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1.25rem; }
  body .font-small {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.75rem;
    color: #000;
    letter-spacing: 0;
    line-height: 1.125rem; }
  body .font-error {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.875rem;
    color: #cf0a2c;
    letter-spacing: 0;
    line-height: 1.5rem; }
  body .font-link {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-decoration: underline;
    font-size: 0.875rem;
    color: #000;
    letter-spacing: 0;
    line-height: 1.25rem;
    font-weight: 500; }
    body .font-link.bold {
      font-weight: 700; }
    body .font-link.no-text-decoration {
      text-decoration: none; }
  body .bold {
    font-weight: 700; }
  body .normal {
    font-weight: 300; }
  body .regular {
    font-weight: 300; }
  body .font-weight-semibold {
    font-weight: 500; }
  body .text-underline {
    text-decoration: underline; }
  body .no-underline {
    text-decoration: none; }
  body .lowercase {
    text-transform: lowercase; }
  @media (min-width: 992px) {
    body .font-header-lg-1 {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 2rem;
      color: #000;
      letter-spacing: 0;
      line-height: 2.5rem;
      font-weight: 500; }
    body .font-header-lg-2 {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1.5rem;
      color: #000;
      letter-spacing: 0;
      line-height: 2rem;
      font-weight: 700; }
    body .font-header-lg-3 {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1.5rem;
      font-weight: 500; } }
  @media (min-width: 992px) and (min-width: 769px) {
    body .font-header-lg-3.desktop-subhead-2 {
      font-size: 0.875rem;
      line-height: 1rem; } }
  @media (min-width: 992px) {
    body .font-subheader-lg-1 {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 3rem;
      color: #000;
      letter-spacing: 0;
      line-height: 3.5rem; }
    body .font-subheader-lg-2 {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 1.25rem;
      color: #000;
      letter-spacing: 0;
      line-height: 2rem;
      font-weight: 500; }
    body .font-subheader-lg-3 {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 0.875rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1rem; }
    body .font-body-lg {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 0.875rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1.25rem;
      font-weight: 300; }
      body .font-body-lg-small {
        font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 0.75rem;
        color: #000;
        letter-spacing: 0;
        line-height: 1rem; }
      body .font-body-lg-large {
        font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 1rem;
        color: #000;
        letter-spacing: 0;
        line-height: 1.5rem; } }
    @media (min-width: 992px) and (max-width: 991.98px) {
      body .font-body-lg-large.mobile-font-small {
        font-size: 0.875rem;
        line-height: 1.25rem; } }
  @media (min-width: 992px) {
      body .font-body-lg-medium {
        font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        font-size: 1rem;
        color: #000;
        letter-spacing: 0;
        line-height: 1.25rem; }
    body .font-small-lg {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 0.75rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1rem; }
    body .font-error-lg {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 0.875rem;
      color: #cf0a2c;
      letter-spacing: 0;
      line-height: 1.5rem; }
    body .font-link-lg {
      font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      text-decoration: underline;
      font-size: 0.875rem;
      color: #000;
      letter-spacing: 0;
      line-height: 1.25rem;
      font-weight: 500; }
      body .font-link-lg.bold {
        font-weight: 700; }
    body .bold-lg {
      font-weight: 700; }
    body .normal-lg {
      font-weight: 300; }
    body .regular-lg {
      font-weight: 300; }
    body .font-weight-semibold-lg {
      font-weight: 500; }
    body .text-underline-lg {
      text-decoration: underline; }
    body .no-underline-lg {
      text-decoration: none; }
    body .lowercase-lg {
      text-transform: lowercase; } }

.form-group.required .form-control-label::before {
  content: "*";
  color: #c00; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #151415 !important;
  -webkit-box-shadow: 0 0 0 100px white inset; }

textarea.form-control,
input.form-control,
.form-control {
  font-weight: 500;
  border: none;
  color: #000;
  border-radius: 0;
  border-bottom: 1px solid #151415; }
  textarea.form-control:disabled, textarea.form-control[readonly],
  input.form-control:disabled,
  input.form-control[readonly],
  .form-control:disabled,
  .form-control[readonly] {
    background-color: #fff;
    opacity: 1;
    pointer-events: none;
    touch-action: none; }
  textarea.form-control:focus,
  input.form-control:focus,
  .form-control:focus {
    color: #000;
    border-bottom-color: #151415; }
  textarea.form-control.is-invalid:focus,
  input.form-control.is-invalid:focus,
  .form-control.is-invalid:focus {
    box-shadow: none; }

label.form-control-label,
.form-control-label {
  color: #000; }

.custom-checkbox .custom-control-input + .custom-control-label::before {
  color: #fff;
  border-color: none;
  background-color: transparent; }

.custom-checkbox .custom-control-input + .custom-control-label::after {
  background-image: url("../../images/unselected.svg");
  background-size: cover; }

.custom-checkbox .custom-control-input:checked + .custom-control-label::after {
  background-image: url("../../images/selected.svg"); }

.custom-checkbox .custom-control-input:disabled + .custom-control-label::after {
  background-color: #f6f6f6;
  background-image: none; }

.custom-radio {
  position: relative; }
  .custom-radio .form-check-input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
  .custom-radio .form-check-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: transparent solid 1px; }
  .custom-radio .form-check-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    content: ""; }
  .custom-radio .form-check-input + .form-check-label::after {
    background-image: url("../../images/inactive.svg");
    width: 16px;
    height: 16px;
    background-size: cover; }
  .custom-radio .form-check-input:checked + .form-check-label::after {
    background-image: url("../../images/active.svg");
    width: 16px;
    height: 16px;
    background-size: cover; }

.form-group {
  position: relative; }

.form-group > input,
> label {
  padding: var(--input-padding-y) var(--input-padding-x); }

.form-group > label {
  position: absolute;
  top: 0.5rem;
  left: 0;
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  transition: all 0.1s ease-in-out;
  pointer-events: none; }

.form-group input::-webkit-input-placeholder, .form-group input::placeholder {
  color: transparent; }

.form-group input:focus, .form-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
  border-bottom: 1px solid #151415; }

.form-group input:focus ~ label,
.form-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 0.625rem;
  color: #767676;
  top: -0.7rem; }

.form-group .subtext {
  margin-top: 1rem;
  display: block; }

.form-group.required .form-control-label::before {
  content: ""; }

.form-group.select-dropdown > label {
  top: -0.7rem;
  font-size: 0.714rem; }

.form-group .invalid-feedback {
  color: #cf0a2c; }

.form-group .form-control.is-invalid {
  border-color: #cf0a2c;
  color: #cf0a2c; }

.shipping-content .form-group > label,
.billing-information .form-group > label,
.giftcard-section .form-group > label,
.promo-content .form-group > label,
.store-finder-form .form-group > label,
.yamato-store-finder .form-group > label,
.contact-cs .form-group > label,
.login-content .form-group > label {
  top: 7px;
  font-size: 12px;
  padding-left: 10px;
  color: #767676; }

.shipping-content .form-group input:not([type=radio]):not([type=checkbox]):focus,
.billing-information .form-group input:not([type=radio]):not([type=checkbox]):focus,
.giftcard-section .form-group input:not([type=radio]):not([type=checkbox]):focus,
.promo-content .form-group input:not([type=radio]):not([type=checkbox]):focus,
.store-finder-form .form-group input:not([type=radio]):not([type=checkbox]):focus,
.yamato-store-finder .form-group input:not([type=radio]):not([type=checkbox]):focus,
.contact-cs .form-group input:not([type=radio]):not([type=checkbox]):focus,
.login-content .form-group input:not([type=radio]):not([type=checkbox]):focus {
  border: 1px solid #151415;
  padding-left: 10px; }

.shipping-content .form-group input:not([type=radio]):not([type=checkbox]):focus ~ label,
.billing-information .form-group input:not([type=radio]):not([type=checkbox]):focus ~ label,
.giftcard-section .form-group input:not([type=radio]):not([type=checkbox]):focus ~ label,
.promo-content .form-group input:not([type=radio]):not([type=checkbox]):focus ~ label,
.store-finder-form .form-group input:not([type=radio]):not([type=checkbox]):focus ~ label,
.yamato-store-finder .form-group input:not([type=radio]):not([type=checkbox]):focus ~ label,
.contact-cs .form-group input:not([type=radio]):not([type=checkbox]):focus ~ label,
.login-content .form-group input:not([type=radio]):not([type=checkbox]):focus ~ label {
  color: #767676; }

.shipping-content .form-group input:not([type=radio]):not([type=checkbox]):not(:placeholder-shown),
.billing-information .form-group input:not([type=radio]):not([type=checkbox]):not(:placeholder-shown),
.giftcard-section .form-group input:not([type=radio]):not([type=checkbox]):not(:placeholder-shown),
.promo-content .form-group input:not([type=radio]):not([type=checkbox]):not(:placeholder-shown),
.store-finder-form .form-group input:not([type=radio]):not([type=checkbox]):not(:placeholder-shown),
.yamato-store-finder .form-group input:not([type=radio]):not([type=checkbox]):not(:placeholder-shown),
.contact-cs .form-group input:not([type=radio]):not([type=checkbox]):not(:placeholder-shown),
.login-content .form-group input:not([type=radio]):not([type=checkbox]):not(:placeholder-shown) {
  padding-left: 10px;
  font-weight: 500; }

.shipping-content .form-group input:not([type=radio]):not([type=checkbox]):not(:placeholder-shown) ~ label,
.billing-information .form-group input:not([type=radio]):not([type=checkbox]):not(:placeholder-shown) ~ label,
.giftcard-section .form-group input:not([type=radio]):not([type=checkbox]):not(:placeholder-shown) ~ label,
.promo-content .form-group input:not([type=radio]):not([type=checkbox]):not(:placeholder-shown) ~ label,
.store-finder-form .form-group input:not([type=radio]):not([type=checkbox]):not(:placeholder-shown) ~ label,
.yamato-store-finder .form-group input:not([type=radio]):not([type=checkbox]):not(:placeholder-shown) ~ label,
.contact-cs .form-group input:not([type=radio]):not([type=checkbox]):not(:placeholder-shown) ~ label,
.login-content .form-group input:not([type=radio]):not([type=checkbox]):not(:placeholder-shown) ~ label {
  color: #767676; }

.shipping-content .form-group input:disabled,
.billing-information .form-group input:disabled,
.giftcard-section .form-group input:disabled,
.promo-content .form-group input:disabled,
.store-finder-form .form-group input:disabled,
.yamato-store-finder .form-group input:disabled,
.contact-cs .form-group input:disabled,
.login-content .form-group input:disabled {
  opacity: 0.4; }
  .shipping-content .form-group input:disabled ~ label,
  .billing-information .form-group input:disabled ~ label,
  .giftcard-section .form-group input:disabled ~ label,
  .promo-content .form-group input:disabled ~ label,
  .store-finder-form .form-group input:disabled ~ label,
  .yamato-store-finder .form-group input:disabled ~ label,
  .contact-cs .form-group input:disabled ~ label,
  .login-content .form-group input:disabled ~ label {
    opacity: 0.4; }

.shipping-content .form-control,
.billing-information .form-control,
.giftcard-section .form-control,
.promo-content .form-control,
.store-finder-form .form-control,
.yamato-store-finder .form-control,
.contact-cs .form-control,
.login-content .form-control {
  border-bottom: none;
  border: 1px solid #ccc;
  padding-left: 10px !important; }

.shipping-content input.form-control,
.billing-information input.form-control,
.giftcard-section input.form-control,
.promo-content input.form-control,
.store-finder-form input.form-control,
.yamato-store-finder input.form-control,
.contact-cs input.form-control,
.login-content input.form-control {
  border-bottom: none;
  border: 1px solid #ccc;
  padding-left: 10px !important; }

.shipping-content .form-group-select::after,
.billing-information .form-group-select::after,
.giftcard-section .form-group-select::after,
.promo-content .form-group-select::after,
.store-finder-form .form-group-select::after,
.yamato-store-finder .form-group-select::after,
.contact-cs .form-group-select::after,
.login-content .form-group-select::after {
  content: "";
  top: 15px;
  right: 15px; }

.shipping-content .form-group.required .form-control-label::after,
.billing-information .form-group.required .form-control-label::after,
.giftcard-section .form-group.required .form-control-label::after,
.promo-content .form-group.required .form-control-label::after,
.store-finder-form .form-group.required .form-control-label::after,
.yamato-store-finder .form-group.required .form-control-label::after,
.contact-cs .form-group.required .form-control-label::after,
.login-content .form-group.required .form-control-label::after {
  content: "*";
  color: #ccc; }

:root {
  /* colors */
  --nb-color-ada-gray: #767676;
  --nb-color-black: #000;
  --nb-color-gray: #737b82;
  --nb-color-gray-ccc: #ccc;
  --nb-color-primary: #cf0a2c;
  --nb-color-primary-hover: #b90020;
  --nb-color-white: #fff;
  /* font-size */
  --nb-font-size-01: 10px;
  --nb-font-size-02: 12px;
  --nb-font-size-03: 14px;
  --nb-font-size-04: 16px;
  --nb-font-size-05: 18px;
  --nb-font-size-06: 20px;
  --nb-font-size-07: 24px;
  --nb-font-size-08: 28px;
  --nb-font-size-09: 32px;
  /* font-weight */
  --nb-font-weight-01: 100;
  --nb-font-weight-02: 200;
  --nb-font-weight-03: 300;
  --nb-font-weight-04: 400;
  --nb-font-weight-05: 500;
  --nb-font-weight-06: 600;
  --nb-font-weight-07: 700;
  --nb-font-weight-08: 800;
  --nb-font-weight-09: 900;
  /* spacing */
  --nb-spacing-01: 2px;
  --nb-spacing-02: 4px;
  --nb-spacing-03: 6px;
  --nb-spacing-04: 8px;
  --nb-spacing-05: 10px;
  --nb-spacing-06: 12px;
  --nb-spacing-07: 14px;
  --nb-spacing-08: 18px;
  --nb-spacing-09: 20px;
  --nb-spacing-10: 22px;
  --nb-spacing-11: 24px; }

:root {
  --blue: #0070d2;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #c00;
  --orange: #b36205;
  --yellow: #ffc107;
  --green: #12823b;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #cf0a2c;
  --secondary: transparent;
  --success: #12823b;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #cf0a2c;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 544px;
  --breakpoint-md: 769px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
  color: #151415;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #444;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #1e1e1e;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 500;
  line-height: 1.2;
  color: #000; }

h1, .h1 {
  font-size: 2rem; }

h2, .h2 {
  font-size: 1.5rem; }

h3, .h3 {
  font-size: 1rem; }

h4, .h4 {
  font-size: 0.875rem; }

h5, .h5 {
  font-size: 0.75rem; }

h6, .h6 {
  font-size: 0.75rem; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #ccc; }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f9f9f9;
  border: 1px solid #dee2e6;
  border-radius: 0.1875rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.1875rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  margin-right: auto;
  margin-left: auto;
  max-width: 480px; }
  @media (min-width: 544px) {
    .container {
      max-width: 767px; } }
  @media (min-width: 769px) {
    .container {
      max-width: 993px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 1290px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1440px; } }

.container-fluid, .container-xs, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  margin-right: auto;
  margin-left: auto; }

.container {
  max-width: 480px; }

@media (min-width: 544px) {
  .container, .container-sm {
    max-width: 767px; } }

@media (min-width: 769px) {
  .container, .container-sm, .container-md {
    max-width: 993px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 1290px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1440px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 544px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 769px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #ccc; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #ccc; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #f2bac4; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #e68091; }

.table-hover .table-primary:hover {
  background-color: #eea5b2; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #eea5b2; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: rgba(255, 255, 255, 0.72); }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: rgba(255, 255, 255, 0.48); }

.table-hover .table-secondary:hover {
  background-color: rgba(242, 242, 242, 0.72); }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: rgba(242, 242, 242, 0.72); }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #bddcc8; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #84be99; }

.table-hover .table-success:hover {
  background-color: #acd3ba; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #acd3ba; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f2bac4; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #e68091; }

.table-hover .table-danger:hover {
  background-color: #eea5b2; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #eea5b2; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #ccc; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 543.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 768.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.1875rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #61d2ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 161, 224, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.76562rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.1875rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.1875rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.625rem;
  color: #12823b; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(18, 130, 59, 0.9);
  border-radius: 0.1875rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #12823b;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2312823b' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #12823b;
    box-shadow: 0 0 0 0.2rem rgba(18, 130, 59, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #12823b;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2312823b' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #12823b;
    box-shadow: 0 0 0 0.2rem rgba(18, 130, 59, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #12823b; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #12823b; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #12823b; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #18af4f;
  background-color: #18af4f; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(18, 130, 59, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #12823b; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #12823b; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #12823b;
  box-shadow: 0 0 0 0.2rem rgba(18, 130, 59, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.625rem;
  color: #c00; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(204, 0, 0, 0.9);
  border-radius: 0.1875rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #c00;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23c00' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23c00' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #c00;
    box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #c00;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23c00' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23c00' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #c00;
    box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #c00; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #c00; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #c00; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: red;
  background-color: red; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #c00; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #c00; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #c00;
  box-shadow: 0 0 0 0.2rem rgba(204, 0, 0, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 544px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 500;
  color: #151415;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0 1.25rem;
  font-size: 0.875rem;
  line-height: calc(3.5rem - 2px);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #151415;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 161, 224, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #cf0a2c;
  border-color: #cf0a2c; }
  .btn-primary:hover {
    color: #fff;
    background-color: #ab0824;
    border-color: #9e0822; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #ab0824;
    border-color: #9e0822;
    box-shadow: 0 0 0 0.2rem rgba(214, 47, 76, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #cf0a2c;
    border-color: #cf0a2c; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #9e0822;
    border-color: #92071f; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(214, 47, 76, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: transparent;
  border-color: transparent; }
  .btn-secondary:hover {
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0); }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: transparent;
    border-color: transparent; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border-color: rgba(0, 0, 0, 0); }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-success {
  color: #fff;
  background-color: #12823b;
  border-color: #12823b; }
  .btn-success:hover {
    color: #fff;
    background-color: #0d602c;
    border-color: #0c5527; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #0d602c;
    border-color: #0c5527;
    box-shadow: 0 0 0 0.2rem rgba(54, 149, 88, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #12823b;
    border-color: #12823b; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #0c5527;
    border-color: #0a4a22; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(54, 149, 88, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #cf0a2c;
  border-color: #cf0a2c; }
  .btn-danger:hover {
    color: #fff;
    background-color: #ab0824;
    border-color: #9e0822; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #ab0824;
    border-color: #9e0822;
    box-shadow: 0 0 0 0.2rem rgba(214, 47, 76, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #cf0a2c;
    border-color: #cf0a2c; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #9e0822;
    border-color: #92071f; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(214, 47, 76, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #cf0a2c;
  border-color: #cf0a2c; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #cf0a2c;
    border-color: #cf0a2c; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(207, 10, 44, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #cf0a2c;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #cf0a2c;
    border-color: #cf0a2c; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(207, 10, 44, 0.5); }

.btn-outline-secondary {
  color: transparent;
  border-color: transparent; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: transparent;
    border-color: transparent; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: transparent;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: transparent;
    border-color: transparent; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.btn-outline-success {
  color: #12823b;
  border-color: #12823b; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #12823b;
    border-color: #12823b; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(18, 130, 59, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #12823b;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #12823b;
    border-color: #12823b; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(18, 130, 59, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #cf0a2c;
  border-color: #cf0a2c; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #cf0a2c;
    border-color: #cf0a2c; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(207, 10, 44, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #cf0a2c;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #cf0a2c;
    border-color: #cf0a2c; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(207, 10, 44, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #444;
  text-decoration: none; }
  .btn-link:hover {
    color: #1e1e1e;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.1875rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.1875rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.1875rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 544px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 769px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #00a1e0; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.76562rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.1875rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.1875rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.1875rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.15625rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #00a1e0;
    background-color: #00a1e0; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 161, 224, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #61d2ff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #94e1ff;
    border-color: #94e1ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.1875rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #00a1e0;
  background-color: #00a1e0; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 161, 224, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 161, 224, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 161, 224, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.15625rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 161, 224, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.1875rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #61d2ff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 161, 224, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.76562rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #61d2ff;
    box-shadow: 0 0 0 0.2rem rgba(0, 161, 224, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.1875rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.1875rem 0.1875rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #f9f9f9, 0 0 0 0.2rem rgba(0, 161, 224, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #f9f9f9, 0 0 0 0.2rem rgba(0, 161, 224, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #f9f9f9, 0 0 0 0.2rem rgba(0, 161, 224, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #00a1e0;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #94e1ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #00a1e0;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #94e1ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #00a1e0;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #94e1ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 0 solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: 0; }
  .nav-tabs .nav-link {
    border: 0 solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #f9f9f9;
    border-color: #dee2e6 #dee2e6 #f9f9f9; }
  .nav-tabs .dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.1875rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #00a1e0; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-xs, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.33594rem;
  padding-bottom: 0.33594rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.1875rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 543.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-xs, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 544px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-xs, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 768.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-xs, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 769px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-xs, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-xs, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-xs, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-xs, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-xs, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-xs, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-xs, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.1875rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.1875rem - 1px);
      border-top-right-radius: calc(0.1875rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.1875rem - 1px);
      border-bottom-left-radius: calc(0.1875rem - 1px); }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.1875rem - 1px) calc(0.1875rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.1875rem - 1px) calc(0.1875rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.1875rem - 1px);
  border-top-right-radius: calc(0.1875rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.1875rem - 1px);
  border-bottom-left-radius: calc(0.1875rem - 1px); }

.card-deck .card {
  margin-bottom: 8px; }

@media (min-width: 544px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -8px;
    margin-left: -8px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 8px;
      margin-bottom: 0;
      margin-left: 8px; } }

.card-group > .card {
  margin-bottom: 8px; }

@media (min-width: 544px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 544px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.1875rem; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #6c757d;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.1875rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #444;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #1e1e1e;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 161, 224, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #00a1e0;
  border-color: #00a1e0; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.1875rem;
  border-bottom-left-radius: 0.1875rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.1875rem;
  border-bottom-right-radius: 0.1875rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.1875rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #cf0a2c; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #9e0822; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(207, 10, 44, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: transparent; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: rgba(0, 0, 0, 0); }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5); }

.badge-success {
  color: #fff;
  background-color: #12823b; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #0c5527; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(18, 130, 59, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #cf0a2c; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #9e0822; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(207, 10, 44, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.1875rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.8125rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #6c0517;
  background-color: #f5ced5;
  border-color: #f2bac4; }
  .alert-primary hr {
    border-top-color: #eea5b2; }
  .alert-primary .alert-link {
    color: #3b030d; }

.alert-secondary {
  color: rgba(0, 0, 0, 0.48);
  background-color: rgba(255, 255, 255, 0.8);
  border-color: rgba(255, 255, 255, 0.72); }
  .alert-secondary hr {
    border-top-color: rgba(242, 242, 242, 0.72); }
  .alert-secondary .alert-link {
    color: rgba(0, 0, 0, 0.48); }

.alert-success {
  color: #09441f;
  background-color: #d0e6d8;
  border-color: #bddcc8; }
  .alert-success hr {
    border-top-color: #acd3ba; }
  .alert-success .alert-link {
    color: #03170a; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #6c0517;
  background-color: #f5ced5;
  border-color: #f2bac4; }
  .alert-danger hr {
    border-top-color: #eea5b2; }
  .alert-danger .alert-link {
    color: #3b030d; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0.1875rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #00a1e0;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.1875rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #00a1e0;
    border-color: #00a1e0; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.1875rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.1875rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 544px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.1875rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.1875rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 769px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.1875rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.1875rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.1875rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.1875rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.1875rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.1875rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #6c0517;
  background-color: #f2bac4; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #6c0517;
    background-color: #eea5b2; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #6c0517;
    border-color: #6c0517; }

.list-group-item-secondary {
  color: rgba(0, 0, 0, 0.48);
  background-color: rgba(255, 255, 255, 0.72); }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: rgba(0, 0, 0, 0.48);
    background-color: rgba(242, 242, 242, 0.72); }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.48);
    border-color: rgba(0, 0, 0, 0.48); }

.list-group-item-success {
  color: #09441f;
  background-color: #bddcc8; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #09441f;
    background-color: #acd3ba; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #09441f;
    border-color: #09441f; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #6c0517;
  background-color: #f2bac4; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #6c0517;
    background-color: #eea5b2; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #6c0517;
    border-color: #6c0517; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.1875rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.1875rem - 1px);
  border-top-right-radius: calc(0.1875rem - 1px); }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 0 solid #dee2e6;
  border-bottom-right-radius: calc(0.1875rem - 1px);
  border-bottom-left-radius: calc(0.1875rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 544px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.1875rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.1875rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.1875rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.1875rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.1875rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #000;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.1875rem - 1px);
  border-top-right-radius: calc(0.1875rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #cf0a2c !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #9e0822 !important; }

.bg-secondary {
  background-color: transparent !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: rgba(0, 0, 0, 0) !important; }

.bg-success {
  background-color: #12823b !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #0c5527 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #cf0a2c !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #9e0822 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #cf0a2c !important; }

.border-secondary {
  border-color: transparent !important; }

.border-success {
  border-color: #12823b !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #cf0a2c !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.1875rem !important; }

.rounded {
  border-radius: 0.1875rem !important; }

.rounded-top {
  border-top-left-radius: 0.1875rem !important;
  border-top-right-radius: 0.1875rem !important; }

.rounded-right {
  border-top-right-radius: 0.1875rem !important;
  border-bottom-right-radius: 0.1875rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.1875rem !important;
  border-bottom-left-radius: 0.1875rem !important; }

.rounded-left {
  border-top-left-radius: 0.1875rem !important;
  border-bottom-left-radius: 0.1875rem !important; }

.rounded-lg {
  border-radius: 0.1875rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 544px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 769px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 544px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 769px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 544px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 769px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.m-6 {
  margin: 2rem !important; }

.mt-6,
.my-6 {
  margin-top: 2rem !important; }

.mr-6,
.mx-6 {
  margin-right: 2rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 2rem !important; }

.ml-6,
.mx-6 {
  margin-left: 2rem !important; }

.m-7 {
  margin: 2.5rem !important; }

.mt-7,
.my-7 {
  margin-top: 2.5rem !important; }

.mr-7,
.mx-7 {
  margin-right: 2.5rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 2.5rem !important; }

.ml-7,
.mx-7 {
  margin-left: 2.5rem !important; }

.m-8 {
  margin: 3.5rem !important; }

.mt-8,
.my-8 {
  margin-top: 3.5rem !important; }

.mr-8,
.mx-8 {
  margin-right: 3.5rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 3.5rem !important; }

.ml-8,
.mx-8 {
  margin-left: 3.5rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.p-6 {
  padding: 2rem !important; }

.pt-6,
.py-6 {
  padding-top: 2rem !important; }

.pr-6,
.px-6 {
  padding-right: 2rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 2rem !important; }

.pl-6,
.px-6 {
  padding-left: 2rem !important; }

.p-7 {
  padding: 2.5rem !important; }

.pt-7,
.py-7 {
  padding-top: 2.5rem !important; }

.pr-7,
.px-7 {
  padding-right: 2.5rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 2.5rem !important; }

.pl-7,
.px-7 {
  padding-left: 2.5rem !important; }

.p-8 {
  padding: 3.5rem !important; }

.pt-8,
.py-8 {
  padding-top: 3.5rem !important; }

.pr-8,
.px-8 {
  padding-right: 3.5rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 3.5rem !important; }

.pl-8,
.px-8 {
  padding-left: 3.5rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-n6 {
  margin: -2rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -2rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -2rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -2rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -2rem !important; }

.m-n7 {
  margin: -2.5rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -2.5rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -2.5rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -2.5rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -2.5rem !important; }

.m-n8 {
  margin: -3.5rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -3.5rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -3.5rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -3.5rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -3.5rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 544px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .m-sm-6 {
    margin: 2rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 2rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 2rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 2rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 2rem !important; }
  .m-sm-7 {
    margin: 2.5rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 2.5rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 2.5rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 2.5rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 2.5rem !important; }
  .m-sm-8 {
    margin: 3.5rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 3.5rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 3.5rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 3.5rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 3.5rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .p-sm-6 {
    padding: 2rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 2rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 2rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 2rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 2rem !important; }
  .p-sm-7 {
    padding: 2.5rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 2.5rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 2.5rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 2.5rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 2.5rem !important; }
  .p-sm-8 {
    padding: 3.5rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 3.5rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 3.5rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 3.5rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 3.5rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-n6 {
    margin: -2rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -2rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -2rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -2rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -2rem !important; }
  .m-sm-n7 {
    margin: -2.5rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -2.5rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -2.5rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -2.5rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -2.5rem !important; }
  .m-sm-n8 {
    margin: -3.5rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -3.5rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -3.5rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -3.5rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -3.5rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 769px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .m-md-6 {
    margin: 2rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 2rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 2rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 2rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 2rem !important; }
  .m-md-7 {
    margin: 2.5rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 2.5rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 2.5rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 2.5rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 2.5rem !important; }
  .m-md-8 {
    margin: 3.5rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 3.5rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 3.5rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 3.5rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 3.5rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .p-md-6 {
    padding: 2rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 2rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 2rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 2rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 2rem !important; }
  .p-md-7 {
    padding: 2.5rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 2.5rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 2.5rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 2.5rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 2.5rem !important; }
  .p-md-8 {
    padding: 3.5rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 3.5rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 3.5rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 3.5rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 3.5rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-n6 {
    margin: -2rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -2rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -2rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -2rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -2rem !important; }
  .m-md-n7 {
    margin: -2.5rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -2.5rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -2.5rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -2.5rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -2.5rem !important; }
  .m-md-n8 {
    margin: -3.5rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -3.5rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -3.5rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -3.5rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -3.5rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .m-lg-6 {
    margin: 2rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 2rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 2rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 2rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 2rem !important; }
  .m-lg-7 {
    margin: 2.5rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 2.5rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 2.5rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 2.5rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 2.5rem !important; }
  .m-lg-8 {
    margin: 3.5rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 3.5rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 3.5rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 3.5rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 3.5rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .p-lg-6 {
    padding: 2rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 2rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 2rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 2rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 2rem !important; }
  .p-lg-7 {
    padding: 2.5rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 2.5rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 2.5rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 2.5rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 2.5rem !important; }
  .p-lg-8 {
    padding: 3.5rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 3.5rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 3.5rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 3.5rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 3.5rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-n6 {
    margin: -2rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -2rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -2rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -2rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -2rem !important; }
  .m-lg-n7 {
    margin: -2.5rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -2.5rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -2.5rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -2.5rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -2.5rem !important; }
  .m-lg-n8 {
    margin: -3.5rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -3.5rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -3.5rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -3.5rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -3.5rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .m-xl-6 {
    margin: 2rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 2rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 2rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 2rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 2rem !important; }
  .m-xl-7 {
    margin: 2.5rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 2.5rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 2.5rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 2.5rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 2.5rem !important; }
  .m-xl-8 {
    margin: 3.5rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 3.5rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 3.5rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 3.5rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 3.5rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .p-xl-6 {
    padding: 2rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 2rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 2rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 2rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 2rem !important; }
  .p-xl-7 {
    padding: 2.5rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 2.5rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 2.5rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 2.5rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 2.5rem !important; }
  .p-xl-8 {
    padding: 3.5rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 3.5rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 3.5rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 3.5rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 3.5rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-n6 {
    margin: -2rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -2rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -2rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -2rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -2rem !important; }
  .m-xl-n7 {
    margin: -2.5rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -2.5rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -2.5rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -2.5rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -2.5rem !important; }
  .m-xl-n8 {
    margin: -3.5rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -3.5rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -3.5rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -3.5rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -3.5rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 544px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 769px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #cf0a2c !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #86061c !important; }

.text-secondary {
  color: transparent !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: rgba(0, 0, 0, 0) !important; }

.text-success {
  color: #12823b !important; }

a.text-success:hover, a.text-success:focus {
  color: #093f1d !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #cf0a2c !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #86061c !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #151415 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #ccc; }
  .table .thead-dark th {
    color: inherit;
    border-color: #ccc; } }

.carousel .icon-prev,
.carousel .icon-next {
  background-color: #fff;
  font-size: 1.875em;
  height: 3rem;
  padding-top: 0.24em;
  width: 3rem; }
  .carousel .icon-prev::before,
  .carousel .icon-next::before {
    color: black;
    font-family: 'FontAwesome'; }

.carousel .icon-prev::before {
  content: '\f104'; }

.carousel .icon-next::before {
  content: '\f105'; }

.carousel .carousel-control-prev {
  justify-content: flex-start; }

.carousel .carousel-control-next {
  justify-content: flex-end; }

.nav-tabs {
  border-bottom: 1px solid #ccc; }
  .nav-tabs .nav-link {
    font-size: 1.1rem;
    color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.active {
      border-bottom: 0.188em solid var(--skin-primary-color-1); }

.card {
  margin-bottom: 1em; }

.card-header h4 {
  margin-bottom: 0; }

.modal .modal-body {
  flex: 0 0 auto; }

dt {
  color: #495057;
  font-weight: normal; }

.custom-checkbox .custom-control-label::before {
  border: 1px solid black;
  background: #f9f9f9;
  /* For browsers that do not support gradients */
  background: linear-gradient(#f9f9f9, #ccc);
  /* Standard syntax */ }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23ff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-label::before {
  border: 1px solid black;
  background: #ccc;
  /* For browsers that do not support gradients */
  background: linear-gradient(#ccc, #666);
  /* Standard syntax */ }

.form-control.is-invalid {
  background-image: none; }

.carousel .carousel-control-prev,
.carousel .carousel-control-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #fff;
  border-radius: 100%; }

.carousel .carousel-control-prev {
  background-image: url("../images/left-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.25rem; }

.carousel .carousel-control-next {
  background-image: url("../images/right-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.25rem; }

.carousel .carousel-item {
  transition-duration: 0.4s; }

.form-group.required .form-control-label::before {
  content: "*";
  color: #c00; }

.login-page {
  margin-top: 1em; }
  .login-page .login-form-nav {
    margin-bottom: 1.25em; }
    .login-page .login-form-nav .tab-pane {
      margin-top: 1em; }
  .login-page .login {
    margin-bottom: 0.938em; }
    .login-page .login .error-message-form {
      padding: 0.625em;
      background-color: #c00;
      color: #fff;
      border-radius: 0.188em; }

.registration-page .privacy-policy {
  margin-top: 1rem; }

.login-banner {
  background-image: url("../images/account.jpg");
  background-position-y: 40%; }

.equal-height .card {
  width: 100%; }

.track-order-header {
  font-size: 1.75rem; }

.request-password-title {
  font-size: 1.5rem; }

.alert-description {
  color: #cf0a2c; }

.nb-modal .reset-password-text,
.reset-password-page .reset-password-text {
  color: #000;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 2rem; }

.nb-modal .enter-email-address-text,
.reset-password-page .enter-email-address-text {
  color: #000;
  letter-spacing: 0;
  line-height: 1.25rem;
  margin-bottom: 3rem; }
  @media (max-width: 768.98px) {
    .nb-modal .enter-email-address-text,
    .reset-password-page .enter-email-address-text {
      margin-bottom: 2.75rem; } }

.nb-modal .reset-password-input-container,
.reset-password-page .reset-password-input-container {
  margin-bottom: 2rem; }
  @media (max-width: 768.98px) {
    .nb-modal .reset-password-input-container,
    .reset-password-page .reset-password-input-container {
      margin-bottom: 1.5rem; } }

.free-shipping-login {
  border-radius: 0.25rem;
  background-color: #efefef !important; }

.login-page .have-account {
  display: none; }

.login-page .nav-tabs {
  border-radius: 4px;
  border: 2px solid #f2f2f3; }
  .login-page .nav-tabs .nav-item {
    flex: 1 1; }
    .login-page .nav-tabs .nav-item:first-of-type .nav-link {
      border-radius: 4px 0 0 4px; }
    .login-page .nav-tabs .nav-item:last-of-type .nav-link {
      border-radius: 0 4px 4px 0; }
  .login-page .nav-tabs .nav-link {
    background-color: #f2f2f3; }
    .login-page .nav-tabs .nav-link.active {
      background-color: #fff;
      font-weight: 500;
      color: #000; }

.login-page .card {
  border-radius: 4px;
  border: 1px solid #abb0b4;
  padding: 1rem;
  color: #000;
  display: flex;
  gap: 12px; }
  .login-page .card h3 {
    font-size: 1.125rem;
    line-height: 19px; }
  .login-page .card-body {
    padding: 12px 0; }
  .login-page .card .login-divider {
    border-top: 1px solid #737b82;
    color: #737b82;
    text-align: center;
    margin-top: 32px; }
    .login-page .card .login-divider span {
      position: relative;
      top: -12px;
      display: inline-block;
      background: #fff;
      padding: 0 16px;
      font-weight: 500;
      text-transform: capitalize; }
  .login-page .card-form .button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px; }
  .login-page .card-form .font-body.error {
    color: #cf0a2c;
    padding: 4px 12px;
    border: 1px solid #cf0a2c;
    border-radius: 4px;
    margin: 8px;
    text-align: center;
    background: rgba(207, 10, 44, 0.2); }
  .login-page .card-form .card-body {
    display: flex;
    flex-direction: column;
    gap: 12px; }
    .login-page .card-form .card-body .input-wrapper {
      position: relative;
      margin: 0; }
    .login-page .card-form .card-body .row {
      margin: 0;
      gap: 12px; }
    .login-page .card-form .card-body .col-12,
    .login-page .card-form .card-body .col-lg-6,
    .login-page .card-form .card-body .col-lg-4 {
      padding: 0; }
    .login-page .card-form .card-body .col-lg-6 {
      flex: none;
      width: calc(50% - 6px); }
    .login-page .card-form .card-body .col-lg-4 {
      flex: none;
      width: calc(33.33333% - 8px); }
    .login-page .card-form .card-body .form-group {
      margin-bottom: 0; }
    .login-page .card-form .card-body .form-group input:not([type=radio]):not([type=checkbox]),
    .login-page .card-form .card-body .form-group select {
      border: 1px solid #abb0b4;
      border-radius: 4px;
      padding: 28px 11px 12px;
      height: 58px;
      min-height: 0;
      line-height: inherit;
      font-size: 1rem; }
      .login-page .card-form .card-body .form-group input:not([type=radio]):not([type=checkbox]).is-invalid,
      .login-page .card-form .card-body .form-group select.is-invalid {
        border: 1px solid #92071f;
        background-image: none; }
      .login-page .card-form .card-body .form-group input:not([type=radio]):not([type=checkbox]):focus ~ label,
      .login-page .card-form .card-body .form-group input:not([type=radio]):not([type=checkbox]):not(:placeholder-shown) ~ label,
      .login-page .card-form .card-body .form-group select:focus ~ label,
      .login-page .card-form .card-body .form-group select:not(:placeholder-shown) ~ label {
        color: #5c6268;
        font-size: 0.875rem;
        top: 12px;
        line-height: 1;
        left: 0; }
    .login-page .card-form .card-body .form-group select {
      padding: 24px 11px 12px; }
    .login-page .card-form .card-body .invalid-feedback {
      font-size: 0.875rem;
      margin: 0;
      color: #92071f; }
    .login-page .card-form .card-body input[type="date"]:not(.has-no-value)::-webkit-datetime-edit {
      opacity: 1; }
    .login-page .card-form .card-body input[type="date"].has-no-value::-webkit-datetime-edit {
      opacity: 0; }
    .login-page .card-form .card-body label {
      font-size: 0.875rem;
      color: #5c6268;
      position: absolute;
      z-index: 1;
      top: 12px;
      font-weight: 300;
      line-height: 1;
      text-transform: lowercase; }
      .login-page .card-form .card-body label::first-letter {
        text-transform: capitalize; }
    .login-page .card-form .card-body .font-body:not(.text-main) {
      color: #5c6268; }
    .login-page .card-form .card-body .custom-checkbox label {
      top: 4px;
      left: 24px;
      min-width: 100px;
      color: #151415;
      font-weight: 500;
      padding: 0; }
      .login-page .card-form .card-body .custom-checkbox label::after, .login-page .card-form .card-body .custom-checkbox label::before {
        top: 0;
        border-radius: 4px; }
      .login-page .card-form .card-body .custom-checkbox label + div {
        padding: 2px; }
    .login-page .card-form .card-body .custom-checkbox .custom-control-input:not(:checked) + .custom-control-label::after {
      border: 1px solid #000;
      background-image: none; }
    .login-page .card-form .card-body .hide-show {
      position: absolute;
      z-index: 1;
      right: 12px;
      top: 18px; }
    .login-page .card-form .card-body .hide-password {
      display: none; }
    .login-page .card-form .card-body .list-style-disc {
      font-size: 0.875rem;
      list-style-type: disc; }
      .login-page .card-form .card-body .list-style-disc li {
        color: #737b82; }
    .login-page .card-form .card-body .requirements-list {
      list-style-type: disc;
      color: #5c6268; }

.account-wrapper.change-email {
  width: 450px;
  margin-top: 24px;
  min-height: 50vh; }
  .account-wrapper.change-email .text-wrapper {
    text-align: center;
    margin-bottom: 16px; }
  .account-wrapper.change-email .card-footer {
    margin-top: 32px; }

.modal-open {
  overflow: hidden; }
  @media (max-width: 991.98px) {
    .modal-open {
      position: fixed;
      -webkit-overflow-scrolling: touch; } }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal.fade:not(.nb-modal).right .modal-dialog {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: 0.3s;
  -webkit-transition: 0.3s; }

.modal:not(.nb-modal) {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 100%;
  outline: 0; }
  .modal:not(.nb-modal) .modal-dialog {
    position: fixed;
    margin: auto;
    pointer-events: none;
    height: 100%;
    width: 100%;
    right: -100%;
    transform: translate3d(0, 0, 0);
    transition: all 0.3s; }
    @media (min-width: 769px) {
      .modal:not(.nb-modal) .modal-dialog {
        width: 60%;
        right: -60%; } }
    @media (min-width: 992px) {
      .modal:not(.nb-modal) .modal-dialog {
        width: 35%;
        right: -35%; } }
    .modal:not(.nb-modal) .modal-dialog.adyenModal {
      pointer-events: auto;
      border-radius: 0.1875rem;
      border: 1px solid black;
      background-color: white; }
  .modal:not(.nb-modal).zoom-modal .modal-dialog {
    width: 100%; }
  .modal:not(.nb-modal).zoom-modal .modal-body {
    width: 100%; }
  .modal:not(.nb-modal).show .modal-dialog {
    right: 0; }
  .modal:not(.nb-modal) .modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem); }
    .modal:not(.nb-modal) .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 1rem);
      overflow: hidden; }
    .modal:not(.nb-modal) .modal-dialog-scrollable .modal-header,
    .modal:not(.nb-modal) .modal-dialog-scrollable .modal-footer {
      flex-shrink: 0; }
    .modal:not(.nb-modal) .modal-dialog-scrollable .modal-body {
      overflow-y: auto; }
  .modal:not(.nb-modal) .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem); }
    .modal:not(.nb-modal) .modal-dialog-centered::before {
      display: block;
      height: calc(100vh - 1rem);
      content: ""; }
    .modal:not(.nb-modal) .modal-dialog-centered.modal-dialog-scrollable {
      flex-direction: column;
      justify-content: center;
      height: 100%; }
      .modal:not(.nb-modal) .modal-dialog-centered.modal-dialog-scrollable .modal-content {
        max-height: none; }
      .modal:not(.nb-modal) .modal-dialog-centered.modal-dialog-scrollable::before {
        content: none; }
  .modal:not(.nb-modal) .modal-content {
    height: 100%;
    overflow-y: auto;
    border-radius: 0;
    border: none;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0; }
  .modal:not(.nb-modal) .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000; }
    .modal:not(.nb-modal) .modal-backdrop.fade {
      opacity: 0; }
    .modal:not(.nb-modal) .modal-backdrop.show {
      opacity: 0.5; }
  .modal:not(.nb-modal) .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem; }
    .modal:not(.nb-modal) .modal-header .close {
      margin: 0 -1rem -1rem auto; }
  .modal:not(.nb-modal) .modal-title {
    margin-bottom: 0;
    line-height: 1.5; }
  .modal:not(.nb-modal) .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem; }
    .modal:not(.nb-modal) .modal-body .btn-outline-danger {
      border: solid 0.125rem #cf0a2c;
      font-weight: 700; }
    @media (max-width: 991.98px) {
      .modal:not(.nb-modal) .modal-body .hidden-md-down {
        display: block !important; } }
  .modal:not(.nb-modal) .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 0 solid #dee2e6;
    border-bottom-right-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem; }
    .modal:not(.nb-modal) .modal-footer > :not(:first-child) {
      margin-left: 0.25rem; }
    .modal:not(.nb-modal) .modal-footer > :not(:last-child) {
      margin-right: 0.25rem; }
  .modal:not(.nb-modal) .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll; }
  @media (min-width: 544px) {
    .modal:not(.nb-modal) .modal-dialog-scrollable {
      max-height: calc(100% - 3.5rem); }
      .modal:not(.nb-modal) .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem); }
    .modal:not(.nb-modal) .modal-dialog-centered {
      min-height: calc(100% - 3.5rem); }
      .modal:not(.nb-modal) .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem); }
    .modal:not(.nb-modal) .modal-sm {
      max-width: 300px; } }
  @media (min-width: 992px) {
    .modal:not(.nb-modal) .modal-lg,
    .modal:not(.nb-modal) .modal-xl {
      max-width: 800px; } }
  @media (min-width: 1200px) {
    .modal:not(.nb-modal) .modal-xl {
      max-width: 1140px; } }

.nb-modal.modal.show .nb-modal-container {
  transform: translateX(0%); }

.nb-modal * {
  box-sizing: border-box; }

.nb-modal .nb-modal-container {
  transition: transform 0.5s cubic-bezier(0.64, 0.04, 0.35, 1);
  transform: translateX(100%); }
  .nb-modal .nb-modal-container .size-chart-container .how-to-measure {
    padding: 0 !important; }
  .nb-modal .nb-modal-container .size-chart-container .tab-layout .nav-tabs {
    background-color: #efefef;
    text-align: left;
    width: fit-content;
    overflow-x: visible;
    padding: 0.25rem; }
    .nb-modal .nb-modal-container .size-chart-container .tab-layout .nav-tabs .nav-item {
      padding: 0 !important;
      min-width: 6.875rem; }
      @media (min-width: 1200px) {
        .nb-modal .nb-modal-container .size-chart-container .tab-layout .nav-tabs .nav-item {
          min-width: 12.5rem; } }
      .nb-modal .nb-modal-container .size-chart-container .tab-layout .nav-tabs .nav-item .nav-link {
        padding: 0.25rem 0.625rem !important;
        font-size: 16px; }
        .nb-modal .nb-modal-container .size-chart-container .tab-layout .nav-tabs .nav-item .nav-link.active {
          border-radius: 0.125rem;
          border: #a4adb6 solid 1px; }
  .nb-modal .nb-modal-container .size-chart-container .tab-layout .tab-content {
    padding-top: 0 !important; }
    .nb-modal .nb-modal-container .size-chart-container .tab-layout .tab-content .tab-pane {
      overflow: auto; }
      .nb-modal .nb-modal-container .size-chart-container .tab-layout .tab-content .tab-pane .py-2 {
        padding-bottom: 0 !important; }
      .nb-modal .nb-modal-container .size-chart-container .tab-layout .tab-content .tab-pane::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: #aaa; }
      .nb-modal .nb-modal-container .size-chart-container .tab-layout .tab-content .tab-pane::-webkit-scrollbar-track {
        border-radius: 0; }
  .nb-modal .nb-modal-container .size-chart-container .pdp-size-chart-table-customizable {
    width: 427px; }
    .nb-modal .nb-modal-container .size-chart-container .pdp-size-chart-table-customizable table {
      width: 200%; }

.nb-modal__panel {
  min-height: 100vh;
  background: white;
  position: relative;
  border: 0;
  min-width: 45%; }
  @media (min-width: 992px) {
    .nb-modal__panel {
      min-width: unset; } }
  .nb-modal__panel::after {
    position: absolute;
    background-color: white;
    content: '';
    top: 0;
    left: 99%;
    height: 100%;
    width: 1000%; }

.nb-modal .modal-header {
  border: 0; }
  .nb-modal .modal-header svg {
    fill: #000; }
  .nb-modal .modal-header .close {
    line-height: 0; }

.nb-modal .modal-dialog {
  padding: 0;
  transition: all 0.3s; }

.nb-modal .modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
  max-width: none; }

.nb-modal .modal-footer {
  width: 100%;
  padding: 0;
  background: #fff; }
  .nb-modal .modal-footer > :not(:first-child) {
    margin-left: 0; }
  .nb-modal .modal-footer > :not(:last-child) {
    margin-right: 0; }

@media (max-width: 991.98px) {
  .nb-modal#unique-id-size-chart {
    overflow-y: hidden; }
    .nb-modal#unique-id-size-chart.modal.show .nb-modal-container {
      transform: translateY(20%); }
    .nb-modal#unique-id-size-chart .nb-modal-container {
      transform: translateY(100%);
      border-radius: 1rem 1rem 0 0;
      overflow: hidden; }
      .nb-modal#unique-id-size-chart .nb-modal-container .nb-modal__panel {
        min-height: 90vh; }
      .nb-modal#unique-id-size-chart .nb-modal-container .modal-body {
        max-height: 74vh;
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 2rem; } }

.size-chart-container .mb-lg-14 {
  margin-bottom: 1rem !important; }

.cancel-order-content .order-heading,
.cancel-order-content .order-email {
  margin-bottom: 2rem; }

.cancel-order-content .order-heading {
  font-weight: 500; }

.cancel-order-content .cancel-reason {
  margin-bottom: 2rem; }

.cancel-order-content .reasons-list .custom-radio .form-check-label::before {
  left: 0; }

#password-reset-form .modal-dialog {
  min-width: 36.5rem; }
  @media (max-width: 991.98px) {
    #password-reset-form .modal-dialog {
      min-width: 100%; } }

@media (min-width: 769px) {
  #password-reset-form .modal-body {
    padding-top: 0.5rem; } }

#password-reset-form .modal-content {
  padding: 0; }
  @media (min-width: 769px) {
    #password-reset-form .modal-content {
      padding: 0 0 0 1.5rem; } }

.experience-commerce_assets-HeroComponent .nb-modal,
.experience-commerce_assets-textRightImageComponent .nb-modal,
.experience-commerce_assets-textLeftImageComponent .nb-modal {
  overflow-x: hidden;
  overflow-y: hidden;
  background: #000; }

.experience-commerce_assets-HeroComponent .modal-dialog,
.experience-commerce_assets-textRightImageComponent .modal-dialog,
.experience-commerce_assets-textLeftImageComponent .modal-dialog {
  height: 100vh;
  width: 100vw; }

.experience-commerce_assets-HeroComponent .modal-content,
.experience-commerce_assets-textRightImageComponent .modal-content,
.experience-commerce_assets-textLeftImageComponent .modal-content {
  border: 0; }

.experience-commerce_assets-HeroComponent .modal-header,
.experience-commerce_assets-textRightImageComponent .modal-header,
.experience-commerce_assets-textLeftImageComponent .modal-header {
  position: absolute;
  right: 0;
  top: 8%;
  z-index: 1050;
  background: transparent; }
  @media (min-width: 544px) {
    .experience-commerce_assets-HeroComponent .modal-header,
    .experience-commerce_assets-textRightImageComponent .modal-header,
    .experience-commerce_assets-textLeftImageComponent .modal-header {
      top: 0; } }
  @media only screen and (orientation: portrait) and (max-width: 1366px) {
    .experience-commerce_assets-HeroComponent .modal-header,
    .experience-commerce_assets-textRightImageComponent .modal-header,
    .experience-commerce_assets-textLeftImageComponent .modal-header {
      top: 5%; } }
  @media only screen and (orientation: landscape) and (max-width: 1366px) {
    .experience-commerce_assets-HeroComponent .modal-header,
    .experience-commerce_assets-textRightImageComponent .modal-header,
    .experience-commerce_assets-textLeftImageComponent .modal-header {
      top: 7%; } }
  .experience-commerce_assets-HeroComponent .modal-header svg,
  .experience-commerce_assets-textRightImageComponent .modal-header svg,
  .experience-commerce_assets-textLeftImageComponent .modal-header svg {
    filter: invert(1);
    fill: #fff; }

.experience-commerce_assets-HeroComponent .modal-body,
.experience-commerce_assets-textRightImageComponent .modal-body,
.experience-commerce_assets-textLeftImageComponent .modal-body {
  padding: 0; }

.experience-commerce_assets-HeroComponent .modal-footer,
.experience-commerce_assets-textRightImageComponent .modal-footer,
.experience-commerce_assets-textLeftImageComponent .modal-footer {
  background: #000; }

@media (max-width: 991.98px) {
  .video-modal-open {
    position: unset;
    -webkit-overflow-scrolling: touch; } }

.shoe-finder-modal {
  min-width: 100%;
  padding: 3.5% 5%; }
  .shoe-finder-modal .modal-content {
    height: 100%; }
  .shoe-finder-modal .modal-body {
    flex: 1 auto; }
  .shoe-finder-modal .shoe-finder-iframe {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    border: none; }
  @media (max-width: 991.98px) {
    .shoe-finder-modal {
      padding: 0; } }

#subscribe-global-modal.iPhone {
  overflow-y: visible;
  overflow-x: visible; }
  #subscribe-global-modal.iPhone input.form-control.subscriber-email {
    font-size: 16px; }

#unique-id-size-chart .nb-modal__panel {
  padding-right: 0 !important;
  padding-left: 0 !important; }
  #unique-id-size-chart .nb-modal__panel .modal-body {
    padding: 14px; }
    #unique-id-size-chart .nb-modal__panel .modal-body button {
      margin-top: 0.7rem !important;
      z-index: 9;
      right: 1rem; }
  #unique-id-size-chart .nb-modal__panel .how-to-measure .description p {
    margin-bottom: 0; }

.modal.modal-v2 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  max-width: 100%; }
  .modal.modal-v2 .modal-dialog {
    width: 620px;
    height: auto;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0;
    transform: translate(-50%, -50%); }
  .modal.modal-v2 .modal-body {
    padding: 0; }
  .modal.modal-v2 .modal-content {
    border-radius: 4px;
    padding: 1.5rem; }
  .modal.modal-v2 .subtext.font-small {
    color: #5c6268;
    font-size: 0.875rem;
    line-height: 1;
    margin-top: 0.25rem; }
    .modal.modal-v2 .subtext.font-small::before {
      content: "*"; }
  .modal.modal-v2 button.close {
    top: 1.5rem;
    right: 1.5rem;
    z-index: 9;
    pointer-events: auto;
    position: absolute;
    border-radius: 4px;
    background-color: #f2f2f3;
    opacity: 1; }
    .modal.modal-v2 button.close svg {
      margin: 9px;
      color: #151415; }
  @media (max-width: 768.98px) {
    .modal.modal-v2:not(.delete) {
      overflow-y: hidden; }
      .modal.modal-v2:not(.delete).modal.show .modal-dialog {
        right: 50%;
        left: 50%;
        transform: translate(-50%, 0); }
      .modal.modal-v2:not(.delete) .modal-dialog {
        bottom: 0;
        left: 50%;
        top: auto;
        transform: translate(-50%, 100%); }
        .modal.modal-v2:not(.delete) .modal-dialog .modal-body {
          border-radius: 20px 20px 0 0;
          overflow: hidden;
          bottom: 0;
          transform: translateY(0); }
      .modal.modal-v2:not(.delete) .modal-content {
        padding: 1rem; }
      .modal.modal-v2:not(.delete) button.close {
        top: 1rem;
        right: 1rem; } }

.modal-v2 .edit-preferences h4 {
  margin-bottom: 0;
  line-height: 2; }

.modal-v2 .edit-preferences .card-form .card-body {
  gap: 0; }

.modal-v2 .edit-preferences .nb-button-secondary {
  margin: 12px 8px 0 0;
  padding: 11px 16px;
  border: 1px solid transparent; }
  .modal-v2 .edit-preferences .nb-button-secondary:hover {
    background-color: #e4e4e7; }
  .modal-v2 .edit-preferences .nb-button-secondary.selected-activity {
    border: 1px solid #151415; }

.modal.modal-v2.delete .modal-dialog {
  width: 360px; }

.modal.modal-v2.delete p {
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  font-weight: 700; }

div[data-action='Address-List'] .modal.modal-v2 {
  overflow: scroll; }
  @media only screen and (max-height: 800px) {
    div[data-action='Address-List'] .modal.modal-v2:not(.delete) .modal-dialog {
      top: 5vh;
      transform: translate(-50%); } }
  @media (max-width: 768.98px) {
    div[data-action='Address-List'] .modal.modal-v2 {
      overflow-y: scroll; }
      div[data-action='Address-List'] .modal.modal-v2:not(.delete) .modal-dialog {
        top: 25%; } }

body.nb-modal-display {
  overflow: hidden; }

/* mixins for reusable components */
@media (min-width: 992px) {
  .pr-lg-25 {
    padding-right: 0.75rem !important; }
  .mt-lg-55 {
    margin-top: 2.25rem !important; }
  .mb-lg-55 {
    margin-bottom: 2.25rem !important; }
  .my-lg-20 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important; }
  .p-lg-20 {
    padding: 10rem !important; }
  .mt-lg-21 {
    margin-top: 10.5rem !important; }
  .mb-lg-16 {
    margin-bottom: 8rem !important; }
  .mb-lg-14 {
    margin-bottom: 6.5rem !important; }
  .mt-lg-14 {
    margin-top: 6.5rem !important; }
  .ml-lg-12 {
    margin-left: 5.5rem !important; }
  .mt-lg-12 {
    margin-top: 5.5rem !important; }
  .mb-lg-10 {
    margin-bottom: 4.5rem !important; }
  .px-lg-10 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important; }
  .mb-lg-9 {
    margin-bottom: 4rem !important; }
  .pt-lg-9 {
    padding-top: 4rem !important; }
  .mt-lg-9 {
    margin-top: 4rem !important; }
  .pr-lg-9 {
    padding-right: 4rem !important; }
  .ml-lg-11 {
    margin-left: 5rem !important; } }

@media (min-width: 769px) {
  .pb-md-16 {
    padding-bottom: 8rem !important; }
  .my-md-10 {
    margin-bottom: 4.5rem !important;
    margin-top: 4.5rem !important; }
  .pt-md-9 {
    padding-top: 4rem !important; } }

.mb-25 {
  margin-bottom: 0.75rem !important; }

.mt-25 {
  margin-top: 0.75rem !important; }

.mt-14 {
  margin-top: 6.5rem !important; }

.mt-10 {
  margin-top: 4.5rem !important; }

.modal.show .modal-content .modal-body .invalid-email .error {
  color: #cf0a2c; }

.modal.show .modal-content .modal-body .subscribe-heading {
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  line-height: 2.5rem; }

.modal.show .modal-content .modal-body .hide {
  display: none; }

.modal.show .modal-content .modal-body .subscribed.show {
  display: inline-block !important; }

.modal.show .modal-content .modal-body .subscriber-email {
  border-bottom: 1px solid #000; }

select {
  border-bottom: 1px solid #000;
  display: inline-block;
  font: inherit;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0; }
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000; }
  select.minimal {
    background-image: none; }

@media (max-width: 543.98px) {
  .collapsible-md .title::after {
    content: "" !important; } }

@media (max-width: 768.98px) {
  .collapsible-md .title::after {
    content: "" !important; } }

@media (max-width: 991.98px) {
  .collapsible-md .title::after {
    content: "" !important; } }

@media (max-width: 1199.98px) {
  .collapsible-md .title::after {
    content: "" !important; } }

.collapsible-md .title::after {
  content: "" !important; }

.triangle {
  position: absolute;
  left: 33%;
  top: 35%; }
  .triangle::after {
    content: '\25bc';
    font-size: 0.7rem; }

.email-content .subscribed {
  font-weight: 700; }

a,
button,
h2,
input,
textarea,
select {
  outline: none;
  box-shadow: none; }
  .form-group a, .form-group
  button, .form-group
  h2, .form-group
  input, .form-group
  textarea, .form-group
  select {
    outline: none;
    box-shadow: none; }
  a:focus,
  button:focus,
  h2:focus,
  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
    box-shadow: none; }
    .form-group a:focus, .form-group
    button:focus, .form-group
    h2:focus, .form-group
    input:focus, .form-group
    textarea:focus, .form-group
    select:focus {
      outline: none;
      box-shadow: none; }

.btn:focus, .btn.focus {
  box-shadow: none; }

.mouse-focus button,
.mouse-focus input[type=button],
.mouse-focus input[type=reset],
.mouse-focus input[type=submit] {
  outline: 0; }

.keyboard-focus a:focus,
.keyboard-focus button:focus,
.keyboard-focus input:focus,
.keyboard-focus input[type=button]:focus,
.keyboard-focus input[type=reset]:focus,
.keyboard-focus input[type=submit]:focus,
.keyboard-focus select:focus,
.keyboard-focus textarea:focus {
  outline: none;
  box-shadow: 0 0 0 2px #015ecc, 0 0 0 4px #fff; }

@media (min-width: 992px) {
  .left-margin-lg {
    margin-left: -8px; } }

.tooltip {
  font-weight: 300; }

.hidden {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden; }

.modal-background {
  background-color: #000;
  display: none;
  height: 100%;
  position: fixed;
  opacity: 0.5;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000; }

input[placeholder] {
  text-overflow: ellipsis; }

.hide-order-discount {
  display: none; }

.hide-shipping-discount {
  display: none; }

.order-discount {
  color: #12823b; }

.shipping-discount {
  color: #12823b; }

.error-messaging {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1; }

.error-hero {
  background-image: url("../images/storelocator.jpg");
  margin-bottom: 0.625em; }

.error-message {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem; }

.error.continue-shopping {
  margin-bottom: 6.25em; }

.error-unassigned-category {
  color: #c00; }

.js-load-url {
  min-height: 12rem; }

.skip {
  position: absolute;
  left: 0;
  top: -4.2em;
  overflow: hidden;
  padding: 1em 1.5em;
  background: #fff;
  transition: all 0.2s ease-in-out; }

a.skip:active,
a.skip:focus,
a.skip:hover {
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  z-index: 10000000;
  background: #fff;
  transition: all 0.2s ease-in-out; }

.card-header-custom {
  font-size: 1.5rem;
  margin-bottom: 0; }

/* adding the additional common styles */
.no-gutter,
.no-gutters {
  margin-right: 0;
  margin-left: 0; }

a,
a:hover {
  color: #000; }

input[placeholder] {
  text-overflow: ellipsis; }

.form-control:focus {
  box-shadow: none;
  border-color: #151415; }

.nb-sprite {
  background: url("../images/nb-sprites.png");
  display: inline-flex; }

.subscribe-title h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold !important; }

.subscribe-form .form-control {
  font-size: 0.875rem;
  line-height: 1.428;
  font-weight: 500;
  color: #000; }
  .subscribe-form .form-control::placeholder {
    color: #000; }

.body-regular {
  color: #000;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0;
  font-weight: 300; }

.body-semibold {
  color: #000;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0;
  font-weight: 500; }

.body-bold {
  color: #000;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0;
  font-weight: 500; }

.body-large {
  color: #000;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0;
  font-weight: 300; }

@media (min-width: 992px) {
  .only-mobile {
    display: none !important; } }

@media (max-width: 991.98px) {
  .only-desktop {
    display: none !important; } }

.seo-text {
  max-width: 100%;
  display: flex;
  flex-flow: column;
  flex-wrap: wrap; }
  @media (min-width: 992px) {
    .seo-text {
      max-width: 65%; } }
  .seo-text .seo-content {
    font-size: 12px; }

.category-seo-text .seo-headline {
  font-size: 0.875rem; }

@media (min-width: 992px) {
  .category-seo-text {
    margin: 0 30%; }
    .category-seo-text.hidefilter {
      margin: 0 auto; } }

@media (max-width: 991.98px) {
  .sticky-button-warpper {
    position: fixed;
    bottom: 0;
    background: white;
    z-index: 99;
    width: 100%; } }

/* PREORDER MESSAGING */
.preorder-cart-msg {
  background-color: #fee67a;
  font-weight: 300;
  padding: 10px 20px; }
  .preorder-cart-msg .preorder-only-one-msg {
    font-weight: 700; }

/* COLOR SWATCHES */
.product-details .color-style,
.product-quickview .color-style {
  display: flex;
  flex-flow: row wrap;
  width: auto; }

.product-details .style-attribute,
.product-quickview .style-attribute {
  min-width: auto;
  height: 62px;
  border: 0 !important;
  border-radius: 0 !important;
  margin: 0 7px 7px 0 !important; }
  @media (max-width: 991.98px) {
    .product-details .style-attribute,
    .product-quickview .style-attribute {
      width: 58px;
      height: 58px;
      min-width: auto !important;
      margin: 0 5px 5px 0 !important;
      border: none !important; } }

.product-details .style-value,
.product-quickview .style-value {
  width: 62px;
  height: 62px;
  display: inline-block;
  overflow: hidden;
  background-size: contain; }
  @media (max-width: 991.98px) {
    .product-details .style-value,
    .product-quickview .style-value {
      width: 58px;
      height: 58px; } }

.product-details .select-attribute-grid,
.product-quickview .select-attribute-grid {
  display: grid; }

.product-details .attribute-grid-3,
.product-quickview .attribute-grid-3 {
  grid-template-columns: repeat(3, 1fr); }

.product-details .attribute-grid-5,
.product-quickview .attribute-grid-5 {
  grid-template-columns: repeat(5, 1fr); }

@media (max-width: 543.98px) {
  .menu-toggleable-left.navbar-toggleable-xs {
    position: fixed;
    top: -100%;
    right: 0;
    bottom: 0;
    transition: all 0.3s cubic-bezier(0.64, 0.04, 0.35, 1);
    display: block;
    max-width: 100%;
    transform: translate(0, -100%); }
    .menu-toggleable-left.navbar-toggleable-xs.in {
      min-width: 100%;
      top: 0;
      right: 0;
      transform: translate(0);
      padding: 0 20px; } }

@media (max-width: 768.98px) {
  .menu-toggleable-left.navbar-toggleable-sm {
    position: fixed;
    top: -100%;
    right: 0;
    bottom: 0;
    transition: all 0.3s cubic-bezier(0.64, 0.04, 0.35, 1);
    display: block;
    max-width: 100%;
    transform: translate(0, -100%); }
    .menu-toggleable-left.navbar-toggleable-sm.in {
      min-width: 100%;
      top: 0;
      right: 0;
      transform: translate(0);
      padding: 0 20px; } }

@media (max-width: 991.98px) {
  .menu-toggleable-left.navbar-toggleable-md {
    position: fixed;
    top: -100%;
    right: 0;
    bottom: 0;
    transition: all 0.3s cubic-bezier(0.64, 0.04, 0.35, 1);
    display: block;
    max-width: 100%;
    transform: translate(0, -100%); }
    .menu-toggleable-left.navbar-toggleable-md.in {
      min-width: 100%;
      top: 0;
      right: 0;
      transform: translate(0);
      padding: 0 20px; } }

@media (max-width: 1199.98px) {
  .menu-toggleable-left.navbar-toggleable-lg {
    position: fixed;
    top: -100%;
    right: 0;
    bottom: 0;
    transition: all 0.3s cubic-bezier(0.64, 0.04, 0.35, 1);
    display: block;
    max-width: 100%;
    transform: translate(0, -100%); }
    .menu-toggleable-left.navbar-toggleable-lg.in {
      min-width: 100%;
      top: 0;
      right: 0;
      transform: translate(0);
      padding: 0 20px; } }

.menu-toggleable-left.navbar-toggleable-xl {
  position: fixed;
  top: -100%;
  right: 0;
  bottom: 0;
  transition: all 0.3s cubic-bezier(0.64, 0.04, 0.35, 1);
  display: block;
  max-width: 100%;
  transform: translate(0, -100%); }
  .menu-toggleable-left.navbar-toggleable-xl.in {
    min-width: 100%;
    top: 0;
    right: 0;
    transform: translate(0);
    padding: 0 20px; }

.navbar.bg-inverse {
  background-color: transparent !important;
  padding: 0; }
  @media (min-width: 992px) {
    .navbar.bg-inverse .navbar-nav .nav-item + .nav-item {
      margin-left: 0;
      margin-left: 2px; }
    .navbar.bg-inverse .navbar-nav .nav-link {
      padding: 1.9rem 0.7rem;
      white-space: nowrap; } }
  @media only screen and (min-width: 992px) and (min-width: 1240px) {
    .navbar.bg-inverse .navbar-nav .nav-link {
      padding: 1.9rem 1.25rem; } }
  @media (min-width: 992px) {
    .navbar.bg-inverse .logo-home {
      padding: 0 1rem 0 0; }
    .navbar.bg-inverse .nav-item .nav-label:hover {
      padding: 0 0 0.875rem;
      border-bottom: 2px solid #cf0a2c; }
    .navbar.bg-inverse .nav-item.show {
      position: static; }
      .navbar.bg-inverse .nav-item.show .nav-label {
        padding: 0 0 0.875rem;
        border-bottom: 2px solid #cf0a2c; }
    .navbar.bg-inverse .dropdown-toggle::after {
      content: none; } }
  .navbar.bg-inverse .menu-flyout {
    animation: menuFadeIn 0.3s; }
    @media (max-width: 991.98px) {
      .navbar.bg-inverse .menu-flyout {
        padding: 0;
        height: 100%;
        overflow: scroll; } }

@keyframes menuFadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
    .navbar.bg-inverse .menu-flyout > ul {
      display: flex;
      flex-wrap: wrap;
      /* featured category changes */ }
      .navbar.bg-inverse .menu-flyout > ul .category-image {
        order: 2;
        margin-left: auto; }
      .navbar.bg-inverse .menu-flyout > ul > li,
      .navbar.bg-inverse .menu-flyout > ul .category-image {
        width: 100%; }
      .navbar.bg-inverse .menu-flyout > ul li {
        padding: 0; }
        .navbar.bg-inverse .menu-flyout > ul li a {
          font-weight: 500; }
        .navbar.bg-inverse .menu-flyout > ul li ul li a {
          font-weight: 300; }
      .navbar.bg-inverse .menu-flyout > ul .Featured .dropdown-link,
      .navbar.bg-inverse .menu-flyout > ul .top-category a {
        font-weight: 700; }
      @media (max-width: 991.98px) {
        .navbar.bg-inverse .menu-flyout > ul .dropdown > .dropdown-link::after {
          content: "";
          background-image: url("../../images/right-icon.svg");
          width: 16px;
          height: 16px;
          display: inline-block;
          position: absolute;
          background-size: cover;
          right: 10px;
          top: 35%;
          border: 0; } }
      .navbar.bg-inverse .menu-flyout > ul > li.Featured > a {
        display: none; }
      @media (max-width: 991.98px) {
        .navbar.bg-inverse .menu-flyout > ul > li.Featured {
          order: 1;
          border: none;
          padding-right: 0 !important; }
          .navbar.bg-inverse .menu-flyout > ul > li.Featured .dropdown-menu {
            position: relative;
            left: 0;
            padding: 0; } }
      @media (min-width: 992px) {
        .navbar.bg-inverse .menu-flyout > ul .category-image {
          width: 30%; }
        .navbar.bg-inverse .menu-flyout > ul > li {
          width: 15%; }
          .navbar.bg-inverse .menu-flyout > ul > li.Featured a {
            font-weight: 500; }
          .navbar.bg-inverse .menu-flyout > ul > li.Featured ul {
            padding: 0; }
          .navbar.bg-inverse .menu-flyout > ul > li.use-partition {
            border-left: 2px solid #d8d8d8; }
          .navbar.bg-inverse .menu-flyout > ul > li > a {
            text-transform: uppercase;
            font-size: 0.75rem;
            font-weight: 500;
            line-height: 1.25rem;
            letter-spacing: 0.8px; }
          .navbar.bg-inverse .menu-flyout > ul > li ul li {
            padding: 0;
            line-height: 2rem; }
            .navbar.bg-inverse .menu-flyout > ul > li ul li a {
              line-height: 1.5rem;
              font-weight: 300; } }
    .navbar.bg-inverse .menu-flyout .dropdown-toggle::after {
      content: none;
      right: -6px !important; }

.nav-item.dropdown button.dropdown-toggle {
  display: none;
  position: sticky;
  margin: 1.6875rem 0 0 -1.5rem;
  height: 1.5rem;
  width: 1.5rem;
  line-height: 1.5rem; }
  .nav-item.dropdown button.dropdown-toggle svg {
    padding: 0 0.375rem 0; }

.keyboard-focus .nav-item.dropdown button.dropdown-toggle {
  display: block; }

@media (max-width: 991.98px) {
  .main-menu.menu-toggleable-left {
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: #fff;
    z-index: 4; } }

.menu-toggleable-left .close-menu {
  width: 100%;
  background: white;
  padding: 16px;
  border-bottom: 1px solid #c7cacd;
  flex: 0 0 100%;
  position: sticky;
  overflow: auto;
  z-index: 1;
  top: 0; }
  @media (min-width: 992px) {
    .menu-toggleable-left .close-menu {
      display: none; } }

.menu-toggleable-left .dropdown-toggle::after {
  border: none; }

.menu-toggleable-left .menu-group {
  margin: auto; }
  @media (max-width: 991.98px) {
    .menu-toggleable-left .menu-group {
      max-height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      padding-top: 20px;
      flex: 0 0 100%; } }

.menu-toggleable-left .bar {
  border-right: 2px solid #d8d8d8; }

.menu-toggleable-left li > .close-menu {
  margin-top: -0.6rem;
  position: sticky;
  width: 100%;
  overflow: auto;
  z-index: 1;
  top: 0;
  padding: 25px 17px 17px !important; }

.menu-toggleable-left .close-menu .back .px-0 {
  line-height: 1;
  border-radius: 0.1875rem;
  padding: 7px 8px 6px !important; }

@media (max-width: 991.98px) {
  .menu-toggleable-left .bg-inverse {
    background-color: white !important;
    color: #000; } }

@media (max-width: 768.98px) {
  .menu-toggleable-left.in {
    right: 0; } }

@media (max-width: 991.98px) {
  .menu-toggleable-left.in .menu-group > ul > li,
  .menu-toggleable-left.in .dropdown-item {
    border: 1px solid #c7cacd;
    border-radius: 4px;
    margin-bottom: 10px; }
  .menu-toggleable-left.in .dropdown {
    display: block;
    position: static; }
  .menu-toggleable-left.in .country-selector {
    position: relative; }
  .menu-toggleable-left.in .dropdown-toggle {
    padding-left: 1rem; }
    .menu-toggleable-left.in .dropdown-toggle::after {
      content: "";
      background-image: url("../../images/right-icon.svg");
      width: 16px;
      height: 16px;
      display: inline-block;
      position: absolute;
      background-size: cover;
      right: 10px;
      top: 35%;
      border: 0; }
    .menu-toggleable-left.in .dropdown-toggle.country-selector::after {
      background-image: none; }
  .menu-toggleable-left.in .nav-item .nav-link,
  .menu-toggleable-left.in .list-unstyled .dropdown-link {
    padding: 1rem;
    position: relative;
    line-height: 1.25rem;
    font-size: 1rem;
    color: #000;
    text-decoration: none;
    font-weight: 500 !important; }
  .menu-toggleable-left.in .nav-link {
    padding: 1rem !important; }
  .menu-toggleable-left.in .flyout-footer .nav-link {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .menu-toggleable-left.in .dropdown-menu {
    display: none; }
  .menu-toggleable-left.in .show > .dropdown-menu {
    right: 0;
    display: block;
    overflow: auto;
    padding-left: 19px;
    padding-right: 19px; } }
  @media (max-width: 991.98px) and (max-width: 991.98px) {
    .menu-toggleable-left.in .show > .dropdown-menu .menu-flyout li.Featured .dropdown-menu {
      display: block; } }
  @media (max-width: 991.98px) and (max-width: 1199.98px) {
    .menu-toggleable-left.in .show > .dropdown-menu .menu-flyout .dropdown-menu .dropdown-menu {
      position: fixed; } }

@media (max-width: 991.98px) {
  .menu-toggleable-left.in .dropdown-menu {
    position: absolute;
    right: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    border: 0 none;
    display: none;
    padding-top: 0.5rem;
    left: auto; }
    .menu-toggleable-left.in .dropdown-menu .nav-menu {
      padding: 1px 0 20px;
      position: sticky;
      top: 0;
      z-index: 1; } }

@media (max-width: 991.98px) {
  .menu-toggleable-left .container {
    max-width: 100%; } }

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:hover {
  background-color: transparent; }

.multilevel-dropdown .dropdown-menu {
  top: 100%;
  width: 100%;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 2rem 0 4rem; }
  .multilevel-dropdown .dropdown-menu .dropdown-item:hover {
    background: none; }
  @media (min-width: 992px) {
    .multilevel-dropdown .dropdown-menu {
      box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4); } }

@media (min-width: 992px) {
  .multilevel-dropdown .dropdown-menu > .dropdown > .dropdown-menu {
    top: -0.65em;
    left: 99%; } }

.multilevel-dropdown .navbar > .close-menu > .back {
  display: none; }

.multilevel-dropdown .close-menu .back .caret-left::after {
  content: "";
  background-image: url("../../images/left-icon.svg");
  width: 16px;
  height: 16px;
  display: inline-block;
  background-size: cover; }

.multilevel-dropdown .dropdown-item .dropdown-link {
  display: block; }

.top-category {
  width: 100%;
  font-size: 1rem;
  font-weight: 700;
  pointer-events: none;
  color: #000;
  background: transparent;
  left: 0; }

/* adding the additional styles here */
@media (min-width: 992px) {
  .main-menu {
    position: static !important; } }

@media screen and (min-width: 1560px) {
  .nb-nav {
    flex: 0 0 50%;
    max-width: 50%; } }

.navbar .nav-link {
  font-size: 1rem;
  font-weight: 300;
  font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.25rem;
  color: #000 !important; }
  @media (max-width: 768.98px) {
    .navbar .nav-link {
      font-weight: 500; } }

.flyout-footer .country-selector-one {
  display: none !important; }

.flyout-footer .nav-label,
.flyout-footer .user .user-message {
  font-size: 1rem;
  font-weight: 300;
  color: #000; }

.flyout-footer .nav-link.no-arrow + .language::after {
  content: none; }

.flyout-footer .arrow-right {
  right: 1rem; }

@media (max-width: 991.98px) {
  .flyout-footer .country-selector .dropdown-item {
    padding: 1rem;
    font-weight: 300; }
  .flyout-footer .close-menu .language .nav-label {
    font-weight: 700; } }

@media (max-width: 991.98px) {
  .nav-chevron-right {
    position: relative; }
    .nav-chevron-right::before {
      content: '';
      background-image: url("../images/right-icon.svg");
      height: 16px;
      width: 16px;
      background-size: contain;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%); } }

body.fixed {
  position: fixed; }

.navbar-header {
  height: 4.375em;
  /* 70/16 */ }
  .navbar-header .user,
  .navbar-header .country-selector,
  .navbar-header .link-selector,
  .navbar-header .minicart,
  .navbar-header .navbar-toggler {
    line-height: 2.25em;
    /* 36/16 */
    height: auto; }
  .navbar-header--has-scrolled {
    background: white; }
  .navbar-header .navbar-toggler {
    font-size: 1.6em;
    width: auto;
    transition: 0.3s cubic-bezier(0.64, 0.04, 0.35, 1);
    padding: 0;
    margin: 0;
    height: 18px;
    right: 1rem;
    line-height: 0; }
    .navbar-header .navbar-toggler--is-active {
      position: unset; }
    .navbar-header .navbar-toggler__container {
      height: 16px;
      margin-left: 1.5rem;
      position: relative; }
  .navbar-header .search-field {
    background-color: transparent; }
    .navbar-header .search-field:focus {
      background-color: #ededed; }
  .navbar-header .user {
    position: relative; }
    .navbar-header .user .popover {
      position: absolute;
      display: none;
      padding: 1em;
      top: 85%;
      left: 0; }
      .navbar-header .user .popover a {
        white-space: nowrap;
        margin-bottom: 0.5em; }
      .navbar-header .user .popover::before {
        left: 1.5rem; }
      .navbar-header .user .popover::after {
        left: 1.5rem; }
      .navbar-header .user .popover.show {
        display: block; }

.brand {
  position: absolute;
  left: 50%;
  display: block;
  text-align: center; }
  .brand img {
    width: 100%; }
  @media (min-width: 992px) {
    .brand {
      width: 14.125em;
      /* 226/16 */
      margin-left: -7.0625em;
      /* 113/16 */
      padding-top: 0.5em; } }
  @media (max-width: 991.98px) {
    .brand {
      width: 4em;
      /* 64/16 */
      margin-left: -2em;
      padding-top: 0.8em; } }

.main-menu .navbar .close-menu button,
.main-menu .navbar .close-button button {
  background-color: #efefef;
  border: 0;
  -webkit-appearance: none;
  padding: 5px 8px 7px !important; }

.header-banner {
  background-color: #151415;
  text-align: center;
  color: #fff; }
  .header-banner > .container {
    min-height: 2.375rem;
    max-width: none; }
    @media (min-width: 992px) {
      .header-banner > .container {
        min-height: 3.25rem; } }
  .header-banner .close-button {
    width: 2.125em; }
    .header-banner .close-button .close {
      opacity: 1;
      color: #fff;
      width: 100%;
      height: 100%;
      background-color: #00a1e0; }
  .header-banner .content {
    padding-top: 0.3125em;
    padding-bottom: 0.3125em; }
  .header-banner .header-promotion {
    color: #fff; }

.minicart {
  vertical-align: top; }
  .minicart .minicart-icon {
    font-size: 1.5em; }
  .minicart a.minicart-link:hover {
    text-decoration: none; }
  .minicart .minicart-quantity {
    background-color: #cf0a2c;
    border-radius: 50%;
    min-width: 1.3em;
    /* 20/16 */
    min-height: 1.3em;
    /* 20/16 */
    line-height: normal;
    display: inline-block;
    text-align: center;
    font-size: 0.8125em;
    /* 13/16 */
    position: absolute;
    top: -0.3rem;
    /* 15/16 */
    right: -0.68rem;
    /* 11/16 */
    color: #fff;
    padding: 1px;
    box-sizing: content-box; }
    @media (min-width: 992px) {
      .minicart .minicart-quantity {
        top: 1rem;
        right: -0.25rem; } }

a.normal {
  color: #00a1e0;
  text-decoration: underline; }

.slide-up {
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
  max-height: 100px;
  overflow: hidden; }
  .slide-up.hide {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1); }

.dropdown-country-selector,
.dropdown-link-selector {
  margin-top: -0.0625em; }
  .dropdown-country-selector .dropdown-item:focus,
  .dropdown-link-selector .dropdown-item:focus {
    background-color: transparent; }

.cookie-warning-messaging.cookie-warning {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  display: none; }

.valid-cookie-warning {
  background-color: #0070d2;
  color: #fff;
  white-space: nowrap; }
  .valid-cookie-warning p {
    margin-top: 0;
    margin-bottom: 0.2em;
    padding-right: 2em; }

/* adding the custom styles to header */
.header-container .header-navigation {
  background: #fff;
  padding: 0;
  margin: 0;
  border-bottom: 0; }
  .header-container .header-navigation.hide-navigation {
    margin: -120px;
    visibility: hidden; }
  @media (min-width: 769px) {
    .header-container .header-navigation {
      border-bottom: solid 1px #efefef; } }
  @media (min-width: 992px) {
    .header-container .header-navigation .header-nav {
      max-width: none !important;
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }
  @media (max-width: 991.98px) {
    .header-container .header-navigation .header-nav {
      padding-left: 0.5rem;
      padding-right: 0.5rem; } }
  .header-container .header-navigation .search {
    width: 100%;
    max-width: 100%; }
    @media (min-width: 992px) {
      .header-container .header-navigation .search {
        max-width: 207px; } }
  .header-container .header-navigation .search-mobile.search-active .site-search .input-wrapper {
    width: 90%; }

.header-container--overlapped {
  min-height: 0 !important; }

@media (min-width: 769px) {
  .header-container--transition.open-search .header-navigation {
    background: #fff; }
  .header-container--transition .header-navigation {
    background: transparent;
    border-color: transparent; } }

.header-container .header-fixed {
  z-index: 999;
  left: 0;
  top: 0; }

.header-container .find-a-store {
  font-weight: 500; }

.header-container .select-country:hover .dropdown-menu,
.header-container .select-country:focus-within .dropdown-menu {
  display: block; }

.header-container .select-country,
.header-container .select-header-links {
  height: 52px;
  display: flex; }

.header-container .locale-selector:hover .language-selector,
.header-container .locale-selector:focus-within .language-selector {
  display: block; }

.header-container .locale-selector:hover .dropdown-open::after,
.header-container .locale-selector:focus-within .dropdown-open::after {
  transform: translateY(-50%) rotate(180deg); }

.header-container .help-links:hover .link-selector,
.header-container .help-links:focus-within .link-selector {
  display: block; }

.header-container .help-links:hover .dropdown-open::after,
.header-container .help-links:focus-within .dropdown-open::after {
  transform: translateY(-50%) rotate(180deg); }

.header-container .country-selector-one {
  font-weight: 500; }
  .header-container .country-selector-one .country-img {
    min-width: 45px;
    min-height: 23px; }
    .header-container .country-selector-one .country-img img {
      height: 1rem; }
    @media (max-width: 991.98px) {
      .header-container .country-selector-one .country-img {
        display: initial;
        justify-content: initial;
        max-width: 1.03rem; }
        .header-container .country-selector-one .country-img img {
          max-width: 100%;
          height: 0.75rem; } }
  .header-container .country-selector-one .country-code {
    padding-top: 2px; }
  .header-container .country-selector-one .canada {
    display: flex;
    justify-content: flex-end; }
    .header-container .country-selector-one .canada img {
      height: 1rem; }
      @media (min-width: 769px) {
        .header-container .country-selector-one .canada img {
          height: 1.25rem; } }
  .header-container .country-selector-one.no-arrow::after {
    content: none; }
  .header-container .country-selector-one .country-name {
    color: #fff; }
    @media (max-width: 991.98px) {
      .header-container .country-selector-one .country-name {
        line-height: 1.25rem; } }
  .header-container .country-selector-one .link-name {
    color: #fff; }
    @media (max-width: 991.98px) {
      .header-container .country-selector-one .link-name {
        line-height: 1.25rem; } }
  .header-container .country-selector-one .dropdown-open::after {
    content: '';
    background-image: url("../images/cheveron-down.svg");
    height: 12px;
    width: 12px;
    background-size: contain;
    position: relative;
    left: 5px;
    bottom: -4px;
    transform: translateY(-50%); }
  .header-container .country-selector-one a {
    padding: 0.125rem 0; }

.header-container .dropdown-country-selector {
  left: auto;
  right: 3.8rem;
  top: 52px;
  background-color: #fff;
  min-width: 200px; }
  .header-container .dropdown-country-selector .dropdown-item {
    color: #151415;
    padding: 0.25rem 1.5rem 0.25rem 1rem;
    letter-spacing: 0.4px; }
  .header-container .dropdown-country-selector .dropdown-item:hover {
    text-decoration: underline;
    background-color: #f2f2f3b5;
    border: 0 #f2f2f3b5;
    border-radius: 5px; }

.header-container .dropdown-link-selector {
  left: auto;
  right: -12px;
  top: 52px;
  background-color: #fff;
  min-width: 200px;
  z-index: 9999; }
  .header-container .dropdown-link-selector .dropdown-item {
    color: #151415;
    letter-spacing: 0.4px; }
    @media (min-width: 992px) {
      .header-container .dropdown-link-selector .dropdown-item {
        padding: 0.25rem 1.5rem 0.25rem 1rem; } }
  .header-container .dropdown-link-selector .dropdown-item:hover {
    text-decoration: underline; }
    @media (min-width: 992px) {
      .header-container .dropdown-link-selector .dropdown-item:hover {
        background-color: #f2f2f3b5;
        border: 0 #f2f2f3b5;
        border-radius: 5px; } }

.header-container .search-mobile {
  margin-bottom: 0.5rem; }
  .header-container .search-mobile.hide {
    display: none; }
  .header-container .search-mobile.show {
    display: block; }
    @media (max-width: 991.98px) {
      .header-container .search-mobile.show {
        margin-bottom: 2px; } }

@media (min-width: 992px) {
  .header-container .logo-home {
    padding: 0 1rem 0 0; } }

.header-container .site-search .reset-button {
  right: 18%;
  top: 20px;
  font-weight: 700; }

.search-input-mr {
  margin-right: 1.5rem !important; }

@media (max-width: 991.98px) {
  .search-mobile.search-active .reset-button {
    z-index: 21;
    position: fixed; } }

.site-search {
  width: 100%;
  max-width: 100%;
  z-index: 1; }
  @media (min-width: 992px) {
    .site-search {
      display: flex !important; } }
  .site-search .input-wrapper {
    width: 100%; }
  .site-search .search-field {
    padding: 7px;
    border: 1px solid #d7d7d7;
    font-size: 1rem;
    font-weight: 300;
    height: 2.625rem; }
    @media (min-width: 992px) {
      .site-search .search-field {
        height: 2.1875rem;
        background-color: #ededed; } }
    .site-search .search-field::placeholder {
      color: #000; }
    .site-search .search-field:focus {
      box-shadow: none;
      color: #000;
      border-bottom-color: #d7d7d7; }
  .site-search .search-button .svg-icon {
    padding-bottom: 0.25rem;
    margin-top: 0.125rem; }

.nb-search .site-search .search-field {
  padding: 8px;
  padding-left: 2.625rem;
  border-radius: 4px !important;
  font-weight: 400;
  font-family: 'Proxima Nova', sans-serif; }

.nb-search .site-search .search-button {
  left: 6px;
  top: 9px; }
  @media (min-width: 769px) {
    .nb-search .site-search .search-button {
      top: 7px; } }

.user .separator {
  width: 1px;
  height: 13px;
  bottom: -1px;
  background-color: #151415; }

.user .user-message,
.user .myaccount {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem; }

.user .auth-user .user-message,
.user .auth-user .myaccount {
  font-size: 14px; }

.user .auth-user .user-message {
  flex: 0; }

.user span.profile {
  max-width: 25%; }

.account-links a span.login-bubble {
  width: 8px;
  height: 8px;
  transform: translate(-50%, -50%); }

.promobarClose.show + .promobarOpen {
  pointer-events: none; }

.banner-right {
  position: absolute;
  top: 0;
  right: 2rem; }
  .banner-right button,
  .banner-right a {
    color: #fff; }
  .banner-right .help-links .nav-link {
    padding: 0; }
  .banner-right .d-lg-block {
    margin: 7px 0 0 10px; }
  .banner-right .dropdown-menu div.d-none.py-2 {
    display: flex !important;
    flex-direction: column; }
    .banner-right .dropdown-menu div.d-none.py-2 .find-a-store {
      color: black; }
  .banner-right .separator {
    width: 0.0625rem;
    background-color: #fff;
    height: 0.75rem;
    position: relative;
    top: 1.2rem; }

.banner-left {
  top: 0;
  left: 0;
  z-index: 1; }
  .banner-left .selected-store {
    line-height: 1.125rem;
    vertical-align: middle; }
  .banner-left .store-locator-icon {
    width: 1rem; }
  .banner-left button,
  .banner-left a {
    color: #fff !important; }

#Promo_bar_details_Container {
  background-color: #efefef;
  z-index: 99;
  max-height: 50vh;
  left: 0;
  overflow: hidden scroll; }

.header-fixed .overlay {
  left: 0;
  top: 0;
  height: 100vh;
  background: rgba(43, 36, 25, 0.4); }

.header-part-store {
  width: 150px; }

/* Need Help CSS for service cloud chat */
.emea-help-chat .trigger {
  position: fixed;
  right: 1.25rem;
  bottom: 0.625rem;
  background-color: #cf0a2c;
  color: #fff;
  text-align: center;
  border-radius: 3.5rem;
  height: 3rem;
  cursor: pointer;
  border: none;
  z-index: 99;
  line-height: 1; }
  @media (min-width: 769px) {
    .emea-help-chat .trigger {
      right: 4.0625rem;
      bottom: 4.0625rem; } }

.emea-help-chat .links-survery {
  cursor: pointer; }

.emea-help-chat .modal:not(.nb-modal) {
  position: fixed;
  top: auto;
  right: auto;
  left: auto;
  bottom: 5.625rem;
  height: 18.75rem; }
  .emea-help-chat .modal:not(.nb-modal) .service-content {
    border-radius: 1.25rem 1.25rem 0 0;
    overflow: hidden; }
    @media (min-width: 769px) {
      .emea-help-chat .modal:not(.nb-modal) .service-content {
        border-radius: 1.25rem;
        border: 1px solid #151415; } }
  .emea-help-chat .modal:not(.nb-modal) .modal-header {
    align-items: center; }
  .emea-help-chat .modal:not(.nb-modal) .modal-body {
    flex: inherit; }

.emea-help-chat .service-close {
  right: 0.5rem;
  font-size: 2.5rem;
  top: 1.5rem;
  line-height: 1;
  width: 2.5rem; }
  @media (min-width: 769px) {
    .emea-help-chat .service-close {
      top: 0.875rem; } }

.emea-help-chat #emeaHelpModal {
  bottom: 0;
  height: 100vh; }

.emea-help-chat .js-question-mark:active {
  background: #000 !important;
  border: #000 !important; }

.emea-help-chat .chat-icon-footer svg {
  filter: invert(1);
  fill: #fff; }

.emea-help-chat .modal.bottom .modal-dialog {
  transform: translate3d(0%, 0, 0); }

.emea-help-chat .modal.bottom.fade .modal-dialog {
  transform: translate3d(0, 100vh, 0);
  transition: transform 0.2s ease-in-out; }

.emea-help-chat .modal.bottom.fade.show .modal-dialog {
  height: auto;
  max-width: 100%;
  transform: translate3d(0, 0, 0);
  right: 0;
  bottom: 0;
  /* cookie policy button overriding the chat modal so set higher z-index */
  z-index: 3147483646; }
  @media (min-width: 769px) {
    .emea-help-chat .modal.bottom.fade.show .modal-dialog {
      right: 1rem;
      max-width: 22.5rem;
      transform: translate3d(0, -1rem, 0); } }

@media (max-width: 768.98px) {
  .emea-help-chat .slider-serive-cloud {
    width: 5.0625rem;
    margin: 0 auto;
    height: 0.25rem;
    background: #dee2e6;
    margin-top: 0.625rem; } }

.cs-header-banner {
  background-color: #fff3cd; }

.free-shipping-member {
  color: #767676; }

/* Mobile search bar styles */
.search-mobile {
  display: none;
  background-color: #fff; }

/* Show search bar when body has 'search-visible' class */
body.search-visible .search-mobile {
  display: block; }

@media (max-width: 991px) {
  /* Adjust logo positioning in mobile header */
  .mobile-logo-container {
    position: absolute;
    left: 50%;
    margin-left: -24px;
    z-index: 1; } }

/* Further adjusting mobile header positioning */
#search-toggle-icon,
.mini-cart {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important; }
  @media (min-width: 992px) {
    #search-toggle-icon,
    .mini-cart {
      margin-left: 0 !important; } }

.navbar-toggler__container {
  margin-right: 0.5rem !important; }

.navbar-toggler,
.account-icon {
  align-items: center;
  justify-content: center; }

.country-selector-one .separator {
  width: 0.0625rem;
  background-color: #fff;
  height: 0.75rem;
  position: relative;
  top: 0.0625rem;
  margin: 0 0.25rem; }

.country-selector-one.has-multiple-locale:hover .country-name {
  text-decoration: underline; }

.minicart {
  /** When Apple pay is not compiled hide it **/
  /* csslint ignore:start */
  /* csslint ignore:end */ }
  .minicart .close {
    font-size: 2rem;
    font-weight: 300; }
  .minicart-items-container .removeBtn {
    display: contents; }
  .minicart-items-container .product-summary {
    -ms-overflow-style: none;
    max-height: calc(100vh - 25.5rem) !important; }
    .minicart-items-container .product-summary .removeBtn {
      display: contents; }
    @media (max-width: 991.98px) {
      .minicart-items-container .product-summary {
        max-height: none !important; } }
    @media (max-width: 991.98px) {
      .minicart-items-container .product-summary .product-row {
        display: none; } }
    .minicart-items-container .product-summary .product-row:first-child {
      display: inline-block; }
    .minicart-items-container .product-summary .line-item-pricing-info,
    .minicart-items-container .product-summary .price {
      font-size: 1rem; }
      .minicart-items-container .product-summary .line-item-pricing-info .value,
      .minicart-items-container .product-summary .price .value {
        font-weight: 500; }
    .minicart-items-container .product-summary .quantity {
      height: 1.7rem;
      width: 2.1rem;
      font-size: 0.875rem;
      border-bottom: 1px solid #737b82;
      z-index: 2;
      background-color: transparent; }
      @media (max-width: 991.98px) {
        .minicart-items-container .product-summary .quantity {
          width: 4rem;
          border-bottom: none;
          background-color: #fff;
          background-image: none; } }
  .minicart-items-container .product-line-item-details {
    overflow-y: unset; }
  .minicart-items-container .line-item-promo {
    line-height: 1rem;
    color: #000; }
  .minicart .checkout-continue div {
    padding-bottom: 0 !important; }
  .minicart .popover {
    position: fixed !important;
    box-sizing: content-box;
    min-width: 34.625rem !important;
    max-width: 34.625rem !important;
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    right: -42rem !important;
    opacity: 0; }
    @media (max-width: 991.98px) {
      .minicart .popover {
        border: none; } }
    .minicart .popover h4 {
      font-size: 1rem; }
    .minicart .popover .cart {
      width: auto; }
    @media (max-width: 991.98px) {
      .minicart .popover .remove-btn {
        display: none; } }
    .minicart .popover .item-image {
      width: 8.5rem;
      height: 8.5rem; }
      .minicart .popover .item-image.oos-cart-item img {
        opacity: 0.5; }
      .minicart .popover .item-image .product-image {
        max-height: 8.5rem; }
      .minicart .popover .item-image .oos-message {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 1.25rem;
        color: #000;
        text-align: center; }
        @media (min-width: 992px) {
          .minicart .popover .item-image .oos-message {
            width: 100%; } }
        @media (max-width: 991.98px) {
          .minicart .popover .item-image .oos-message {
            top: 25%; } }
    .minicart .popover .minicart-success-msg {
      font-size: 1rem;
      color: #000;
      font-weight: 500;
      padding-top: 0 !important;
      padding-bottom: 0.5rem !important; }
    .minicart .popover.show {
      right: 0 !important;
      opacity: 1;
      transition: 0.3s; }
    @media (max-width: 991.98px) {
      .minicart .popover {
        min-width: 45rem !important; } }
    @media (max-width: 543.98px) {
      .minicart .popover {
        left: 0 !important;
        min-width: 100vw !important;
        min-height: 100vh !important;
        top: 0 !important; } }
    .minicart .popover .checkmark::before {
      content: '';
      display: inline-block;
      width: 0.6rem;
      height: 1.1rem;
      border: solid #000;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg); }
    .minicart .popover .line-item-name {
      font-size: 1rem !important;
      font-weight: 700;
      letter-spacing: 0.01rem;
      line-height: 1.5rem;
      color: #000; }
    .minicart .popover .line-item-unit-price {
      font-size: 1rem !important;
      font-weight: 500;
      line-height: 1.5rem;
      color: #000; }
    .minicart .popover .strike-through {
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.5rem;
      margin-right: 0;
      color: #767676;
      text-decoration: line-through; }
      @media (max-width: 991.98px) {
        .minicart .popover .strike-through {
          font-size: 0.875rem;
          line-height: 1.25rem;
          display: inline-block; } }
    .minicart .popover .gender-name {
      font-size: 0.875rem; }
    .minicart .popover .minicart-footer {
      font-size: 1rem; }
    @media (max-width: 991.98px) {
      .minicart .popover .line-item-quantity {
        display: none; } }
    .minicart .popover .line-item-quantity select.custom-select {
      background: none;
      min-width: 2rem;
      width: 2rem;
      height: 1.13rem;
      border-bottom: 1px solid #ccc;
      position: relative;
      z-index: 1; }
    .minicart .popover .line-item-quantity select.error {
      border: 1px solid #cf0a2c !important; }
    .minicart .popover .line-item-quantity .triangle {
      position: absolute;
      left: 6rem;
      top: 4%;
      z-index: 0; }
    .minicart .popover .line-item-quantity .quantity-label {
      font-size: 0.875rem !important;
      width: 4rem; }
    .minicart .popover .item-attributes .product-content {
      font-size: 0.875rem;
      line-height: 1.25rem; }
    .minicart .popover .item-attributes .attribute-label {
      width: 4rem;
      text-transform: capitalize; }
    .minicart .popover .item-attributes .attribute-value {
      width: calc(100% - 4rem); }
    .minicart .popover .line-item-divider {
      color: #737b82; }
    .minicart .popover .minicart-footer {
      font-weight: 500; }
      @media (max-width: 991.98px) {
        .minicart .popover .minicart-footer {
          border: 0; } }
      .minicart .popover .minicart-footer .applepay {
        background-color: #000; }
      .minicart .popover .minicart-footer .minicart-promo-display .approaching-discounts-info:not(:first-child) {
        display: none; }
    .minicart .popover.product-recommendator {
      overflow-y: auto;
      height: 100vh; }
      @media (min-width: 769px) {
        .minicart .popover.product-recommendator .product-summary {
          max-height: calc(100vh - 44rem) !important; } }
  @media screen and (min-width: 769px) and (max-height: 800px) {
    .minicart .popover.product-recommendator .product-summary {
      max-height: calc(100vh - 25.5rem) !important; } }
  .minicart .sub-total-label,
  .minicart .sub-total {
    font-size: 1rem !important;
    font-weight: 500 !important; }
  .minicart .free-shipping {
    font-size: 0.875rem !important;
    font-weight: 500; }
  .minicart .btn {
    font-weight: 700; }
  .minicart .minicart-paypal-button-holder {
    background-color: #009cde;
    height: 3rem;
    overflow: hidden; }
    @media (min-width: 992px) {
      .minicart .minicart-paypal-button-holder {
        height: 3.5rem;
        padding-top: 0.25rem; } }
  .minicart .btn-outline-dark {
    height: 3rem; }
    @media (min-width: 992px) {
      .minicart .btn-outline-dark {
        height: 3.5rem; } }
  .minicart isapplepay.bg-dark {
    display: none; }

@media (max-width: 991.98px) {
  #removeProductModal .modal-header {
    padding: 1rem 0.5rem 0 1rem; } }

@media (max-width: 991.98px) {
  #removeProductModal .modal-body {
    padding: 0.5rem 0.5rem 0;
    min-height: 100vh; } }

@media (max-width: 991.98px) {
  #removeProductModal .modal-body .product-image {
    width: 100%; } }

#removeProductModal .modal-body .product-price-details {
  min-width: 21.5rem; }
  @media (max-width: 991.98px) {
    #removeProductModal .modal-body .product-price-details {
      min-width: 100%; } }

#removeProductModal .modal-body .cart-add-to-wishlist {
  line-height: 1.25rem; }
  #removeProductModal .modal-body .cart-add-to-wishlist .icon.svg-icon {
    width: 1.5rem;
    height: 1.3125rem; }

#removeProductModal .modal-footer {
  position: static;
  background: #fff; }
  @media (max-width: 991.98px) {
    #removeProductModal .modal-footer {
      position: sticky;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1rem 0.5rem; } }

@media (min-width: 992px) {
  .removeButtonCart {
    position: absolute;
    right: 0; }
  .quantyTotalCart {
    max-width: 100% !important;
    flex: 100% !important; }
  .popover-bottom.show .miniCartPageShowPrice {
    display: block !important; }
  .popover-bottom.show .miniCartPageHidePrice {
    display: none; } }

.hide-no-link {
  display: none; }

@media only screen and (min-width: 1560px) {
  .mini-cart-shift-left .popover.show {
    right: calc((100vw - 1440px) / 2) !important; } }

.hero {
  height: 25vw;
  background-size: cover;
  background-position: 50%;
  position: relative; }

@media (min-width: 544px) {
  .slant-down::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-right-width: 0;
    border-left-width: 90vw;
    border-bottom: 4vw solid #fff; } }

@media (min-width: 544px) {
  .slant-up::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-left-width: 0;
    border-right-width: 90vw;
    border-top: 4vw solid #fff; } }

h1.page-title {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700; }

.notify {
  position: fixed;
  top: 0; }

.price {
  color: #222; }
  .price .strike-through {
    text-decoration: line-through;
    color: #999;
    margin-right: 0.938rem; }
  .price .starting,
  .price .range,
  .price .sales {
    font-weight: bold; }
  .price .tiered {
    color: #222; }
    .price .tiered table {
      border-top: 1px solid #ccc;
      margin: 0 auto; }
      .price .tiered table tr:nth-child(odd) {
        background-color: #eee; }
    .price .tiered td,
    .price .tiered span.price {
      font-size: 0.875rem;
      font-weight: bold; }
    .price .tiered td {
      padding: 0.313rem; }
      .price .tiered td.quantity {
        font-weight: normal;
        text-align: right; }
      .price .tiered td.value {
        text-align: left; }
    .price .tiered .table-header {
      font-size: 1.125rem;
      padding: 0.313rem; }
    .price .tiered .column-header {
      font-size: 1rem;
      padding: 0.313rem;
      font-weight: normal; }
    .price .tiered .column-header.quantity {
      text-align: right; }

.price {
  color: #000; }
  .experience-component .price.product-price {
    white-space: nowrap; }
  .price .sales {
    font-weight: 700;
    font-size: 1.125rem; }
    @media (max-width: 991.98px) {
      .price .sales {
        font-size: 1rem;
        line-height: 1.25rem; } }
    .price .sales.discount > .value {
      font-weight: normal; }
  .price .savings {
    color: #c00;
    font-weight: 500 !important;
    display: inline; }

.afterpay-pdp-wrapper .price-value .savings {
  display: none !important; }

@media (max-width: 991.98px) {
  .flex-reverse {
    display: flex;
    flex-flow: column-reverse; } }

.pgp-grid .price-value .savings .percentOff {
  display: none; }

.discount.percentOff {
  color: #c00;
  font-weight: 500 !important;
  display: block;
  font-size: 0.75rem; }

.product-tile .promotions .title.callout {
  color: #c00;
  font-weight: 500 !important;
  display: block; }

.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-beige.disabled {
    opacity: 0.2; }

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f5f5dc;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f5f5dc;
  display: block;
  position: relative; }
  .swatch-filter-beige.disabled {
    opacity: 0.2; }

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-black.disabled {
    opacity: 0.2; }

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000;
  display: block;
  position: relative; }
  .swatch-filter-black.disabled {
    opacity: 0.2; }

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #0070d2;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-blue.disabled {
    opacity: 0.2; }

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0070d2;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #0070d2;
  display: block;
  position: relative; }
  .swatch-filter-blue.disabled {
    opacity: 0.2; }

.swatch-circle-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-brown.disabled {
    opacity: 0.2; }

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a52a2a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #a52a2a;
  display: block;
  position: relative; }
  .swatch-filter-brown.disabled {
    opacity: 0.2; }

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #12823b;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-green.disabled {
    opacity: 0.2; }

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #12823b;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #12823b;
  display: block;
  position: relative; }
  .swatch-filter-green.disabled {
    opacity: 0.2; }

.swatch-circle-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-grey.disabled {
    opacity: 0.2; }

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f979d;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #8f979d;
  display: block;
  position: relative; }
  .swatch-filter-grey.disabled {
    opacity: 0.2; }

.swatch-circle-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-navy.disabled {
    opacity: 0.2; }

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000080;
  display: block;
  position: relative; }
  .swatch-filter-navy.disabled {
    opacity: 0.2; }

.swatch-circle-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-orange.disabled {
    opacity: 0.2; }

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa500;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ffa500;
  display: block;
  position: relative; }
  .swatch-filter-orange.disabled {
    opacity: 0.2; }

.swatch-circle-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-pink.disabled {
    opacity: 0.2; }

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fe249a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #fe249a;
  display: block;
  position: relative; }
  .swatch-filter-pink.disabled {
    opacity: 0.2; }

.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-purple.disabled {
    opacity: 0.2; }

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #800080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #800080;
  display: block;
  position: relative; }
  .swatch-filter-purple.disabled {
    opacity: 0.2; }

.swatch-circle-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-red.disabled {
    opacity: 0.2; }

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f00;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f00;
  display: block;
  position: relative; }
  .swatch-filter-red.disabled {
    opacity: 0.2; }

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-white.disabled {
    opacity: 0.2; }

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fff;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #fff;
  display: block;
  position: relative; }
  .swatch-filter-white.disabled {
    opacity: 0.2; }

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-yellow.disabled {
    opacity: 0.2; }

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff0;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ff0;
  display: block;
  position: relative; }
  .swatch-filter-yellow.disabled {
    opacity: 0.2; }

.swatch-circle-miscellaneous {
  background: linear-gradient(0deg, #821e91 0, #821e91 25%, #edd134 25%, yellow 50%, #edd134 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, #0e5cd1 0, #0e5cd1 50%, #e20b0b 50%, #e20b0b 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  transform: rotate(35deg);
  width: 2.5em; }
  .swatch-circle-miscellaneous.disabled {
    opacity: 0.2; }
  .swatch-circle-miscellaneous.selected::after {
    transform: rotate(-35deg); }

.attribute {
  margin-top: 0.938em; }
  .attribute label {
    display: block; }

.swatch a {
  text-decoration: none; }

@media (max-width: 543.98px) {
  .primary-images {
    margin: 0;
    padding: 0; } }

.prices-add-to-cart-actions {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 50;
  padding-right: 0;
  padding-left: 0;
  left: 0; }
  @media (max-width: 543.98px) {
    .prices-add-to-cart-actions {
      background-color: rgba(255, 255, 255, 0.95);
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); } }
  @media (min-width: 544px) {
    .prices-add-to-cart-actions {
      position: static;
      padding: 0 0.9375em; } }
  .prices-add-to-cart-actions .price {
    text-align: center; }

.prices {
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  text-align: center; }

.cart-and-ipay {
  text-align: center; }
  @media (max-width: 543.98px) {
    .cart-and-ipay {
      padding-bottom: 26px; }
      .cart-and-ipay .btn {
        width: 98%;
        margin: 1%;
        display: block; } }

.add-to-cart-messages {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%; }

.add-to-basket-alert {
  animation: fade 5s linear forwards;
  box-shadow: 1px 1px 5px grey;
  padding: 1em; }

@keyframes fade {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }
  .add-to-basket-alert.show {
    display: block; }

.simple-quantity {
  margin-top: 1em; }

.main-attributes {
  margin-top: 1em; }

.size-chart {
  margin-top: 1.071em; }

div.availability {
  margin-top: 1.071em; }

.bundle-item {
  padding-bottom: 1em;
  border-bottom: 1px solid #ccc; }
  .bundle-item:last-child {
    border-bottom: none; }

.container.product-detail {
  margin-top: 2em;
  margin-bottom: 2em; }
  @media (max-width: 543.98px) {
    .container.product-detail {
      margin-top: 0;
      margin-bottom: 0; } }

.product-option:not(:first-child) {
  margin-top: 1.071em; }

.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-beige.disabled {
    opacity: 0.2; }

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f5f5dc;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f5f5dc;
  display: block;
  position: relative; }
  .swatch-filter-beige.disabled {
    opacity: 0.2; }

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-black.disabled {
    opacity: 0.2; }

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000;
  display: block;
  position: relative; }
  .swatch-filter-black.disabled {
    opacity: 0.2; }

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #0070d2;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-blue.disabled {
    opacity: 0.2; }

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0070d2;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #0070d2;
  display: block;
  position: relative; }
  .swatch-filter-blue.disabled {
    opacity: 0.2; }

.swatch-circle-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-brown.disabled {
    opacity: 0.2; }

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a52a2a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #a52a2a;
  display: block;
  position: relative; }
  .swatch-filter-brown.disabled {
    opacity: 0.2; }

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #12823b;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-green.disabled {
    opacity: 0.2; }

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #12823b;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #12823b;
  display: block;
  position: relative; }
  .swatch-filter-green.disabled {
    opacity: 0.2; }

.swatch-circle-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-grey.disabled {
    opacity: 0.2; }

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f979d;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #8f979d;
  display: block;
  position: relative; }
  .swatch-filter-grey.disabled {
    opacity: 0.2; }

.swatch-circle-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-navy.disabled {
    opacity: 0.2; }

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000080;
  display: block;
  position: relative; }
  .swatch-filter-navy.disabled {
    opacity: 0.2; }

.swatch-circle-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-orange.disabled {
    opacity: 0.2; }

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa500;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ffa500;
  display: block;
  position: relative; }
  .swatch-filter-orange.disabled {
    opacity: 0.2; }

.swatch-circle-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-pink.disabled {
    opacity: 0.2; }

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fe249a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #fe249a;
  display: block;
  position: relative; }
  .swatch-filter-pink.disabled {
    opacity: 0.2; }

.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-purple.disabled {
    opacity: 0.2; }

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #800080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #800080;
  display: block;
  position: relative; }
  .swatch-filter-purple.disabled {
    opacity: 0.2; }

.swatch-circle-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-red.disabled {
    opacity: 0.2; }

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f00;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f00;
  display: block;
  position: relative; }
  .swatch-filter-red.disabled {
    opacity: 0.2; }

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-white.disabled {
    opacity: 0.2; }

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fff;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #fff;
  display: block;
  position: relative; }
  .swatch-filter-white.disabled {
    opacity: 0.2; }

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-yellow.disabled {
    opacity: 0.2; }

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff0;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ff0;
  display: block;
  position: relative; }
  .swatch-filter-yellow.disabled {
    opacity: 0.2; }

.swatch-circle-miscellaneous {
  background: linear-gradient(0deg, #821e91 0, #821e91 25%, #edd134 25%, yellow 50%, #edd134 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, #0e5cd1 0, #0e5cd1 50%, #e20b0b 50%, #e20b0b 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  transform: rotate(35deg);
  width: 2.5em; }
  .swatch-circle-miscellaneous.disabled {
    opacity: 0.2; }
  .swatch-circle-miscellaneous.selected::after {
    transform: rotate(-35deg); }

.attribute {
  margin-top: 0.938em; }
  .attribute label {
    display: block; }

.swatch a {
  text-decoration: none; }

@media (max-width: 543.98px) {
  .primary-images {
    margin: 0;
    padding: 0; } }

.prices-add-to-cart-actions {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 50;
  padding-right: 0;
  padding-left: 0;
  left: 0; }
  @media (max-width: 543.98px) {
    .prices-add-to-cart-actions {
      background-color: rgba(255, 255, 255, 0.95);
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); } }
  @media (min-width: 544px) {
    .prices-add-to-cart-actions {
      position: static;
      padding: 0 0.9375em; } }
  .prices-add-to-cart-actions .price {
    text-align: center; }

.prices {
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  text-align: center; }

.cart-and-ipay {
  text-align: center; }
  @media (max-width: 543.98px) {
    .cart-and-ipay {
      padding-bottom: 26px; }
      .cart-and-ipay .btn {
        width: 98%;
        margin: 1%;
        display: block; } }

.add-to-cart-messages {
  transform: translate(-50%, -50%);
  position: fixed;
  top: 15%;
  left: 50%; }

.add-to-basket-alert {
  animation: fade 5s linear forwards;
  box-shadow: 1px 1px 5px grey;
  padding: 1em; }

@keyframes fade {
  0% {
    opacity: 0; }
  10% {
    opacity: 1; }
  90% {
    opacity: 1; }
  100% {
    opacity: 0; } }
  .add-to-basket-alert.show {
    display: block; }

.simple-quantity {
  margin-top: 1em; }

.main-attributes {
  margin-top: 1em; }

.size-chart {
  margin-top: 1.071em; }

div.availability {
  margin-top: 1.071em; }

.bundle-item {
  padding-bottom: 1em;
  border-bottom: 1px solid #ccc; }
  .bundle-item:last-child {
    border-bottom: none; }

.container.product-detail {
  margin-top: 2em;
  margin-bottom: 2em; }
  @media (max-width: 543.98px) {
    .container.product-detail {
      margin-top: 0;
      margin-bottom: 0; } }

.product-option:not(:first-child) {
  margin-top: 1.071em; }

.quick-view-dialog,
.choose-bonus-product-dialog {
  max-width: 56.25em; }
  .quick-view-dialog .selectable-bonus-product-line-item,
  .choose-bonus-product-dialog .selectable-bonus-product-line-item {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    padding-top: 0.3em;
    padding-bottom: 0.3em; }
  .quick-view-dialog .beenSelected,
  .choose-bonus-product-dialog .beenSelected {
    background-color: #eee; }
  .quick-view-dialog .modal-header,
  .choose-bonus-product-dialog .modal-header {
    background-color: #eee;
    border-bottom: 2px solid #ccc;
    border-top-left-radius: 0.1875rem;
    border-top-right-radius: 0.1875rem; }
    .quick-view-dialog .modal-header .full-pdp-link,
    .choose-bonus-product-dialog .modal-header .full-pdp-link {
      color: var(--skin-primary-color-1); }
    .quick-view-dialog .modal-header .close,
    .choose-bonus-product-dialog .modal-header .close {
      font-size: 2rem;
      line-height: 1.5rem; }
  .quick-view-dialog .modal-title,
  .choose-bonus-product-dialog .modal-title {
    font-size: 1em; }
  .quick-view-dialog .product-name,
  .choose-bonus-product-dialog .product-name {
    font-size: 1.875em; }
  .quick-view-dialog .swatch-circle,
  .choose-bonus-product-dialog .swatch-circle {
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    width: 2.5em;
    height: 2.5em;
    background: #fff;
    -moz-border-radius: 1.25em;
    border-radius: 1.25em;
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    display: inline-block;
    margin-right: 0.313em;
    position: relative; }
    .quick-view-dialog .swatch-circle.color-value[data-selected=true]::after,
    .choose-bonus-product-dialog .swatch-circle.color-value[data-selected=true]::after {
      color: #000;
      content: '\f058';
      display: table-caption;
      font-family: 'FontAwesome';
      font-size: 1.625em;
      left: 0.295em;
      position: absolute; }
    .quick-view-dialog .swatch-circle.color-value.selected::after,
    .choose-bonus-product-dialog .swatch-circle.color-value.selected::after {
      background: #fff;
      border-radius: 50%;
      color: #000;
      content: '\f058';
      display: table-caption;
      font-family: 'FontAwesome';
      font-size: 1.625em;
      height: 0.75em;
      left: 0.31em;
      line-height: 0.8em;
      position: absolute;
      top: 0.35em;
      width: 0.8em; }
    .quick-view-dialog .swatch-circle i.fa-times-circle,
    .choose-bonus-product-dialog .swatch-circle i.fa-times-circle {
      background: #fff;
      border-radius: 50%;
      height: 0.75em;
      line-height: 0.8em;
      width: 0.8em; }
  .quick-view-dialog a[disabled] .swatch-circle,
  .choose-bonus-product-dialog a[disabled] .swatch-circle {
    cursor: not-allowed; }
    .quick-view-dialog a[disabled] .swatch-circle.color-value.selected::after,
    .choose-bonus-product-dialog a[disabled] .swatch-circle.color-value.selected::after {
      background-color: #495057; }
  .quick-view-dialog .availablity-container,
  .choose-bonus-product-dialog .availablity-container {
    text-align: right; }
  .quick-view-dialog .availablity-container,
  .quick-view-dialog .size-chart,
  .choose-bonus-product-dialog .availablity-container,
  .choose-bonus-product-dialog .size-chart {
    margin-top: 0.938em; }
  .quick-view-dialog .modal-content,
  .choose-bonus-product-dialog .modal-content {
    border: 1px solid rgba(0, 0, 0, 0.2); }
  .quick-view-dialog .modal-body,
  .choose-bonus-product-dialog .modal-body {
    max-height: 28.125em;
    /* 450/16 */
    overflow-y: auto; }
  .quick-view-dialog button.close,
  .choose-bonus-product-dialog button.close {
    font-size: 1.25em; }
  .quick-view-dialog .modal-footer,
  .choose-bonus-product-dialog .modal-footer {
    background-color: #fff;
    border: none;
    border-bottom-right-radius: 0.1875rem;
    border-bottom-left-radius: 0.1875rem; }
    .quick-view-dialog .modal-footer .prices .price,
    .choose-bonus-product-dialog .modal-footer .prices .price {
      font-size: 1.6em; }
  .quick-view-dialog .prices .sales,
  .choose-bonus-product-dialog .prices .sales {
    font-size: 1.5rem; }
  .quick-view-dialog .promotions,
  .choose-bonus-product-dialog .promotions {
    text-align: left;
    color: #c00; }
  @media (max-width: 768.98px) {
    .quick-view-dialog .bonus-summary,
    .choose-bonus-product-dialog .bonus-summary {
      font-size: 0.625em; } }
  @media (max-width: 768.98px) {
    .quick-view-dialog .bonus-summary .bonus-product-name,
    .choose-bonus-product-dialog .bonus-summary .bonus-product-name {
      padding: 0; } }
  .quick-view-dialog .pre-cart-products,
  .choose-bonus-product-dialog .pre-cart-products {
    margin-right: 0.125em; }
  .quick-view-dialog .color-attribute,
  .choose-bonus-product-dialog .color-attribute {
    border: none;
    padding: 0;
    background: none; }
  .quick-view-dialog .non-input-label,
  .choose-bonus-product-dialog .non-input-label {
    display: block;
    margin-bottom: 0.5rem; }

.product-tile {
  border: 0;
  margin-bottom: 0; }
  @media (max-width: 991.98px) {
    .product-tile {
      min-height: 23.4375em; } }
  @media (max-width: 768.98px) {
    .product-tile {
      min-height: 19.6875em; } }
  @media (max-width: 543.98px) {
    .product-tile {
      min-height: 13.4375em; } }
  .product-tile .tile-body {
    padding: 0.625em 0 1.875em; }
    .product-tile .tile-body .color-swatches {
      min-height: 2.25em; }
      .product-tile .tile-body .color-swatches .product-tile-color-label {
        cursor: pointer;
        font-size: 1em; }
        @media (max-width: 991.98px) {
          .product-tile .tile-body .color-swatches .product-tile-color-label {
            font-size: 0.9375em; } }
        @media (max-width: 768.98px) {
          .product-tile .tile-body .color-swatches .product-tile-color-label {
            font-size: 0.8125em; } }
    .product-tile .tile-body .price {
      font-size: 1.125em;
      margin-bottom: 0; }
      @media (max-width: 991.98px) {
        .product-tile .tile-body .price {
          font-size: 1.0625em; } }
      @media (max-width: 768.98px) {
        .product-tile .tile-body .price {
          font-size: 1em; } }
      .product-tile .tile-body .price .tiered {
        font-size: 0.875em; }
        .product-tile .tile-body .price .tiered .value {
          font-weight: bold; }
    .product-tile .tile-body .coming-soon-tile {
      text-align: center; }
    .product-tile .tile-body .pdp-link {
      line-height: 1.2; }
      .product-tile .tile-body .pdp-link a {
        font-size: 1em;
        text-decoration: none; }
        @media (max-width: 991.98px) {
          .product-tile .tile-body .pdp-link a {
            font-size: 0.9375em; } }
        @media (max-width: 768.98px) {
          .product-tile .tile-body .pdp-link a {
            font-size: 0.8125em; } }
    .product-tile .tile-body .ratings {
      font-size: 0.9em; }
  .product-tile .image-container {
    position: relative;
    overflow: auto; }
    .product-tile .image-container .quickview {
      position: absolute;
      bottom: 1rem;
      right: 1rem; }
      .product-tile .image-container .quickview i.fa-expand {
        color: rgba(0, 0, 0, 0.7); }
      .product-tile .image-container .quickview i.fa-circle {
        color: rgba(255, 255, 255, 0.7);
        text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); }
    .product-tile .image-container a {
      display: block; }
      .product-tile .image-container a .tile-image {
        width: 100%; }
  .product-tile .swatches a {
    text-decoration: none; }
  .product-tile .swatch-circle {
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    width: 1.8em;
    height: 1.8em;
    background: #fff;
    -moz-border-radius: 0.9em;
    border-radius: 0.9em;
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    display: inline-block;
    margin-right: 0.313em;
    position: relative; }
    .product-tile .swatch-circle.color-value[data-selected=true]::after {
      color: #000;
      content: '\f058';
      display: table-caption;
      font-family: 'FontAwesome';
      font-size: 1.625em;
      left: 0.295em;
      position: absolute; }
    .product-tile .swatch-circle.color-value.selected::after {
      background: #fff;
      border-radius: 50%;
      color: #000;
      content: '\f058';
      display: table-caption;
      font-family: 'FontAwesome';
      font-size: 1.625em;
      height: 0.75em;
      left: 0.31em;
      line-height: 0.8em;
      position: absolute;
      top: 0.35em;
      width: 0.8em; }
    .product-tile .swatch-circle i.fa-times-circle {
      background: #fff;
      border-radius: 50%;
      height: 0.75em;
      line-height: 0.8em;
      width: 0.8em; }

#chooseBonusProductModal .modal-footer .container {
  margin-left: 0;
  width: 100%;
  margin-right: 0; }

#chooseBonusProductModal .select-cbp-container {
  margin-top: auto;
  margin-bottom: auto; }

#chooseBonusProductModal .product-name-wrapper {
  width: 100%; }

#chooseBonusProductModal .bonus-quantity,
#chooseBonusProductModal .bonus-option {
  margin-top: 0.938em; }

#chooseBonusProductModal .bonus-quantity-select {
  min-width: 5em; }

#chooseBonusProductModal .select-bonus-product {
  margin-top: 1em; }

#chooseBonusProductModal .selected-pid {
  border: 1px solid #ccc; }
  #chooseBonusProductModal .selected-pid .bonus-product-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

#chooseBonusProductModal .bonus-product-price {
  text-align: center;
  margin-top: 1em; }

.experience-component .slick-list .image-container {
  overflow: initial; }

.experience-component .slick-list .product-tile {
  padding: 1px; }

.experience-component.experience-commerce_layouts-Tabs .tabbed-carousel .product-tile .product-name,
.experience-component.experience-commerce_layouts-Tabs .tabbed-carousel .product-tile .product-category,
.experience-component.experience-commerce_layouts-Tabs .tabbed-carousel .product-tile .product-price,
.experience-component.experience-commerce_layouts-Tabs .tabbed-carousel .product-tile .sub-badges,
.experience-component .horizontal-product-carousel .product-tile .product-name,
.experience-component .horizontal-product-carousel .product-tile .product-category,
.experience-component .horizontal-product-carousel .product-tile .product-price,
.experience-component .horizontal-product-carousel .product-tile .sub-badges {
  font-size: 0.875rem;
  font-style: normal;
  line-height: 18px !important;
  text-decoration: none; }

.experience-component.experience-commerce_layouts-Tabs .tabbed-carousel .product-tile .product-name,
.experience-component.experience-commerce_layouts-Tabs .tabbed-carousel .product-tile .sub-badges,
.experience-component .horizontal-product-carousel .product-tile .product-name,
.experience-component .horizontal-product-carousel .product-tile .sub-badges {
  margin-bottom: 0 !important;
  padding-bottom: 4px !important;
  font-weight: 500 !important; }

.experience-component.experience-commerce_layouts-Tabs .tabbed-carousel .product-tile .product-price span,
.experience-component.experience-commerce_layouts-Tabs .tabbed-carousel .product-tile .product-category,
.experience-component .horizontal-product-carousel .product-tile .product-price span,
.experience-component .horizontal-product-carousel .product-tile .product-category {
  line-height: 18px !important;
  color: #5c6268 !important;
  font-weight: 300 !important; }

@media (max-width: 991.98px) {
  .experience-component.experience-commerce_layouts-Tabs .tabbed-carousel .product-tile,
  .experience-component .horizontal-product-carousel .product-tile {
    padding: 1px; } }

@media (max-width: 991.98px) {
  .experience-component.experience-commerce_layouts-Tabs .tabbed-carousel:not(.slick-initialized) .odd .product-tile,
  .experience-component.experience-commerce_layouts-Tabs .tabbed-carousel:not(.slick-initialized) .p-tile:nth-child(odd) .product-tile,
  .experience-component .horizontal-product-carousel:not(.slick-initialized) .odd .product-tile,
  .experience-component .horizontal-product-carousel:not(.slick-initialized) .p-tile:nth-child(odd) .product-tile {
    padding-left: 0 !important; } }

@media (max-width: 991.98px) {
  .experience-component.experience-commerce_layouts-Tabs .tabbed-carousel:not(.slick-initialized) .even .product-tile,
  .experience-component.experience-commerce_layouts-Tabs .tabbed-carousel:not(.slick-initialized) .p-tile:nth-child(even) .product-tile,
  .experience-component .horizontal-product-carousel:not(.slick-initialized) .even .product-tile,
  .experience-component .horizontal-product-carousel:not(.slick-initialized) .p-tile:nth-child(even) .product-tile {
    padding-right: 0 !important; } }

.experience-component.experience-commerce_layouts-Tabs .tabbed-carousel .product-tile > .flex-column > div:not(.image-container),
.experience-component .horizontal-product-carousel .product-tile > .flex-column > div:not(.image-container) {
  margin-top: 0 !important;
  padding: 12px 24px 0 !important; }
  @media (max-width: 991.98px) {
    .experience-component.experience-commerce_layouts-Tabs .tabbed-carousel .product-tile > .flex-column > div:not(.image-container),
    .experience-component .horizontal-product-carousel .product-tile > .flex-column > div:not(.image-container) {
      padding: 8px 16px 0 !important; } }

.experience-component.experience-commerce_layouts-Tabs .tabbed-carousel .product-tile > .flex-column > div:not(.image-container) ~ div,
.experience-component .horizontal-product-carousel .product-tile > .flex-column > div:not(.image-container) ~ div {
  padding-top: 0 !important; }

.form-nav .nav-tabs .nav-link {
  color: #333; }

.form-nav .nav-tabs .nav-link.active,
.form-nav .nav-tabs .nav-link.active:focus,
.form-nav .nav-tabs .nav-link.active:hover {
  background-color: #fff;
  color: #333; }

.nav-tabs .nav-link.active {
  background-color: transparent; }

@media (min-width: 992px) {
  .form-nav .nav-item > .nav-link {
    color: #333; } }

.container .breadcrumb {
  border-radius: 0;
  border-bottom: 1px solid #ccc; }

.container .breadcrumb {
  padding: 1.5rem 0;
  border-radius: 0;
  border-bottom: 0;
  margin-bottom: 0; }

.breadcrumb-item {
  font-size: 0.75rem;
  line-height: 1.5rem;
  color: #5c6268; }
  .breadcrumb-item a {
    color: #5c6268; }

.breadcrumb-overlay {
  position: absolute;
  z-index: 100;
  left: 80px;
  top: 16px;
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(8px); }
  .breadcrumb-overlay .breadcrumb-item {
    line-height: 0.875rem; }
    .breadcrumb-overlay .breadcrumb-item a,
    .breadcrumb-overlay .breadcrumb-item a::before {
      color: #000; }

#hamburger-icon-custom {
  width: 1rem;
  height: 1rem;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  cursor: pointer; }

#hamburger-icon-custom span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #000;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out; }

/* Icon 3 */
#hamburger-icon-custom span:nth-child(1) {
  top: 2px; }

#hamburger-icon-custom span:nth-child(2),
#hamburger-icon-custom span:nth-child(3) {
  top: 8px; }

#hamburger-icon-custom span:nth-child(4) {
  top: 14px; }

#hamburger-icon-custom.hamburger-icon--open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%; }

#hamburger-icon-custom.hamburger-icon--open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg); }

#hamburger-icon-custom.hamburger-icon--open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg); }

#hamburger-icon-custom.hamburger-icon--open span:nth-child(4) {
  top: 8px;
  width: 0%;
  left: 50%; }

.veil {
  position: absolute;
  z-index: 100;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .veil .underlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: #000; }

.spinner {
  width: 80px;
  height: 80px;
  text-align: center;
  animation: sk-rotate 2s infinite linear;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -40px;
  margin-left: -40px; }

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-bounce 2s infinite ease-in-out; }

.dot2 {
  top: auto;
  bottom: 0;
  animation-delay: -1s; }

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg); } }

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

.item-attributes {
  vertical-align: top;
  padding-left: 0; }

.line-item-attributes,
.line-item-option {
  font-size: 0.813rem;
  margin: 0; }

.line-item-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: #222;
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 0.313em; }

.line-item-pricing-info {
  margin-bottom: 0; }
  .line-item-pricing-info + .price {
    font-size: 1em;
    font-weight: bolder; }
  .line-item-pricing-info + .unit-price .price {
    font-size: 1em;
    font-weight: bolder; }

.line-item-price-quantity-info {
  margin-top: 0.625em;
  border-top: 1px solid #ccc; }

.line-item-total-text {
  font-size: 0.813em; }

.pricing {
  font-size: 1em;
  font-weight: bolder; }

.item-image {
  height: 5.625em;
  width: 5.625em;
  margin-right: 0.938em;
  flex-grow: 0;
  flex-shrink: 0; }
  .item-image img.product-image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto;
    max-height: 5.625em; }

.non-adjusted-price {
  display: none; }

.line-item-promo {
  color: #12823b;
  font-size: 0.813em; }

.line-item-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.bundled-line-item + .bundled-line-item {
  margin-top: 0.625em; }

.bundle-includes {
  font-size: 0.813em;
  margin-bottom: 0.625em; }

.line-item-divider {
  margin: 0.625em -1.225em 0.625em -1.325em; }

.line-dotted {
  border-top: 0.063em dashed #ccc; }

.line-item-availability {
  font-size: 0.813rem; }

.product-line-item-details {
  overflow-y: auto; }

.minicart {
  position: relative; }
  .minicart h1 {
    font-size: 1rem; }
  .minicart .cart {
    padding-top: 0.625em;
    padding-bottom: 0.625em;
    background-color: #fff; }
  .minicart .remove-btn {
    color: #999;
    float: right;
    background-color: white;
    border: none;
    font-size: 1.625em;
    margin-top: -0.313em;
    padding: 0; }
  .minicart .product-summary {
    margin-right: -0.938em;
    max-height: 21.875em;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 0.938em; }
  .minicart .card-body {
    padding: 0.625em; }
  .minicart .quantity-label {
    font-size: 0.813em; }
  .minicart .quantity {
    width: 100%; }
  .minicart .popover {
    top: 100%;
    left: auto;
    right: 0;
    min-width: 23.44rem;
    max-width: 23.44rem;
    min-height: 22.7rem;
    display: none; }
    .minicart .popover::before {
      left: auto;
      right: 15px; }
    .minicart .popover::after {
      left: auto;
      right: 16px; }
    .minicart .popover.show {
      display: block; }
  .minicart .minicart-footer {
    border-top: 1px solid #ccc; }
  .minicart .estimated-total {
    margin-top: 0.625em; }
  .minicart .sub-total-label {
    font-size: 1em;
    font-weight: 600; }
  .minicart .sub-total {
    font-size: 1em;
    font-weight: 600; }
  .minicart .line-item-divider {
    margin: 0.625em -0.625em 0.625em -1.325em; }
  .minicart .line-item-name {
    width: 90%; }

@media (min-width: 544px) and (max-width: 768.98px) {
  .hide-link-med {
    display: none; } }

@media (min-width: 769px) {
  .hide-no-link {
    display: none; } }

@media (max-width: 543.98px) {
  .hide-no-link {
    display: none; } }

.swatch-circle-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f5f5dc;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-beige.disabled {
    opacity: 0.2; }

.swatch-filter-beige {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f5f5dc;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f5f5dc;
  display: block;
  position: relative; }
  .swatch-filter-beige.disabled {
    opacity: 0.2; }

.swatch-circle-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-black.disabled {
    opacity: 0.2; }

.swatch-filter-black {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000;
  display: block;
  position: relative; }
  .swatch-filter-black.disabled {
    opacity: 0.2; }

.swatch-circle-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #0070d2;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-blue.disabled {
    opacity: 0.2; }

.swatch-filter-blue {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #0070d2;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #0070d2;
  display: block;
  position: relative; }
  .swatch-filter-blue.disabled {
    opacity: 0.2; }

.swatch-circle-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #a52a2a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-brown.disabled {
    opacity: 0.2; }

.swatch-filter-brown {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #a52a2a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #a52a2a;
  display: block;
  position: relative; }
  .swatch-filter-brown.disabled {
    opacity: 0.2; }

.swatch-circle-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #12823b;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-green.disabled {
    opacity: 0.2; }

.swatch-filter-green {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #12823b;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #12823b;
  display: block;
  position: relative; }
  .swatch-filter-green.disabled {
    opacity: 0.2; }

.swatch-circle-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #8f979d;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-grey.disabled {
    opacity: 0.2; }

.swatch-filter-grey {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #8f979d;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #8f979d;
  display: block;
  position: relative; }
  .swatch-filter-grey.disabled {
    opacity: 0.2; }

.swatch-circle-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #000080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-navy.disabled {
    opacity: 0.2; }

.swatch-filter-navy {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #000080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #000080;
  display: block;
  position: relative; }
  .swatch-filter-navy.disabled {
    opacity: 0.2; }

.swatch-circle-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ffa500;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-orange.disabled {
    opacity: 0.2; }

.swatch-filter-orange {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ffa500;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ffa500;
  display: block;
  position: relative; }
  .swatch-filter-orange.disabled {
    opacity: 0.2; }

.swatch-circle-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fe249a;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-pink.disabled {
    opacity: 0.2; }

.swatch-filter-pink {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fe249a;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #fe249a;
  display: block;
  position: relative; }
  .swatch-filter-pink.disabled {
    opacity: 0.2; }

.swatch-circle-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #800080;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-purple.disabled {
    opacity: 0.2; }

.swatch-filter-purple {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #800080;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #800080;
  display: block;
  position: relative; }
  .swatch-filter-purple.disabled {
    opacity: 0.2; }

.swatch-circle-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #f00;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-red.disabled {
    opacity: 0.2; }

.swatch-filter-red {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #f00;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #f00;
  display: block;
  position: relative; }
  .swatch-filter-red.disabled {
    opacity: 0.2; }

.swatch-circle-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #fff;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-white.disabled {
    opacity: 0.2; }

.swatch-filter-white {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #fff;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #fff;
  display: block;
  position: relative; }
  .swatch-filter-white.disabled {
    opacity: 0.2; }

.swatch-circle-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 2.5em;
  height: 2.5em;
  background: #ff0;
  -moz-border-radius: 1.25em;
  border-radius: 1.25em;
  display: block;
  position: relative; }
  .swatch-circle-yellow.disabled {
    opacity: 0.2; }

.swatch-filter-yellow {
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  width: 1.38em;
  height: 1.38em;
  background: #ff0;
  -moz-border-radius: 0.69em;
  border-radius: 0.69em;
  background-color: #ff0;
  display: block;
  position: relative; }
  .swatch-filter-yellow.disabled {
    opacity: 0.2; }

.swatch-circle-miscellaneous {
  background: linear-gradient(0deg, #821e91 0, #821e91 25%, #edd134 25%, yellow 50%, #edd134 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, #0e5cd1 0, #0e5cd1 50%, #e20b0b 50%, #e20b0b 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 0.063em solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25em;
  display: block;
  height: 2.5em;
  position: relative;
  transform: rotate(35deg);
  width: 2.5em; }
  .swatch-circle-miscellaneous.disabled {
    opacity: 0.2; }
  .swatch-circle-miscellaneous.selected::after {
    transform: rotate(-35deg); }

.site-search {
  position: relative;
  height: 2.5em;
  /* 40/16 */ }
  @media (min-width: 544px) {
    .site-search {
      margin-right: 0.5em;
      /* 20/16 */ } }
  @media (min-width: 1200px) {
    .site-search {
      width: 20em;
      /* 320/16 */ } }
  @media (min-width: 769px) and (max-width: 991.98px) {
    .site-search {
      width: 14.0625em;
      /* 225/16 */ } }
  @media (min-width: 544px) and (max-width: 768.98px) {
    .site-search {
      width: 12.5em;
      /* 200/16 */ } }
  .site-search .fa-close,
  .site-search .fa-search {
    position: absolute;
    border: none;
    top: 0.5625em;
    /* 9/16 */
    right: 0.5625em;
    /* 9/16 */
    padding: 0;
    background-color: transparent; }
  .site-search .reset-button {
    position: absolute;
    border: none;
    top: 0.5625em;
    /* 9/16 */
    right: 2em;
    /* 32/16 */
    padding: 0;
    background-color: transparent; }
  .site-search input {
    padding-right: 2rem;
    height: 100%; }

@media (max-width: 543.98px) {
  .header-search .site-search {
    display: none; } }

.suggestions-wrapper {
  position: relative; }

.suggestions {
  display: block;
  position: absolute;
  border: 1px solid #ccc;
  background-color: #fff;
  top: 0;
  right: 0;
  width: 21.875rem;
  z-index: 3; }
  @media (max-width: 543.98px) {
    .suggestions {
      display: flex;
      position: fixed;
      width: 100%; } }
  .suggestions .swatch-circle {
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    width: 2.5em;
    height: 2.5em;
    background: #fff;
    -moz-border-radius: 1.25em;
    border-radius: 1.25em;
    border: 0.063em solid rgba(0, 0, 0, 0.3);
    display: inline-block;
    margin-right: 0.313em;
    position: relative; }
    .suggestions .swatch-circle.color-value[data-selected=true]::after {
      color: #000;
      content: '\f058';
      display: table-caption;
      font-family: 'FontAwesome';
      font-size: 1.625em;
      left: 0.295em;
      position: absolute; }
    .suggestions .swatch-circle.color-value.selected::after {
      background: #fff;
      border-radius: 50%;
      color: #000;
      content: '\f058';
      display: table-caption;
      font-family: 'FontAwesome';
      font-size: 1.625em;
      height: 0.75em;
      left: 0.31em;
      line-height: 0.8em;
      position: absolute;
      top: 0.35em;
      width: 0.8em; }
    .suggestions .swatch-circle i.fa-times-circle {
      background: #fff;
      border-radius: 50%;
      height: 0.75em;
      line-height: 0.8em;
      width: 0.8em; }
  .suggestions .header {
    color: #999;
    font-size: 0.875em;
    padding-top: 0.625em; }
    .suggestions .header:not(:first-child) {
      border-top: 1px solid #ccc; }
  .suggestions .items {
    padding: 0.313em 0; }
  .suggestions .item {
    padding-bottom: 0.625em; }
    .suggestions .item .name {
      margin-top: 0.313em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    @media (max-width: 543.98px) {
      .suggestions .item {
        padding-bottom: 0.938em; } }
  .suggestions .category-parent {
    color: #999;
    font-size: 0.875em; }
  .suggestions .selected {
    background-color: #eee; }
  .suggestions .container {
    list-style-type: none; }

.more-below {
  -moz-border-radius: 1.25em;
  background: #ccc;
  border: 0.063em solid rgba(0, 0, 0, 0.1);
  border-radius: 1.25em;
  bottom: 1.875em;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
  display: none;
  height: 2.5em;
  position: fixed;
  right: 1.875em;
  width: 2.5em; }
  .more-below i.fa-long-arrow-down {
    border-radius: 50%;
    color: #fff;
    display: table-caption;
    height: 0.75em;
    font-size: 1.5rem;
    left: 0.57em;
    line-height: 0.8em;
    position: absolute;
    top: 0.4em;
    width: 0.8em; }

.fluid-image-max-width {
  max-width: 100rem; }

.font-color-black h1,
.font-color-black h2,
.font-color-black h3,
.font-color-black h4,
.font-color-black p,
.font-color-black span {
  color: #000 !important; }

.font-color-white h1,
.font-color-white h2,
.font-color-white h3,
.font-color-white h4,
.font-color-white p,
.font-color-white span {
  color: #fff !important; }

.header_banner {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  height: 100%; }
  @media (max-width: 991.98px) {
    .header_banner {
      position: relative;
      left: 0;
      transform: translateX(0); } }

.cc-quotemarks {
  height: 2.5rem;
  width: 2.125rem;
  background-size: 100%;
  background-image: url("../../images/quotemarks.svg");
  background-repeat: no-repeat; }

@media (max-width: 991.98px) {
  .reverse-children-mobile {
    display: flex;
    flex-direction: column-reverse !important; } }

.red-line {
  width: 5rem;
  height: 0.25rem;
  background: #cf0a2c; }

.disclaimer {
  bottom: 0; }

.didot {
  font-family: "ITC Garamond W01";
  font-size: 3.5rem;
  line-height: 3.75rem; }
  html[data-site="NBJP"] .didot,
  html[data-site="JPOUTLET"] .didot {
    font-size: 2.375rem;
    line-height: 2.625rem; }

.comp-j {
  height: 100%; }
  @media (min-width: 992px) {
    .comp-j {
      height: auto; } }

/* overriding the bootstrap header sizes */
@media (max-width: 991.98px) {
  .search-mobile.search-active .site-search .input-wrapper {
    position: fixed;
    z-index: 10;
    width: 90%;
    background: #fff; } }

.search-mobile.search-active .site-search .close {
  opacity: 1; }
  .search-mobile.search-active .site-search .close:hover {
    opacity: 1; }

.suggestions {
  border: 1px solid transparent;
  top: 1.32rem;
  right: -15rem;
  width: auto;
  color: #000; }
  @media (max-width: 991.98px) {
    .suggestions {
      width: 100% !important;
      top: 2.5rem !important; } }
  .suggestions.four-tile-width {
    width: 70rem; }
  .suggestions.two-tile-width {
    width: 70rem; }
    .suggestions.two-tile-width .tile-items {
      width: 65%; }
      .suggestions.two-tile-width .tile-items .item {
        max-width: 100%;
        flex: 0 0 100%; }
  .suggestions.small-desktop {
    width: 60rem;
    right: -10rem; }
  .suggestions.no-tiles {
    width: 50rem; }
  .suggestions .container {
    padding-left: 1rem; }
    @media (min-width: 992px) {
      .suggestions .container {
        padding: 0 0 3rem 7.5rem; } }
  .suggestions .item {
    padding-bottom: 1rem;
    padding-right: 1.5rem; }
    @media (max-width: 1199.98px) {
      .suggestions .item {
        padding-bottom: 0.5rem;
        padding-right: 0.5rem; } }
    .suggestions .item .name {
      font-size: 1rem;
      line-height: 2rem;
      margin: 0; }
    .suggestions .item .search-phrase {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 300; }
      .suggestions .item .search-phrase a {
        font-weight: 500; }
      @media (max-width: 991.98px) {
        .suggestions .item .search-phrase {
          font-size: 0.875rem;
          line-height: 2.5rem; } }
  .suggestions .modal-background {
    display: block;
    top: 36%; }
  .suggestions .header {
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 2rem;
    color: #000; }
    @media (max-width: 991.98px) {
      .suggestions .header {
        font-size: 1rem;
        line-height: 1.25rem; } }
    .suggestions .header:not(:first-child) {
      border-top: 1px solid transparent; }
  .suggestions .search-terms-suggestions {
    float: right;
    width: 33%;
    margin-bottom: 3rem; }
    @media (max-width: 991.98px) {
      .suggestions .search-terms-suggestions {
        width: 100%;
        float: left;
        margin-bottom: 0.5rem;
        padding-top: 0; } }
    .suggestions .search-terms-suggestions .item {
      padding-bottom: 0;
      padding-right: 1.5rem; }
    .suggestions .search-terms-suggestions .items {
      width: 100%; }
  .suggestions .product-items,
  .suggestions .trending-products {
    float: left;
    width: 67%; }
    @media (max-width: 991.98px) {
      .suggestions .product-items,
      .suggestions .trending-products {
        width: 100%; } }
    .suggestions .product-items.no-products-found,
    .suggestions .trending-products.no-products-found {
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 2rem;
      text-align: center;
      width: 65%; }
      @media (max-width: 991.98px) {
        .suggestions .product-items.no-products-found,
        .suggestions .trending-products.no-products-found {
          text-align: left;
          padding: 1rem;
          font-size: 1rem;
          line-height: 1.5rem;
          width: 100%; } }
    .suggestions .product-items.no-tiles, .suggestions .product-items.search-focus,
    .suggestions .trending-products.no-tiles,
    .suggestions .trending-products.search-focus {
      width: 65%; }
      @media (max-width: 991.98px) {
        .suggestions .product-items.no-tiles, .suggestions .product-items.search-focus,
        .suggestions .trending-products.no-tiles,
        .suggestions .trending-products.search-focus {
          width: 100%; } }
  .suggestions .product-items #product-4 {
    display: none; }
  .suggestions .product-tile {
    min-height: auto;
    line-height: 0px; }
    .suggestions .product-tile .wishListToggle,
    .suggestions .product-tile .quickview,
    .suggestions .product-tile .badges,
    .suggestions .product-tile .arTryOn-badge,
    .suggestions .product-tile .tile-badges,
    .suggestions .product-tile .tile-body-footer {
      display: none; }
    .suggestions .product-tile .quick-view-button-ctn {
      display: none; }
    .suggestions .product-tile .image-container {
      width: 9.5rem;
      display: inline-block;
      padding-right: 1rem;
      box-sizing: border-box; }
      @media (max-width: 991.98px) {
        .suggestions .product-tile .image-container {
          width: 5.5rem;
          padding-right: 0.5rem; } }
      .suggestions .product-tile .image-container .next-main-image {
        display: none; }
    .suggestions .product-tile .tile-body {
      width: 49%;
      display: inline-block;
      vertical-align: top;
      padding: 0; }
      @media (max-width: 991.98px) {
        .suggestions .product-tile .tile-body {
          width: 66%; } }
      .suggestions .product-tile .tile-body .search-tile {
        justify-content: flex-start !important; }
      .suggestions .product-tile .tile-body .pdp-link a {
        font-size: 1rem;
        font-weight: 500 !important;
        line-height: 1.5rem;
        color: #000; }
        @media (max-width: 991.98px) {
          .suggestions .product-tile .tile-body .pdp-link a {
            font-size: 0.875rem;
            line-height: 1.25rem; } }
      .suggestions .product-tile .tile-body .pdp-link .category-name {
        line-height: 1.25rem;
        padding-top: 0 !important; }
      .suggestions .product-tile .tile-body .price,
      .suggestions .product-tile .tile-body .sales,
      .suggestions .product-tile .tile-body .value {
        color: #000;
        padding-top: 1rem;
        font-size: 1rem;
        font-weight: 500 !important;
        line-height: 1.5rem; }
        @media (max-width: 991.98px) {
          .suggestions .product-tile .tile-body .price,
          .suggestions .product-tile .tile-body .sales,
          .suggestions .product-tile .tile-body .value {
            font-size: 0.875rem;
            line-height: 1.25rem;
            padding-top: 0; } }
      .suggestions .product-tile .tile-body .strike-through {
        display: flex; }
        .suggestions .product-tile .tile-body .strike-through .value {
          padding-top: 0;
          color: #767676;
          font-weight: 300 !important; }
      .suggestions .product-tile .tile-body .pgp-reviews-wrapper {
        height: unset; }
      .suggestions .product-tile .tile-body .promotions {
        padding-top: 5px; }
        .suggestions .product-tile .tile-body .promotions .callout {
          font-size: 0.75rem;
          line-height: 1rem; }
    .suggestions .product-tile .pgp-grid .fw-search {
      width: 100%;
      display: inline-block;
      max-width: 100%;
      flex: 0 0 100%; }
    .suggestions .product-tile .pgp-grid.pb-2 {
      padding-bottom: 0 !important; }

.site-search {
  height: 4rem; }
  @media (min-width: 544px) {
    .site-search {
      height: 2.5rem; } }
  .site-search .search-field {
    font-size: 1rem;
    line-height: 1.5rem; }
    @media only screen and (min-width: 1130px) {
      .site-search .search-field {
        font-size: 1rem; } }

.seach-modal-bg {
  background-color: #000;
  height: 100%;
  position: fixed;
  opacity: 0.5;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1; }

@media (max-width: 991.98px) {
  .search-mobile.search-active .suggestions {
    position: static; }
  .search-mobile.search-active .site-search {
    position: fixed;
    top: 0;
    width: 100% !important;
    left: 0;
    background: #fff;
    height: 100%;
    overflow-y: auto; }
  .search-mobile.search-active .search-button {
    display: none; }
  .search-mobile.search-active input.search-field {
    width: calc(100% - 32px);
    margin: 0.5rem 1rem; }
  .search-mobile.search-active .close-search {
    position: fixed;
    right: 0;
    top: 0.625rem;
    z-index: 21;
    padding: 0.5rem 1rem !important; } }

.error-page .logo-icon {
  height: 1.5rem; }

.error-page .header-navigation {
  border-bottom: 1px solid #ccc; }

.error-page .navbar-header {
  height: 3rem; }

@media (min-width: 769px) {
  .error-page .error-heading {
    font-family: "Proxima Nova W01", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.25rem;
    color: #000;
    letter-spacing: 0;
    line-height: 2rem;
    font-weight: 500; } }

.error-page .maintanence-container-error .error-logo {
  margin-left: 43%;
  margin-bottom: -40px;
  margin-top: 30px; }
  @media (min-width: 769px) and (max-width: 991.98px) {
    .error-page .maintanence-container-error .error-logo {
      margin-left: 38%; } }
  @media (max-width: 768.98px) {
    .error-page .maintanence-container-error .error-logo {
      margin-left: 25%; } }

@media (max-width: 1199.98px) {
  #change-country-modal .modal-header {
    padding: 0; }
  #change-country-modal .modal-content {
    padding-right: 1rem;
    padding-top: 1rem; }
  #change-country-modal .modal-body {
    padding: 0; } }

#change-country-modal .country-logo {
  width: 7.5rem; }
  @media (max-width: 1199.98px) {
    #change-country-modal .country-logo {
      width: 5.8125rem; } }

.form-group.required .form-control-label::before {
  content: "*";
  color: #c00; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #151415 !important;
  -webkit-box-shadow: 0 0 0 100px white inset !important; }

.form-control {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #151415;
  padding: 1.5rem 0 0.1875rem;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.25rem;
  min-height: 1.5rem;
  color: #000;
  height: auto; }

.custom-checkbox .custom-control-input + .custom-control-label {
  top: 0;
  pointer-events: all; }
  .custom-checkbox .custom-control-input + .custom-control-label::before {
    color: #fff;
    border-color: none;
    background-color: transparent;
    box-shadow: none; }
  .custom-checkbox .custom-control-input + .custom-control-label::after {
    background-image: url("../../images/unselected.svg");
    background-size: cover;
    background-color: #fff; }

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::after,
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::after,
.custom-checkbox .custom-control-input:focus:not(:checked) ~ .custom-control-label::after,
.custom-checkbox .custom-control-input:focus:not(:checked) ~ .custom-control-label::after {
  outline: 2px auto highlight;
  outline: 2px auto -webkit-focus-ring-color;
  background: transparent;
  outline-offset: 2px; }

.custom-checkbox .custom-control-input:focus:not(:checked) ~ .custom-control-label::before, .custom-checkbox .custom-control-input:focus:not(:checked) ~ .custom-control-label::after,
.custom-checkbox .custom-control-input:focus:not(:checked) ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:focus:not(:checked) ~ .custom-control-label::after {
  border: none; }

.custom-checkbox .custom-control-input:checked + .custom-control-label::after {
  background-image: url("../../images/selected.svg"); }

.custom-radio {
  position: relative; }
  .custom-radio .form-check-input,
  .custom-radio .custom-check-input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
  .custom-radio .form-check-label {
    pointer-events: all; }
    .custom-radio .form-check-label::before {
      position: absolute;
      top: 0.25rem;
      left: -1.5rem;
      display: block;
      width: 1rem;
      height: 1rem;
      pointer-events: none;
      content: "";
      background-color: #fff;
      border: transparent solid 1px; }
    .custom-radio .form-check-label::after {
      position: absolute;
      top: 0.125rem;
      left: 0;
      display: block;
      content: ""; }
  .custom-radio .form-check-input + .form-check-label::after,
  .custom-radio .custom-check-input + .form-check-label::after {
    background-image: url("../../images/inactive.svg");
    width: 16px;
    height: 16px;
    background-size: cover; }
  .custom-radio .form-check-input:focus ~ .form-check-label::after,
  .custom-radio .form-check-input:focus:not(:checked) ~ .form-check-label::after,
  .custom-radio .form-check-input:focus ~ .form-check-label::after,
  .custom-radio .form-check-input:focus:not(:checked) ~ .form-check-label::after {
    outline: 2px auto highlight;
    outline: 2px auto -webkit-focus-ring-color;
    outline-offset: 2px; }
  .custom-radio .form-check-input:checked + .form-check-label::after,
  .custom-radio .custom-check-input:checked + .form-check-label::after {
    background-image: url("../../images/active.svg");
    width: 16px;
    height: 16px;
    background-size: cover; }

.form-group {
  position: relative; }

.form-group > input,
> label {
  padding: var(--input-padding-y) var(--input-padding-x); }

.form-group > label {
  position: absolute;
  top: 1.5rem;
  left: 0;
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  transition: all 0.1s ease-in-out;
  pointer-events: none; }

.form-group input:not([type=radio]):not([type=checkbox])::-webkit-input-placeholder, .form-group input:not([type=radio]):not([type=checkbox])::placeholder,
.form-group textarea::-webkit-input-placeholder,
.form-group textarea::placeholder {
  color: transparent; }

.form-group input:not([type=radio]):not([type=checkbox]):focus, .form-group input:not([type=radio]):not([type=checkbox]):not(:placeholder-shown),
.form-group textarea:focus,
.form-group textarea:not(:placeholder-shown) {
  padding: 1.5rem 0 0.1875rem;
  min-height: 1.25rem;
  color: #000;
  font-weight: 300;
  background: transparent; }

.form-group input:not([type=radio]):not([type=checkbox]):focus ~ label,
.form-group input:not([type=radio]):not([type=checkbox]):-webkit-autofill ~ label,
.form-group input:not([type=radio]):not([type=checkbox]):not(:placeholder-shown) ~ label,
.form-group textarea:focus ~ label,
.form-group textarea:-webkit-autofill ~ label,
.form-group textarea:not(:placeholder-shown) ~ label {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 0.75rem;
  line-height: 1.333;
  height: 1rem;
  color: #000;
  top: 0.5rem;
  font-weight: 300; }

.form-group.required .form-control-label::after {
  content: '*';
  color: #c00; }

.form-group.required .form-control-label::before {
  content: none;
  color: #fff; }

.form-group select:not([value=""]):valid ~ label {
  top: 0.5rem;
  font-size: 0.75rem;
  color: #000;
  font-weight: 300; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.asterisk {
  color: #c00; }

#login .form-group.required .form-control-label::after,
#register .form-group.required .form-control-label::after,
#email-form .form-group.required .form-control-label::after {
  content: none;
  color: #fff; }

.custom-dropdown {
  width: 100%;
  float: left;
  color: inherit;
  font-size: inherit;
  position: relative;
  border: none;
  background: inherit; }
  .custom-dropdown.current_item {
    border: 1px solid #151415; }
    .custom-dropdown.current_item .selection-list {
      display: block;
      border: 1px solid #000; }
    .custom-dropdown.current_item .selected-option::before {
      top: 47%;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      border-color: #000 transparent transparent; }
  .custom-dropdown select {
    position: absolute;
    left: -9999px;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 1px;
    padding: 0;
    border: none; }
  .custom-dropdown.current_item .selected-option {
    border-color: #000; }
  .custom-dropdown .selection-list {
    background-color: #fff;
    list-style: none;
    display: none;
    left: -1px;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100.4%;
    z-index: 9;
    overflow: auto; }
    .custom-dropdown .selection-list.show {
      display: block; }
    .pt_checkout .custom-dropdown .selection-list {
      background-color: #000; }
    .custom-dropdown .selection-list li {
      line-height: 25px;
      outline: none;
      float: left;
      width: 100%;
      padding: 10px 20px;
      cursor: pointer;
      border-bottom: 1px solid #000; }
      .custom-dropdown .selection-list li:last-child {
        border-bottom: 0; }
      .custom-dropdown .selection-list li:hover, .custom-dropdown .selection-list li:active, .custom-dropdown .selection-list li.selected, .custom-dropdown .selection-list li.hover {
        color: #151415;
        background: inherit; }
      .custom-dropdown .selection-list li.selected {
        display: none; }

.custom-selection-list {
  top: 75%;
  position: static; }
  .custom-selection-list:focus {
    outline: none; }
  .custom-selection-list li {
    list-style: none; }
    .custom-selection-list li:active {
      outline: none; }
    .custom-selection-list li:focus .custom-radio label::before {
      content: none; }
    .custom-selection-list li:focus .custom-radio .form-check-input {
      left: -9999px; }
  .custom-selection-list label {
    color: #000 !important; }
  .custom-selection-list .custom-radio label {
    display: inline-block; }
  .custom-selection-list .selected-option {
    border-top: 1px solid #ccc; }

.selected-option.active + .custom-selection-list {
  display: block;
  border-bottom: 1px solid #ccc; }

.custom-dropdown {
  color: #000; }
  .custom-dropdown .display-name {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-transform: capitalize; }
  .custom-dropdown .address-line {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 300; }
  .custom-dropdown .list-item label {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #000; }
    .custom-dropdown .list-item label .display-name {
      font-weight: 500;
      text-transform: capitalize; }
    .custom-dropdown .list-item label .address-line {
      font-size: 0.875rem;
      font-weight: 300; }
  .custom-dropdown .selected-option {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background: transparent;
    background-size: cover;
    position: relative;
    width: 100%;
    text-align: left; }
    .custom-dropdown .selected-option.active {
      border-bottom: none; }
    .custom-dropdown .selected-option::after {
      content: "";
      background-image: url("../../images/down-icon.svg");
      width: 18px;
      height: 16px;
      display: inline-block;
      position: absolute;
      background-size: cover;
      right: 3%;
      top: 50%;
      transform: translateY(-50%); }
    .custom-dropdown .selected-option.active::after {
      background-image: url("../../images/up-icon.svg"); }

.card-selected-option.active + .creditcard-select {
  display: block; }

.stored-payments {
  display: none; }

.selected-card-display.active + .stored-payments {
  display: block; }

select.custom-select {
  background: none;
  position: relative;
  background-size: 1rem;
  padding-left: 0.25rem;
  color: #000;
  font-weight: 300;
  background-position: right 75%; }
  .disabled-dropdown-selection select.custom-select {
    color: #767676 !important;
    pointer-events: none;
    background: none; }
  select.custom-select:focus {
    border-bottom: 1px solid #151415; }

.disabled-dropdown-selection select.custom-select + label {
  color: #767676 !important;
  pointer-events: none;
  background: none; }

.form-group-select {
  position: relative; }
  .form-group-select.active::after {
    background-image: url("../../images/up-icon.svg"); }
  .form-group-select::after {
    content: '';
    pointer-events: none;
    position: absolute;
    background-image: url("../../images/down-icon.svg");
    background-size: cover;
    top: 1.5rem;
    right: 0.25rem;
    height: 1rem;
    width: 1.125rem;
    transform-origin: center center;
    transition: 0.3s; }

.linkAsset {
  white-space: nowrap;
  padding: 1rem;
  overflow-y: auto; }
  @media (min-width: 544px) {
    .linkAsset {
      display: none; } }
  .linkAsset_item {
    display: inline-block;
    padding-right: 2.5rem; }

.custom-product-card {
  font-size: 12px; }
  .custom-product-card .inner {
    border: 1px solid #ccc;
    padding: 14px; }
    @media (min-width: 992px) {
      .custom-product-card .inner {
        padding: 0;
        border: none; } }
  .custom-product-card .product-line-item {
    padding: 0 12px 0 0; }
  .custom-product-card .item-name {
    font-size: 14px;
    text-decoration: underline; }
    @media (min-width: 992px) {
      .custom-product-card .item-name {
        font-size: 16px;
        text-decoration: none; } }
  @media (min-width: 992px) {
    .custom-product-card .info-area {
      margin-bottom: 0; } }
  .custom-product-card .item-prices {
    font-size: 14px;
    font-weight: 500;
    order: 2;
    padding: 0 16px 0 0;
    color: #000; }
    .custom-product-card .item-prices .strike-through {
      text-decoration: line-through; }
    @media (min-width: 992px) {
      .custom-product-card .item-prices {
        order: 0;
        font-size: 16px; } }
  .custom-product-card .gender {
    order: 1; }
    @media (min-width: 992px) {
      .custom-product-card .gender {
        order: 0; } }
  .custom-product-card .method {
    font-weight: 500; }
  .custom-product-card .shipping-method-name {
    font-size: 14px; }
  .custom-product-card .line-item-attribute,
  .custom-product-card .line-item-option {
    font-size: 12px; }
  .custom-product-card .line-item-attribute {
    margin-bottom: 8px; }
    .custom-product-card .line-item-attribute .display-name {
      padding-right: 10px; }
  .custom-product-card .qty-label,
  .custom-product-card .display-name {
    display: inline-block;
    min-width: 1.25rem; }
  .shipping-section .custom-product-card {
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      .shipping-section .custom-product-card {
        margin-bottom: 40px; } }
    .shipping-section .custom-product-card:last-of-type {
      margin-bottom: 0; }
  .multi-order-product-summary .custom-product-card {
    margin-bottom: 16px; }
    .multi-order-product-summary .custom-product-card .inner {
      flex-direction: column !important;
      padding: 0;
      border: none; }
    .multi-order-product-summary .custom-product-card .store-name {
      font-size: 14px; }
    .multi-order-product-summary .custom-product-card .item-prices {
      padding: 0; }
    .multi-order-product-summary .custom-product-card .info-area {
      flex: 0 0 100%;
      max-width: none;
      margin-bottom: 8px; }
    .multi-order-product-summary .custom-product-card .product-info {
      padding-right: 0; }
    .multi-order-product-summary .custom-product-card .shipping-method-card {
      margin: 8px 0 0;
      padding: 0; }
      .multi-order-product-summary .custom-product-card .shipping-method-card.pre-order, .multi-order-product-summary .custom-product-card .shipping-method-card.back-order {
        background-color: transparent;
        color: #000; }
      .multi-order-product-summary .custom-product-card .shipping-method-card .method .shipping-method-name {
        display: flex;
        flex-wrap: wrap;
        font-weight: 300; }
        .multi-order-product-summary .custom-product-card .shipping-method-card .method .shipping-method-name .preorder-label {
          flex: 0 auto;
          font-weight: 700; }
        .multi-order-product-summary .custom-product-card .shipping-method-card .method .shipping-method-name .preorder-instockdate {
          flex: 1 auto; }

.bopis-product-info-border.product-info {
  border: 1px solid #ccc;
  padding: 10px; }
  @media (min-width: 992px) {
    .bopis-product-info-border.product-info {
      padding: 0;
      border: none; } }
  .bopis-product-info-border.product-info .line-item-store {
    padding: 15px; }
    @media (min-width: 992px) {
      .bopis-product-info-border.product-info .line-item-store {
        padding: 0 8px; } }

.line-item-name {
  overflow: hidden; }

.dynamic-shipping-header {
  background-color: #f6f6f6;
  padding: 16px;
  margin-bottom: 16px; }
  .dynamic-shipping-header .shipping-header-trigger {
    cursor: pointer; }
    .dynamic-shipping-header .shipping-header-trigger[aria-expanded="true"]::before {
      content: attr(data-show-less); }
    .dynamic-shipping-header .shipping-header-trigger[aria-expanded="false"]::before {
      content: attr(data-show-more); }
  .multi-order-product-summary .dynamic-shipping-header {
    padding: 16px 0;
    margin-bottom: 0; }

#inStoreInventoryModal .modal-header,
#storeFinderModal .modal-header,
#storeFinderModalMyAccount .modal-header,
.shipping-section .modal-header {
  padding: 0;
  border: none; }
  #inStoreInventoryModal .modal-header .close-store-modal,
  #storeFinderModal .modal-header .close-store-modal,
  #storeFinderModalMyAccount .modal-header .close-store-modal,
  .shipping-section .modal-header .close-store-modal {
    top: 1.2rem;
    z-index: 9;
    right: 1.4rem;
    background: #f2f2f3;
    border: 0;
    padding: 5px 8px 7px !important;
    margin-right: 0.1rem;
    border-radius: 3px; }

@media (min-width: 992px) {
  #inStoreInventoryModal .modal-dialog,
  #storeFinderModal .modal-dialog,
  #storeFinderModalMyAccount .modal-dialog,
  .shipping-section .modal-dialog {
    max-width: 400px; } }

#inStoreInventoryModal .store-locator-container .card,
#inStoreInventoryModal .store-locator-container .card-header,
#storeFinderModal .store-locator-container .card,
#storeFinderModal .store-locator-container .card-header,
#storeFinderModalMyAccount .store-locator-container .card,
#storeFinderModalMyAccount .store-locator-container .card-header,
.shipping-section .store-locator-container .card,
.shipping-section .store-locator-container .card-header {
  border: none;
  background-color: #fff;
  top: 11px; }

#inStoreInventoryModal .store-locator-container .form-check.custom-radio .form-check-label::after,
#storeFinderModal .store-locator-container .form-check.custom-radio .form-check-label::after,
#storeFinderModalMyAccount .store-locator-container .form-check.custom-radio .form-check-label::after,
.shipping-section .store-locator-container .form-check.custom-radio .form-check-label::after {
  top: 3px;
  left: -10px; }

#inStoreInventoryModal .zip-field .form-group,
#storeFinderModal .zip-field .form-group,
#storeFinderModalMyAccount .zip-field .form-group,
.shipping-section .zip-field .form-group {
  margin-bottom: 0; }

#inStoreInventoryModal .zip-field input,
#storeFinderModal .zip-field input,
#storeFinderModalMyAccount .zip-field input,
.shipping-section .zip-field input {
  font-size: 1rem;
  padding-right: 2.375rem;
  border-radius: 4px; }

#inStoreInventoryModal .zip-field input:not(.is-invalid),
#storeFinderModal .zip-field input:not(.is-invalid),
#storeFinderModalMyAccount .zip-field input:not(.is-invalid),
.shipping-section .zip-field input:not(.is-invalid) {
  border: 1px solid #abb0b4; }

#inStoreInventoryModal .zip-field .btn-storelocator-search,
#storeFinderModal .zip-field .btn-storelocator-search,
#storeFinderModalMyAccount .zip-field .btn-storelocator-search,
.shipping-section .zip-field .btn-storelocator-search {
  right: 0; }

#inStoreInventoryModal select.radius,
#storeFinderModal select.radius,
#storeFinderModalMyAccount select.radius,
.shipping-section select.radius {
  font-size: 16px; }

#inStoreInventoryModal .store-results-wrapper,
#storeFinderModal .store-results-wrapper,
#storeFinderModalMyAccount .store-results-wrapper,
.shipping-section .store-results-wrapper {
  padding: 10px; }

#inStoreInventoryModal .radius-selector,
#storeFinderModal .radius-selector,
#storeFinderModalMyAccount .radius-selector,
.shipping-section .radius-selector {
  position: relative; }
  #inStoreInventoryModal .radius-selector .down-icon,
  #storeFinderModal .radius-selector .down-icon,
  #storeFinderModalMyAccount .radius-selector .down-icon,
  .shipping-section .radius-selector .down-icon {
    position: absolute;
    right: 20px;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%); }

#inStoreInventoryModal .bopis-header,
#storeFinderModal .bopis-header,
#storeFinderModalMyAccount .bopis-header,
.shipping-section .bopis-header {
  text-align: center;
  display: flex;
  position: relative;
  top: -21px;
  left: 7px; }

#inStoreInventoryModal #store-postal-code,
#storeFinderModal #store-postal-code,
#storeFinderModalMyAccount #store-postal-code,
.shipping-section #store-postal-code {
  font-size: 1rem;
  font-weight: 500;
  line-height: 18px;
  color: #151415; }

#inStoreInventoryModal #store-postal-code::placeholder,
#storeFinderModal #store-postal-code::placeholder,
#storeFinderModalMyAccount #store-postal-code::placeholder,
.shipping-section #store-postal-code::placeholder {
  color: #ccc; }

#inStoreInventoryModal .custom-select:focus,
#storeFinderModal .custom-select:focus,
#storeFinderModalMyAccount .custom-select:focus,
.shipping-section .custom-select:focus {
  border-color: #767676; }

#inStoreInventoryModal .results,
#storeFinderModal .results,
#storeFinderModalMyAccount .results,
.shipping-section .results {
  top: -16px;
  display: grid;
  position: relative;
  gap: 16px; }

#inStoreInventoryModal .custom,
#storeFinderModal .custom,
#storeFinderModalMyAccount .custom,
.shipping-section .custom {
  flex-direction: column;
  justify-content: center; }

#inStoreInventoryModal .custom-hr,
#storeFinderModal .custom-hr,
#storeFinderModalMyAccount .custom-hr,
.shipping-section .custom-hr {
  border-top: 1px solid #ccc; }

#inStoreInventoryModal .result-store-count-text,
#storeFinderModal .result-store-count-text,
#storeFinderModalMyAccount .result-store-count-text,
.shipping-section .result-store-count-text {
  display: flex;
  position: relative;
  right: 9px;
  color: gray;
  font-weight: 300;
  font-size: 1rem; }

#inStoreInventoryModal .store-locator-heading,
#storeFinderModal .store-locator-heading,
#storeFinderModalMyAccount .store-locator-heading,
.shipping-section .store-locator-heading {
  margin-bottom: -14px; }

#inStoreInventoryModal .update-store-area,
#storeFinderModal .update-store-area,
#storeFinderModalMyAccount .update-store-area {
  bottom: 0;
  left: 0;
  background: #fff; }

#inStoreInventoryModal .store-result,
#storeFinderModal .store-result,
#storeFinderModalMyAccount .store-result {
  padding: 1rem;
  background: #f8f9f9;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  top: -19px;
  display: block;
  position: relative; }
  #inStoreInventoryModal .store-result .store-ats,
  #storeFinderModal .store-result .store-ats,
  #storeFinderModalMyAccount .store-result .store-ats {
    color: #12823b;
    margin-bottom: 1rem; }
    #inStoreInventoryModal .store-result .store-ats .store-ats-low,
    #storeFinderModal .store-result .store-ats .store-ats-low,
    #storeFinderModalMyAccount .store-result .store-ats .store-ats-low {
      color: #b36205; }
    #inStoreInventoryModal .store-result .store-ats .store-ats-none,
    #storeFinderModal .store-result .store-ats .store-ats-none,
    #storeFinderModalMyAccount .store-result .store-ats .store-ats-none {
      color: #92071f; }
  #inStoreInventoryModal .store-result .store-details,
  #inStoreInventoryModal .store-result .store-map,
  #storeFinderModal .store-result .store-details,
  #storeFinderModal .store-result .store-map,
  #storeFinderModalMyAccount .store-result .store-details,
  #storeFinderModalMyAccount .store-result .store-map {
    color: #5c6268; }
  #inStoreInventoryModal .store-result .custom-address,
  #storeFinderModal .store-result .custom-address,
  #storeFinderModalMyAccount .store-result .custom-address {
    line-height: 1.5rem; }
  #inStoreInventoryModal .store-result .store-name-wrapper,
  #storeFinderModal .store-result .store-name-wrapper,
  #storeFinderModalMyAccount .store-result .store-name-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    gap: 1rem; }
  #inStoreInventoryModal .store-result .store-name,
  #storeFinderModal .store-result .store-name,
  #storeFinderModalMyAccount .store-result .store-name {
    line-height: 1.2rem;
    color: #151415;
    font-size: 1rem;
    font-weight: 500 !important;
    flex-grow: 1;
    margin-right: 2rem; }
  #inStoreInventoryModal .store-result .select-store,
  #storeFinderModal .store-result .select-store,
  #storeFinderModalMyAccount .store-result .select-store {
    color: #151415; }
  #inStoreInventoryModal .store-result .store-details,
  #storeFinderModal .store-result .store-details,
  #storeFinderModalMyAccount .store-result .store-details {
    display: block;
    position: relative;
    line-height: 1.5rem; }
  #inStoreInventoryModal .store-result .storelocator-phone,
  #storeFinderModal .store-result .storelocator-phone,
  #storeFinderModalMyAccount .store-result .storelocator-phone {
    color: #000; }
  #inStoreInventoryModal .store-result .store-info,
  #storeFinderModal .store-result .store-info,
  #storeFinderModalMyAccount .store-result .store-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%; }
  #inStoreInventoryModal .store-result .store-distance,
  #storeFinderModal .store-result .store-distance,
  #storeFinderModalMyAccount .store-result .store-distance {
    white-space: nowrap;
    margin-left: 1rem;
    align-self: flex-start;
    text-align: right; }

.shipping-method-card {
  border-radius: 5px;
  background-color: #f6f6f6;
  padding: 24px;
  font-size: 0.75rem; }
  .shipping-method-card .method {
    display: flex;
    font-size: 0.875rem;
    font-weight: 700; }
  .shipping-method-card.pre-order, .shipping-method-card.back-order {
    background-color: #3e3e3e;
    color: #fff;
    font-weight: 300; }
    .shipping-method-card.pre-order .method, .shipping-method-card.back-order .method {
      font-weight: 300; }
    .shipping-method-card.pre-order .preorder-label, .shipping-method-card.back-order .preorder-label {
      font-weight: 700; }
  .shipping-method-card .delivery-estimate {
    margin-left: -4px; }

.bopis-policy a {
  color: #c00;
  font-weight: 500;
  text-decoration: underline; }

.product-details hr.no-top-bottom-margin {
  margin: 0 1.5rem; }

.product-details #shipping-method {
  cursor: pointer; }
  .product-details #shipping-method .display-name {
    font-weight: 500; }
  .product-details #shipping-method.selected {
    background: #ebeced;
    border-color: #151415 !important; }

.product-details #bopis-method {
  cursor: pointer; }
  .product-details #bopis-method.disable .custom-radio .form-check-label::before {
    background-color: transparent; }
  .product-details #bopis-method.disable .display-name {
    color: #767676; }
  .product-details #bopis-method.disable .card-body {
    color: #767676; }
    .product-details #bopis-method.disable .card-body .store-map {
      color: #767676; }
    .product-details #bopis-method.disable .card-body .store-ats {
      display: none; }
  .product-details #bopis-method.selected {
    background: #ebeced;
    border-color: #151415 !important; }

@media (min-width: 992px) {
  #storeFinderModal.modal:not(.nb-modal) .modal-dialog,
  #storeFinderModalMyAccount.modal:not(.nb-modal) .modal-dialog {
    display: flex;
    justify-content: center;
    position: relative;
    margin: 0;
    width: 600px;
    max-width: 600px;
    height: auto;
    max-height: 95vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  #storeFinderModal.modal:not(.nb-modal) .modal-content,
  #storeFinderModalMyAccount.modal:not(.nb-modal) .modal-content {
    border-radius: 6px;
    background-color: #fff;
    height: auto; }
  #storeFinderModal.modal:not(.nb-modal) .close-store-modal,
  #storeFinderModalMyAccount.modal:not(.nb-modal) .close-store-modal {
    right: 1.4rem; }
  #storeFinderModal.modal:not(.nb-modal) #store-postal-code,
  #storeFinderModalMyAccount.modal:not(.nb-modal) #store-postal-code {
    width: 100%;
    height: 58px;
    position: relative; }
  #storeFinderModal.modal:not(.nb-modal) .btn-storelocator-search,
  #storeFinderModalMyAccount.modal:not(.nb-modal) .btn-storelocator-search {
    display: flex; }
  #storeFinderModal.modal:not(.nb-modal) .js-search-store-coordinates,
  #storeFinderModalMyAccount.modal:not(.nb-modal) .js-search-store-coordinates {
    position: relative;
    width: 100%;
    height: 58px;
    align-items: center;
    justify-content: center;
    flex: 1; }
  #storeFinderModal.modal:not(.nb-modal) .custom,
  #storeFinderModalMyAccount.modal:not(.nb-modal) .custom {
    position: relative; }
  #storeFinderModal.modal:not(.nb-modal) .custom-vr-top,
  #storeFinderModalMyAccount.modal:not(.nb-modal) .custom-vr-top {
    height: 15px;
    border: 1px solid #ccc;
    position: relative;
    top: -4px; }
  #storeFinderModal.modal:not(.nb-modal) .custom-vr-bottom,
  #storeFinderModalMyAccount.modal:not(.nb-modal) .custom-vr-bottom {
    height: 15px;
    border: 1px solid #ccc;
    position: relative;
    top: 3px; } }

#storeFinderModal.modal:not(.nb-modal) .store-locator .d-flex,
#storeFinderModalMyAccount.modal:not(.nb-modal) .store-locator .d-flex {
  display: flex; }

#storeFinderModal.modal:not(.nb-modal) .store-locator .align-items-center,
#storeFinderModalMyAccount.modal:not(.nb-modal) .store-locator .align-items-center {
  align-items: center;
  flex: 0; }

#storeFinderModal.modal:not(.nb-modal) .store-locator .zip-field,
#storeFinderModalMyAccount.modal:not(.nb-modal) .store-locator .zip-field {
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem; }
  @media (max-width: 991.98px) {
    #storeFinderModal.modal:not(.nb-modal) .store-locator .zip-field,
    #storeFinderModalMyAccount.modal:not(.nb-modal) .store-locator .zip-field {
      flex-direction: column;
      align-items: stretch;
      width: 100%; }
      #storeFinderModal.modal:not(.nb-modal) .store-locator .zip-field .btn-storelocator-search,
      #storeFinderModalMyAccount.modal:not(.nb-modal) .store-locator .zip-field .btn-storelocator-search {
        margin-bottom: 1rem; }
      #storeFinderModal.modal:not(.nb-modal) .store-locator .zip-field .form-group,
      #storeFinderModalMyAccount.modal:not(.nb-modal) .store-locator .zip-field .form-group {
        width: 100%;
        position: relative; }
        #storeFinderModal.modal:not(.nb-modal) .store-locator .zip-field .form-group .btn-storelocator-search,
        #storeFinderModalMyAccount.modal:not(.nb-modal) .store-locator .zip-field .form-group .btn-storelocator-search {
          color: #ebeced; } }

#storeFinderModal.modal:not(.nb-modal) .store-locator .form-group,
#storeFinderModalMyAccount.modal:not(.nb-modal) .store-locator .form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  flex: 1; }
  #storeFinderModal.modal:not(.nb-modal) .store-locator .form-group .btn-storelocator-search,
  #storeFinderModalMyAccount.modal:not(.nb-modal) .store-locator .form-group .btn-storelocator-search {
    display: flex;
    top: 35%;
    right: 12px; }
  #storeFinderModal.modal:not(.nb-modal) .store-locator .form-group .nb-button.location-button,
  #storeFinderModalMyAccount.modal:not(.nb-modal) .store-locator .form-group .nb-button.location-button {
    width: 256px !important;
    min-width: unset !important; }

#storeFinderModal.modal:not(.nb-modal) .store-locator .font-body-small,
#storeFinderModalMyAccount.modal:not(.nb-modal) .store-locator .font-body-small {
  font-size: 0.9rem;
  color: #5c6268;
  left: 0;
  position: relative;
  top: -2px; }

@media (max-width: 991.98px) {
  #storeFinderModal.modal#storeFinderModal, #storeFinderModal.modal#storeFinderModalMyAccount,
  #storeFinderModalMyAccount.modal#storeFinderModal,
  #storeFinderModalMyAccount.modal#storeFinderModalMyAccount {
    overflow-y: hidden;
    transition: all 0.3s ease-in-out; }
    #storeFinderModal.modal#storeFinderModal.show .modal-dialog, #storeFinderModal.modal#storeFinderModalMyAccount.show .modal-dialog,
    #storeFinderModalMyAccount.modal#storeFinderModal.show .modal-dialog,
    #storeFinderModalMyAccount.modal#storeFinderModalMyAccount.show .modal-dialog {
      right: 0;
      bottom: 0; }
      #storeFinderModal.modal#storeFinderModal.show .modal-dialog.stores-searched, #storeFinderModal.modal#storeFinderModalMyAccount.show .modal-dialog.stores-searched,
      #storeFinderModalMyAccount.modal#storeFinderModal.show .modal-dialog.stores-searched,
      #storeFinderModalMyAccount.modal#storeFinderModalMyAccount.show .modal-dialog.stores-searched {
        transform: translateY(10%); } }
    @media (max-width: 991.98px) and (orientation: landscape) {
      #storeFinderModal.modal#storeFinderModal.show .modal-dialog, #storeFinderModal.modal#storeFinderModalMyAccount.show .modal-dialog,
      #storeFinderModalMyAccount.modal#storeFinderModal.show .modal-dialog,
      #storeFinderModalMyAccount.modal#storeFinderModalMyAccount.show .modal-dialog {
        transform: translateY(10%); } }

@media (max-width: 991.98px) {
    #storeFinderModal.modal#storeFinderModal .modal-dialog, #storeFinderModal.modal#storeFinderModalMyAccount .modal-dialog,
    #storeFinderModalMyAccount.modal#storeFinderModal .modal-dialog,
    #storeFinderModalMyAccount.modal#storeFinderModalMyAccount .modal-dialog {
      transform: translateY(70%);
      border-radius: 1rem 1rem 0 0;
      overflow: hidden;
      right: 0;
      bottom: 0;
      height: 105vh; }
      #storeFinderModal.modal#storeFinderModal .modal-dialog .modal-content, #storeFinderModal.modal#storeFinderModalMyAccount .modal-dialog .modal-content,
      #storeFinderModalMyAccount.modal#storeFinderModal .modal-dialog .modal-content,
      #storeFinderModalMyAccount.modal#storeFinderModalMyAccount .modal-dialog .modal-content {
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 3rem; }
    #storeFinderModal.modal#storeFinderModal .card.results-card, #storeFinderModal.modal#storeFinderModalMyAccount .card.results-card,
    #storeFinderModalMyAccount.modal#storeFinderModal .card.results-card,
    #storeFinderModalMyAccount.modal#storeFinderModalMyAccount .card.results-card {
      margin-bottom: 5rem; }
    #storeFinderModal.modal#storeFinderModal #store-postal-code, #storeFinderModal.modal#storeFinderModalMyAccount #store-postal-code,
    #storeFinderModalMyAccount.modal#storeFinderModal #store-postal-code,
    #storeFinderModalMyAccount.modal#storeFinderModalMyAccount #store-postal-code {
      width: 100%;
      height: 58px; }
    #storeFinderModal.modal#storeFinderModal .js-search-store, #storeFinderModal.modal#storeFinderModalMyAccount .js-search-store,
    #storeFinderModalMyAccount.modal#storeFinderModal .js-search-store,
    #storeFinderModalMyAccount.modal#storeFinderModalMyAccount .js-search-store {
      width: 100%; }
    #storeFinderModal.modal#storeFinderModal .font-body-small, #storeFinderModal.modal#storeFinderModalMyAccount .font-body-small,
    #storeFinderModalMyAccount.modal#storeFinderModal .font-body-small,
    #storeFinderModalMyAccount.modal#storeFinderModalMyAccount .font-body-small {
      font-size: 0.875rem;
      color: #5c6268; }
    #storeFinderModal.modal#storeFinderModal .custom-hr, #storeFinderModal.modal#storeFinderModalMyAccount .custom-hr,
    #storeFinderModalMyAccount.modal#storeFinderModal .custom-hr,
    #storeFinderModalMyAccount.modal#storeFinderModalMyAccount .custom-hr {
      border-top: 1px solid #ccc;
      width: 100%;
      height: 1px; }
  #storeFinderModal.modal .font-body-small,
  #storeFinderModalMyAccount.modal .font-body-small {
    font-size: 0.9rem;
    color: #5c6268;
    left: 0;
    position: relative;
    top: -6px; } }

@media (max-width: 768.98px) {
  #storeFinderModal.modal .modal-dialog,
  #storeFinderModalMyAccount.modal .modal-dialog {
    height: 85vh; } }

.timer-countdown {
  display: flex;
  margin: 2rem 0; }
  .timer-countdown .timerContainer {
    width: auto;
    margin-right: 10px;
    text-align: center;
    font-size: 3rem;
    line-height: 0.7em; }
    .timer-countdown .timerContainer .label {
      font-weight: 400;
      font-size: 0.3em;
      line-height: 2.5em;
      text-transform: uppercase;
      text-align: left; }

.experience-component .timer-countdown-container .timer-description {
  display: block !important; }

.experience-component .timer-countdown-container p {
  font-weight: 700;
  font-size: 1.5rem;
  margin-top: 1rem; }

.experience-component .timer-countdown-container .hrItem {
  display: block;
  width: 75px;
  height: 1px;
  border: 0;
  border-top: 4px solid #cf0a2c;
  margin: 1em 0 2em;
  padding: 0; }

.experience-component .post-launch-banner .hrItem {
  display: none; }

.experience-component .post-launch-banner .cta-pre-launch {
  display: none; }

.experience-component .post-launch-banner .cta-post-launch {
  display: inline-block !important; }

.product-detail .timer-countdown-container {
  padding-top: 20px; }
  .product-detail .timer-countdown-container .timer-countdown {
    margin-top: 0; }
    @media only screen and (min-width: 992px) and (max-width: 1300px) {
      .product-detail .timer-countdown-container .timer-countdown .timerContainer {
        font-size: 2.1875rem; }
        .product-detail .timer-countdown-container .timer-countdown .timerContainer .label {
          font-size: 0.4em; } }
  @media (min-width: 992px) {
    .product-detail .timer-countdown-container {
      padding-top: 10px; } }
  @media (max-width: 991.98px) {
    .product-detail .timer-countdown-container p {
      margin-bottom: 0.625rem; }
    .product-detail .timer-countdown-container .timer-countdown {
      margin-bottom: 0; } }

#productDetails .timer-countdown-container .timer-description .hrItem {
  display: none; }

/* overriding the bootstrap header sizes */
.content-text-top {
  top: 50%;
  transform: translateY(-50%) translateX(50%);
  margin: 0;
  position: absolute;
  right: 50%; }

.content-text-hero {
  position: relative; }
  @media (min-width: 992px) {
    .content-text-hero {
      top: 50%;
      transform: translateY(-50%) translateX(50%);
      margin: 0;
      position: absolute;
      right: 50%; } }

.light-color-mode {
  color: #fff !important; }

.z-index-banner {
  z-index: -1; }

@media (min-width: 992px) {
  .light-color-mode-hero,
  .light-color-mode-hero a {
    color: #fff !important; } }

.disclaimer-hero {
  position: relative; }
  @media (min-width: 992px) {
    .disclaimer-hero {
      position: absolute;
      bottom: 0; } }

.word-break {
  word-wrap: break-word; }

.overlay-image {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center; }

@media (min-width: 992px) {
  .disclaimer-position {
    transform: translateY(0%) translateX(0%);
    position: relative;
    z-index: 2; } }

@media (min-width: 992px) {
  .header-container--overlapped-js ~ div#maincontent .experience-commerce_assets-HeroComponent:first-child {
    margin-top: -120px; } }

.markup-container p {
  margin-bottom: 0; }

/* overriding the bootstrap header sizes */
#header-rotating-banner {
  width: 100%;
  margin: 0 auto;
  color: #fff; }
  @media (min-width: 992px) {
    #header-rotating-banner {
      width: 100%; } }
  #header-rotating-banner .promo-view {
    display: inline;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 0; }
    @media (min-width: 992px) {
      #header-rotating-banner .promo-view {
        font-size: 0.875rem; } }
  #header-rotating-banner .hidden-slider {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    margin: -10000px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    visibility: hidden; }
  #header-rotating-banner .slick-autoplay-toggle-button {
    display: none; }
  #header-rotating-banner .experience-component.experience-commerce_assets-RotatingPromoBar[data-sfcc-pd-component-info] {
    clip: initial;
    clip-path: initial;
    height: initial;
    width: initial;
    margin: initial;
    overflow: initial;
    padding: initial;
    position: initial;
    visibility: visible; }
  #header-rotating-banner .slick-slider {
    margin: 0 auto 10px;
    max-width: 450px;
    min-width: 320px;
    top: 7px; }
    #header-rotating-banner .slick-slider.slick-dotted {
      top: 6px; }
  #header-rotating-banner .slick-arrow {
    top: calc(50% + 5px);
    z-index: 2;
    background: #efefef;
    height: 48px; }
    #header-rotating-banner .slick-arrow .slick-prev-icon::before,
    #header-rotating-banner .slick-arrow .slick-next-icon::before {
      content: '';
      background-image: url(../images/left-icon-white.svg);
      height: 1rem;
      width: 1rem;
      position: absolute;
      transform: translateY(-50%) translateX(-50%);
      background-size: contain; }
    #header-rotating-banner .slick-arrow .slick-next-icon::before {
      content: '';
      background-image: url(../images/right-icon-white.svg); }
    #header-rotating-banner .slick-arrow.slick-next {
      right: 0; }
      @media (min-width: 992px) {
        #header-rotating-banner .slick-arrow.slick-next {
          right: -20px; } }
    #header-rotating-banner .slick-arrow.slick-prev {
      left: 0; }
      @media (min-width: 992px) {
        #header-rotating-banner .slick-arrow.slick-prev {
          left: -20px; } }
  #header-rotating-banner .slick-dots {
    left: 0;
    bottom: -24px; }
    #header-rotating-banner .slick-dots li,
    #header-rotating-banner .slick-dots li button {
      margin: 0;
      vertical-align: top;
      width: 12px;
      height: 25px;
      cursor: pointer; }
      #header-rotating-banner .slick-dots li .slick-dot-icon,
      #header-rotating-banner .slick-dots li button .slick-dot-icon {
        color: #fff; }
    #header-rotating-banner .slick-dots li button::before {
      content: ""; }
    #header-rotating-banner .slick-dots li button .slick-dot-icon::before {
      width: initial;
      height: initial;
      font-size: 20px; }
    #header-rotating-banner .slick-dots li button:hover .slick-dot-icon {
      opacity: 1; }
    #header-rotating-banner .slick-dots li button .slick-dot-icon {
      opacity: 0.5; }
    #header-rotating-banner .slick-dots li.slick-active button .slick-dot-icon {
      color: #fff;
      opacity: 1; }
  #header-rotating-banner.expanded {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 52;
    height: 36px; }
    #header-rotating-banner.expanded .promo-bar-details {
      display: block; }
  #header-rotating-banner .promo-details-container {
    width: 100% !important;
    margin: 0 auto;
    padding: 20px 0 0;
    position: fixed;
    background: #151415;
    max-height: calc(100% - 30px);
    overflow-y: scroll;
    overflow-x: hidden;
    left: 0;
    top: 48px;
    z-index: 1;
    scrollbar-width: none;
    /* so we don't see the scrollbar */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */ }
    #header-rotating-banner .promo-details-container::-webkit-scrollbar {
      display: none;
      /* Safari and Chrome */ }
    @media (min-width: 992px) {
      #header-rotating-banner .promo-details-container {
        top: 0; } }
    #header-rotating-banner .promo-details-container .row,
    #header-rotating-banner .promo-details-container .details-button-wrapper {
      width: calc(100vw - 50px);
      max-width: 400px;
      min-width: 320px;
      margin: 0 auto; }
    #header-rotating-banner .promo-details-container .slick-arrow {
      top: 14px;
      background: #151415; }
    #header-rotating-banner .promo-details-container .slick-next {
      right: -6vw; }
    #header-rotating-banner .promo-details-container .slick-prev {
      left: -6vw; }
    #header-rotating-banner .promo-details-container .slick-prev-icon::before,
    #header-rotating-banner .promo-details-container .slick-next-icon::before {
      content: '';
      background-image: url(../images/left-icon-white.svg);
      height: 1rem;
      width: 1rem;
      position: absolute;
      transform: translateY(-50%) translateX(-50%);
      background-size: contain; }
    #header-rotating-banner .promo-details-container .slick-next-icon::before {
      content: '';
      background-image: url(../images/right-icon-white.svg); }
    #header-rotating-banner .promo-details-container .nb-button {
      padding: 0 10px;
      height: 48px;
      line-height: 46px;
      overflow: hidden;
      white-space: nowrap;
      border-radius: 0.4rem;
      border: 1px solid #bec0c3;
      background-color: #fff; }
    #header-rotating-banner .promo-details-container .banner-close {
      display: none; }
    @media (min-width: 992px) {
      #header-rotating-banner .promo-details-container .row {
        max-width: 100rem;
        position: relative;
        display: flex;
        flex-flow: row wrap;
        align-content: flex-start;
        justify-content: space-between;
        align-items: stretch;
        padding: 20px 0;
        gap: 20px; }
        #header-rotating-banner .promo-details-container .row .promo-bar-details {
          border-right: 1px solid #fff;
          padding-right: 20px;
          box-sizing: border-box;
          flex: 1 1 22%; }
          #header-rotating-banner .promo-details-container .row .promo-bar-details .banner-close {
            display: block;
            right: 1vw; }
          #header-rotating-banner .promo-details-container .row .promo-bar-details:nth-of-type(4), #header-rotating-banner .promo-details-container .row .promo-bar-details:last-of-type, #header-rotating-banner .promo-details-container .row .promo-bar-details:only-of-type {
            border-right: none; }
          #header-rotating-banner .promo-details-container .row .promo-bar-details .details-button-wrapper,
          #header-rotating-banner .promo-details-container .row .promo-bar-details .row {
            min-width: 1px;
            width: initial;
            padding: 0; }
          #header-rotating-banner .promo-details-container .row .promo-bar-details .details-button-wrapper {
            text-align: center;
            margin: 20px 15px; } }
      @media (min-width: 992px) and (min-width: 992px) {
        #header-rotating-banner .promo-details-container .row .promo-bar-details .details-button-wrapper {
          text-align: left;
          margin: 20px 0; } }
    @media (min-width: 1200px) {
      #header-rotating-banner .promo-details-container .row {
        gap: 40px;
        padding: 40px; }
        #header-rotating-banner .promo-details-container .row .promo-bar-details {
          padding-right: 40px; } }
  #header-rotating-banner .promo-details-close {
    display: block;
    margin: 20px auto 0;
    text-align: center;
    padding: 0 0 20px;
    color: #fff; }
    @media (min-width: 992px) {
      #header-rotating-banner .promo-details-close {
        display: none; } }
  #header-rotating-banner .details-body {
    text-align: left;
    color: #000; }
    #header-rotating-banner .details-body .details-body-headline {
      display: none; }
      @media (min-width: 992px) {
        #header-rotating-banner .details-body .details-body-headline {
          display: block;
          font-weight: 700; } }
    #header-rotating-banner .details-body ul {
      list-style: disc inside; }
    #header-rotating-banner .details-body p {
      margin-bottom: 0.5rem; }
    #header-rotating-banner .details-body span,
    #header-rotating-banner .details-body p,
    #header-rotating-banner .details-body a,
    #header-rotating-banner .details-body li,
    #header-rotating-banner .details-body h1,
    #header-rotating-banner .details-body h2,
    #header-rotating-banner .details-body h3,
    #header-rotating-banner .details-body h4,
    #header-rotating-banner .details-body h5,
    #header-rotating-banner .details-body h6,
    #header-rotating-banner .details-body .h1,
    #header-rotating-banner .details-body .h2,
    #header-rotating-banner .details-body .h3,
    #header-rotating-banner .details-body .h4,
    #header-rotating-banner .details-body .h5,
    #header-rotating-banner .details-body .h6 {
      color: #fff !important; }

#Promo_banner_details_overlay {
  display: none;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 51; }
  #Promo_banner_details_overlay.details-open {
    display: block; }

@media (max-width: 991.98px) {
  .mobile-homepage-quicklinks {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; } }

.mobile-homepage-quicklinks h3 {
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.375rem;
  margin-bottom: 1rem; }
  @media (max-width: 991.98px) {
    .mobile-homepage-quicklinks h3 {
      font-size: 0.875rem;
      line-height: 1.125rem;
      margin-bottom: 0.75rem; } }

.mobile-homepage-quicklinks .mobile-homepage-quicklinks-ctn {
  column-gap: 0.5rem; }
  .mobile-homepage-quicklinks .mobile-homepage-quicklinks-ctn a.mobile-homepage-button {
    margin-bottom: 0.5rem;
    border-radius: 4px;
    background-color: #f2f2f3;
    border: 0.125rem solid #f2f2f3;
    font-size: 1rem;
    font-weight: 300;
    padding: 0.25rem 1rem;
    line-height: 1.5rem; }
    @media (max-width: 991.98px) {
      .mobile-homepage-quicklinks .mobile-homepage-quicklinks-ctn a.mobile-homepage-button {
        font-size: 0.875rem;
        line-height: 1.25rem; } }

.single-shipping .access-points-search-container {
  width: 840px;
  height: auto; }

.single-shipping .access-points-locations {
  width: 100%;
  padding-right: 5px;
  display: inline-block;
  height: 500px;
  overflow: auto;
  overflow-x: hidden; }

.single-shipping .access-points-map {
  display: inline-block;
  border: 2px solid #999;
  width: 100%;
  height: 456px; }

.single-shipping .ap-tile-body .name {
  font-weight: 500; }

.single-shipping .ap-tile-body .name,
.single-shipping .ap-tile-body .address,
.single-shipping .ap-tile-body .phone,
.single-shipping .ap-tile-body .distance {
  margin-bottom: 5px; }

.single-shipping .ap-tile-select {
  margin-top: 2px; }

.single-shipping .display-name {
  font-size: 0.875rem;
  line-height: 1.25rem; }

.addressAutocompleteContainer {
  position: absolute;
  min-width: 250px;
  min-height: 60px;
  top: 52px;
  left: 7px;
  background: #fff;
  border: solid 1px #e5e5e5;
  border-top: none;
  padding-bottom: 18px;
  z-index: 999;
  display: none; }
  .addressAutocompleteContainer .addressSuggestion {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border-bottom: solid 1px #e5e5e5;
    font-size: 12px;
    padding: 10px 5px;
    cursor: pointer; }
    .addressAutocompleteContainer .addressSuggestion:hover, .addressAutocompleteContainer .addressSuggestion.hascursor {
      background: #323232;
      color: #fff; }
    .addressAutocompleteContainer .addressSuggestion:active, .addressAutocompleteContainer .addressSuggestion.hascursor.selecting {
      background: #e21836; }
    .addressAutocompleteContainer .addressSuggestion .match {
      font-weight: 500; }
    .addressAutocompleteContainer .addressSuggestion.current-location {
      position: relative;
      padding-left: 24px; }
      .addressAutocompleteContainer .addressSuggestion.current-location.after {
        content: "\e60d";
        position: absolute;
        left: 8px;
        top: 7px;
        font-size: 130%; }
  .addressAutocompleteContainer .googleCredit {
    width: 100%;
    height: 16px;
    position: absolute;
    bottom: 0;
    background: #fff url(../images/powered-by-google-on-white.png) no-repeat center right; }

.boss-store,
.cvs-store {
  display: flex;
  border: 2px solid #ccc;
  padding: 15px;
  cursor: pointer; }
  @media (min-width: 544px) {
    .boss-store,
    .cvs-store {
      width: calc((100% - 15px) / 3);
      flex-basis: calc((100% - 15px) / 3);
      min-height: 200px;
      margin-right: 5px; } }
  .boss-store .boss-tile-select,
  .boss-store .cvs-tile-select,
  .cvs-store .boss-tile-select,
  .cvs-store .cvs-tile-select {
    margin-top: 6px; }
  .boss-store .font-body-large,
  .cvs-store .font-body-large {
    font-size: 14px;
    margin: 0; }
    .boss-store .font-body-large.boss-store-phone, .boss-store .font-body-large.cvs-store-phone,
    .cvs-store .font-body-large.boss-store-phone,
    .cvs-store .font-body-large.cvs-store-phone {
      margin-top: 1rem; }
  .boss-store .custom-radio .form-check-label::before,
  .cvs-store .custom-radio .form-check-label::before {
    background-color: transparent; }

html[data-site="NBJP"] body,
html[data-site="NBJP"] body *,
html[data-site="JPOUTLET"] body,
html[data-site="JPOUTLET"] body * {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Helvetica Neue", "メイリオ", "Meiryo", "Helvetica", Helvetica, Arial, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif !important; }

html[data-site="NBJP"] body .experience-commerce_assets-HeroComponent .font-hero,
html[data-site="NBJP"] body .experience-commerce_assets-HeroComponent .font-hero font,
html[data-site="NBJP"] body .experience-commerce_assets-HeroComponent .didot,
html[data-site="NBJP"] body .experience-commerce_assets-HeroComponent .didot font,
html[data-site="NBJP"] body .experience-commerce_assets-textImageCombinedComponent .font-hero,
html[data-site="NBJP"] body .experience-commerce_assets-textImageCombinedComponent .font-hero font,
html[data-site="NBJP"] body .experience-commerce_assets-textImageCombinedComponent .didot,
html[data-site="NBJP"] body .experience-commerce_assets-textImageCombinedComponent .didot font,
html[data-site="JPOUTLET"] body .experience-commerce_assets-HeroComponent .font-hero,
html[data-site="JPOUTLET"] body .experience-commerce_assets-HeroComponent .font-hero font,
html[data-site="JPOUTLET"] body .experience-commerce_assets-HeroComponent .didot,
html[data-site="JPOUTLET"] body .experience-commerce_assets-HeroComponent .didot font,
html[data-site="JPOUTLET"] body .experience-commerce_assets-textImageCombinedComponent .font-hero,
html[data-site="JPOUTLET"] body .experience-commerce_assets-textImageCombinedComponent .font-hero font,
html[data-site="JPOUTLET"] body .experience-commerce_assets-textImageCombinedComponent .didot,
html[data-site="JPOUTLET"] body .experience-commerce_assets-textImageCombinedComponent .didot font {
  font-family: "ITC Garamond W01" !important; }
