#hamburger-icon-custom {
    width: rem(16);
    height: rem(16);
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    cursor: pointer;
}

#hamburger-icon-custom span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #000;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

/* Icon 3 */

#hamburger-icon-custom span:nth-child(1) {
    top: 2px;
}

#hamburger-icon-custom span:nth-child(2),
#hamburger-icon-custom span:nth-child(3) {
    top: 8px;
}

#hamburger-icon-custom span:nth-child(4) {
    top: 14px;
}

#hamburger-icon-custom.hamburger-icon--open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
}

#hamburger-icon-custom.hamburger-icon--open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#hamburger-icon-custom.hamburger-icon--open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#hamburger-icon-custom.hamburger-icon--open span:nth-child(4) {
    top: 8px;
    width: 0%;
    left: 50%;
}
