// Button

@import '../mixins/button';
@import '../variables';
@import 'bootstrap/scss/mixins/breakpoints';

a.nb-button {
    &:hover {
        text-decoration: none;
    }
}

.nb-button {
    display: inline-block;
    min-width: rem(104);
    line-height: rem(24);
    text-align: center;
    font-size: rem(16);
    font-weight: $fontweight-bold;
    padding: rem(12) rem(24);
    border-radius: rem(4);
    border: rem(2) solid transparent;
    box-sizing: border-box;
    text-decoration: none !important; // This is needed as sometimes an achor tag is passed as the text
    transition: all 0.2s;
    user-select: none;
    vertical-align: middle;

    &.button-short {
        padding: 0.2rem 1rem !important;
        height: rem(40);
    }

    &.button-full-width {
        width: 100%;
    }

    &.button-medium {
        @include media-breakpoint-up(md) {
            min-width: rem(168);
        }
    }

    &.button-large {
        @include media-breakpoint-up(md) {
            min-width: rem(224);
        }
    }

    &.button-xlarge {
        @include media-breakpoint-up(md) {
            min-width: rem(344);
        }
    }

    &.button-full-width-mobile {
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    //Mobile button
    &.button-mxlarge {
        @include media-breakpoint-down(md) {
            min-width: rem(344);
        }
    }

    &.button-mlarge {
        @include media-breakpoint-down(md) {
            min-width: rem(224);
        }
    }

    &.button-mmedium {
        @include media-breakpoint-down(md) {
            min-width: rem(168);
        }
    }

    // custom button - primary
    &.button-primary {
        background-color: $nb-red;
        border-color: $nb-red;
        color: $white !important; // This is needed as sometimes an achor tag is passed as the text

        &:hover {
            background-color: $monza;
            border-color: $monza;
            outline: none;
        }

        &.disabled,
        &:disabled {
            pointer-events: none;
            @include button($bg: $silver-grey, $color: $boulder, $border-color: $silver-grey, $active-bg: $silver-grey, $active-border-color: $silver-grey, $hover-bg: $silver-grey, $hover-border-color: $silver-grey);

            color: $boulder !important;
        }

        &.black {
            background-color: $black;
            border-color: $black;
        }
    }

    // custom button - secondary
    &.button-secondary {
        border-color: $nb-red;
        color: $nb-red !important; // This is needed as sometimes an achor tag is passed as the text
        background-color: $white;

        &:hover,
        &:focus {
            outline: none;
            box-shadow: inset 0 0 0 0.125rem $nb-red;
        }

        &.disabled,
        &:disabled {
            pointer-events: none;
            @include button($bg: $trans, $color: $boulder, $border-color: $silver-grey);
        }
    }

    &.button-tertiary {
        border-color: $silver-light-grey;
    }
}

.nb-button-secondary {
    background-color: $white;
    color: $nb-black;
    border: 1px $silver-dark-gray solid;
    padding: 5px 16px;
    border-radius: 4px;
    font-family: $body-font-family;
    font-size: $base-font;
    line-height: 20px;
    font-weight: $btn-font-weight;

    &.icon {
        padding: 5px 7px;
    }

    &.gray-button {
        background-color: $ghost-white;
        border: none;
    }
}

.tertiary-link {
    color: $black;
    font-family: $body-font-family;
    text-decoration: underline;
    line-height: 24px;
    padding: 0;

    &-large {
        font-size: rem(16);
        font-weight: 500;
        letter-spacing: rem(0.4);
    }

    &-medium {
        font-size: rem(14);
        font-weight: 300;
        letter-spacing: rem(0.4);
    }

    &-small {
        font-size: rem(12);
        font-weight: 300;
        letter-spacing: rem(0.4);
    }
}
