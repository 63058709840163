.linkAsset {
    white-space: nowrap;
    padding: $nb-spacing-3;
    overflow-y: auto;

    @include media-breakpoint-up(sm) {
        display: none;
    }

    &_item {
        display: inline-block;
        padding-right: $nb-spacing-6;
    }
}
