//colors

$white: #fff;
$black: #000;
$trans: transparent;

$black60: #00000060;
$fog-white: #f1f1f1;
$silver-dark-gray: #abb0b4;
$nb-light-black: #0f0f10;
$nb-black: #151415;
$nb-red: #cf0a2c;
$nb-dark-red: #92071f;
$nb-grey: #737b82; // replace with rolling-stone
$nb-grey-light: #ebeced;
$nb-grey-lighter: #f8f9f9;
$rolling-stone: #737b82;
$dark-grey: #767676; // replaced with boulder
$boulder: #767676;
$silver-grey: #ccc;
$light-grey: #f6f6f6; // replaced with wild-sand
$medium-grey: #f8f8f8;
$wild-sand: #f6f6f6;
$white-smoke: #efefef; // replaced with white-smoke
$gallery-solid: #efefef;
$gallery: #f0f0f0;
$dim-gray: #727272; // replaced with dov-grey and not used in codebase
$dov-grey: #727272;
$gray-blue: #5c6268;
$Matterhorn: #4f4f4f; // replaced with emperor and not used in codebase
$emperor: #4f4f4f;
$nb-hover-red: #b90020; //replaced with monza
$monza: #b90020;
$alabaster: #fafafa;
$brick-red: #bf2d37;
$mercury: #e9e9e9;

$Blumine: #21538d; // replace wtih blumine
$blumine: #21538d;
$nb-alto-grey: #d8d8d8; // replaced with alto-approx
$alto-approx: #d8d8d8;

$silver-chalice: #aeaeae;
$nb-reef-gold: #a58520;
$orange: #b36205;
$green: #12823b;
$nb-grey-strike-through: #656c72;
$neutral-grey: #e4e4e7;
$hr-grey: #bec0c3;
