@import "~base/components/breadcrumb";

.container {
    .breadcrumb {
        padding: 1.5rem 0;
        border-radius: 0;
        border-bottom: 0;
        margin-bottom: 0;
    }
}

.breadcrumb-item {
    font-size: 0.75rem;
    line-height: 1.5rem;
    color: #5c6268;

    a {
        color: #5c6268;
    }
}

.breadcrumb-overlay {
    position: absolute;
    z-index: 100;
    left: 80px;
    top: 16px;
    padding: 0.5rem;
    border-radius: 0.25rem;
    background-color: rgba($white, 0.75);
    backdrop-filter: blur(8px);

    .breadcrumb-item {
        line-height: 0.875rem;

        a,
        a::before {
            color: $black;
        }
    }
}
