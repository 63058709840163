// Styles / overrides for the product cards that show in the shipping section of checkout

.custom-product-card {
    font-size: 12px;

    .inner {
        border: 1px solid $silver-grey;
        padding: 14px;
        @include media-breakpoint-up(lg) {
            padding: 0;
            border: none;
        }
    }

    .product-line-item {
        padding: 0 12px 0 0;
    }

    .item-name {
        font-size: 14px;
        text-decoration: underline;
        @include media-breakpoint-up(lg) {
            font-size: 16px;
            text-decoration: none;
        }
    }

    .info-area {
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }

    .item-prices {
        font-size: 14px;
        font-weight: $fontweight-medium;
        order: 2;
        padding: 0 16px 0 0;
        color: $black;

        .strike-through {
            text-decoration: line-through;
        }

        @include media-breakpoint-up(lg) {
            order: 0;
            font-size: 16px;
        }
    }

    .gender {
        order: 1;
        @include media-breakpoint-up(lg) {
            order: 0;
        }
    }

    .method {
        font-weight: $fontweight-medium;
    }

    .shipping-method-name {
        font-size: 14px;
    }

    .line-item-attribute,
    .line-item-option {
        font-size: 12px;
    }

    .line-item-attribute {
        margin-bottom: 8px;

        .display-name {
            padding-right: 10px;
        }
    }

    .qty-label,
    .display-name {
        display: inline-block;
        min-width: rem(20);
    }

    .shipping-section & {
        margin-bottom: 20px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 40px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    // Order confirmation page specific card styles
    .multi-order-product-summary & {
        margin-bottom: 16px;

        .inner {
            flex-direction: column !important; // Override bootstrap class
            padding: 0;
            border: none;
        }

        .store-name {
            font-size: 14px;
        }

        .item-prices {
            padding: 0;
        }

        .info-area {
            flex: 0 0 100%;
            max-width: none;
            margin-bottom: 8px;
        }

        .product-info {
            padding-right: 0;
        }

        .shipping-method-card {
            margin: 8px 0 0;
            padding: 0;

            &.pre-order,
            &.back-order {
                background-color: transparent;
                color: #000;
            }

            .method {
                .shipping-method-name {
                    display: flex;
                    flex-wrap: wrap;
                    font-weight: 300;

                    .preorder-label {
                        flex: 0 auto;
                        font-weight: 700;
                    }

                    .preorder-instockdate {
                        flex: 1 auto;
                    }
                }
            }
        }
    }
}

// Cart Product Cards When Bopis is Enabled
.bopis-product-info-border.product-info {
    border: 1px solid $silver-grey;
    padding: 10px;

    @include media-breakpoint-up(lg) {
        padding: 0;
        border: none;
    }

    .line-item-store {
        padding: 15px;
        @include media-breakpoint-up(lg) {
            padding: 0 8px;
        }
    }
}

.line-item-name {
    overflow: hidden;
}
