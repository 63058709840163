$breakpoint-name: 'md';
$breakpoint-name: 'md' !default;
$breakpoint-index: index(map-keys($grid-breakpoints), $breakpoint-name);
$prev-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index - 1);
$next-breakpoint: nth(map-keys($grid-breakpoints), $breakpoint-index + 1);
$nav-link-min: 1240px;
$menu-border-color: #c7cacd;
$xxl: 1560px;

$slide-out-animation: cubic-bezier(0.64, 0.04, 0.35, 1);

@mixin caret-left() {
    content: "";
    background-image: url('../../images/left-icon.svg');
    width: 16px;
    height: 16px;
    display: inline-block;
    background-size: cover;
}

@mixin caret-right() {
    content: "";
    background-image: url('../../images/right-icon.svg');
    width: 16px;
    height: 16px;
    display: inline-block;
    position: absolute;
    background-size: cover;
    right: 10px;
    top: 35%;
    border: 0;
}

@each $size in map-keys($grid-breakpoints) {

    @include media-breakpoint-down($size) {
        .menu-toggleable-left.navbar-toggleable-#{$size} {
            position: fixed;
            top: -100%;
            right: 0;
            bottom: 0;
            transition: all 0.3s $slide-out-animation;
            display: block;
            max-width: 100%;
            transform: translate(0, -100%);

            &.in {
                min-width: 100%;
                top: 0;
                right: 0;
                transform: translate(0);
                padding: 0 20px;
            }
        }
    }
}

.navbar.bg-inverse {
    background-color: transparent !important;
    padding: 0;

    @include media-breakpoint-up($next-breakpoint) {
        .navbar-nav .nav-item + .nav-item {
            margin-left: 0;
            margin-left: 2px;
        }

        .navbar-nav .nav-link {
            padding: 1.9rem 0.7rem;
            white-space: nowrap;
            @media only screen and (min-width: $nav-link-min) {
                padding: 1.9rem 1.25rem;
            }
        }

        .logo-home {
            padding: 0 1rem 0 0;
        }

        .nav-item {
            .nav-label:hover {
                padding: 0 0 0.875rem;
                border-bottom: 2px solid $nb-red;
            }
        }

        .nav-item.show {
            position: static;

            .nav-label {
                padding: 0 0 0.875rem;
                border-bottom: 2px solid $nb-red;
            }
        }

        .dropdown-toggle::after {
            content: none;
        }
    }

    .menu-flyout {
        @include media-breakpoint-down(md) {
            padding: 0;
            height: 100%;
            overflow: scroll;
        }

        @keyframes menuFadeIn {
            0% { opacity: 0; }
            100% { opacity: 1; }
        }

        animation: menuFadeIn 0.3s;

        > ul {
            display: flex;
            flex-wrap: wrap;

            .category-image {
                order: 2;
                margin-left: auto;
            }

            > li,
            .category-image {
                width: 100%;
            }

            li {
                padding: 0;

                a {
                    font-weight: $fontweight-semibold;
                }

                ul li a {
                    font-weight: $fontweight-light;
                }
            }

            .Featured .dropdown-link,
            .top-category a {
                font-weight: $fontweight-bold;
            }

            .dropdown {
                > .dropdown-link {

                    @include media-breakpoint-down(md) {
                        &::after {
                            @include caret-right();
                        }
                    }
                }
            }

            /* featured category changes */
            > li.Featured {
                > a {
                    display: none;
                }
                @include media-breakpoint-down(md) {
                    order: 1;
                    border: none;
                    padding-right: 0 !important;

                    .dropdown-menu {
                        position: relative;
                        left: 0;
                        padding: 0;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                .category-image {
                    width: 30%;
                }

                > li {
                    width: 15%;

                    &.Featured {
                        a {
                            font-weight: $fontweight-medium;
                        }

                        ul {
                            padding: 0;
                        }
                    }

                    &.use-partition {
                        border-left: 2px solid $alto-approx;
                    }

                    > a {
                        text-transform: uppercase;
                        font-size: $font-size12;
                        font-weight: $fontweight-semibold;
                        line-height: 1.25rem;
                        letter-spacing: 0.8px;
                    }

                    ul {
                        li {
                            padding: 0;
                            line-height: rem(32);

                            a {
                                line-height: rem(24);
                                font-weight: $fontweight-light;
                            }
                        }
                    }
                }
            }
        }

        .dropdown-toggle::after {
            content: none;
            right: -6px !important;
        }
    }
}

.nav-item.dropdown {
    button.dropdown-toggle {
        display: none;
        position: sticky;
        margin: rem(27) 0 0 rem(-24);
        height: 1.5rem;
        width: rem(24);
        line-height: 1.5rem;

        svg {
            padding: 0 rem(6) 0;
        }
    }
}

.keyboard-focus {
    .nav-item.dropdown {
        button.dropdown-toggle {
            display: block;
        }
    }
}

.main-menu.menu-toggleable-left {
    @include media-breakpoint-down($breakpoint-name) {
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: $white;
        z-index: 4;
    }
}

.menu-toggleable-left {
    .close-menu {
        width: 100%;
        background: white;
        padding: 16px;
        border-bottom: 1px solid $menu-border-color;
        flex: 0 0 100%;
        position: sticky;
        overflow: auto;
        z-index: 1;
        top: 0;

        @include media-breakpoint-up($next-breakpoint) {
            display: none;
        }
    }

    .dropdown-toggle {
        &::after {
            border: none;
        }
    }

    .menu-group {
        margin: auto;

        @include media-breakpoint-down($breakpoint-name) {
            max-height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            padding-top: 20px;
            flex: 0 0 100%;
        }
    }

    .bar {
        border-right: 2px solid $alto-approx;
    }

    li > .close-menu {
        margin-top: -0.6rem;
        position: sticky;
        width: 100%;
        overflow: auto;
        z-index: 1;
        top: 0;
        padding: 25px 17px 17px !important;
    }

    .close-menu {
        .back {
            .px-0 {
                line-height: 1;
                border-radius: 0.1875rem;
                padding: 7px 8px 6px !important;
            }
        }
    }

    @include media-breakpoint-down($breakpoint-name) {
        .bg-inverse {
            background-color: white !important;
            color: $black;
        }
    }

    &.in {

        @include media-breakpoint-down($prev-breakpoint) {
            right: 0;
        }

        @include media-breakpoint-down($breakpoint-name) {
            .menu-group > ul > li,
            .dropdown-item {
                border: 1px solid $menu-border-color;
                border-radius: 4px;
                margin-bottom: 10px;
            }

            .dropdown {
                display: block;
                position: static;
            }

            .country-selector {
                position: relative;
            }

            .dropdown-toggle {
                padding-left: 1rem;

                &::after {
                    @include caret-right();
                }

                &.country-selector {
                    &::after {
                        background-image: none;
                    }
                }
            }

            .nav-item .nav-link,
            .list-unstyled .dropdown-link {
                padding: 1rem;
                position: relative;
                line-height: 1.25rem;
                font-size: 1rem;
                color: $black;
                text-decoration: none;
                font-weight: 500 !important;
            }

            .nav-link {
                padding: 1rem !important;
            }

            .flyout-footer {
                .nav-link {
                    padding-top: 0.5rem !important;
                    padding-bottom: 0.5rem !important;
                }
            }

            .dropdown-menu {
                display: none;
            }

            .show > .dropdown-menu {
                right: 0;
                display: block;
                overflow: auto;
                padding-left: 19px;
                padding-right: 19px;

                .menu-flyout {

                    @include media-breakpoint-down(md) {
                        li.Featured {
                            .dropdown-menu {
                                display: block;
                            }
                        }
                    }

                    .dropdown-menu {
                        @include media-breakpoint-down(lg) {
                            .dropdown-menu {
                                position: fixed;
                            }
                        }
                    }
                }
            }

            .dropdown-menu {
                position: absolute;
                right: -100%;
                top: 0;
                width: 100%;
                height: 100%;
                border: 0 none;
                // transition: 0.3s $slide-out-animation;
                display: none;
                padding-top: 0.5rem;
                left: auto;

                .nav-menu {
                    padding: 1px 0 20px;
                    position: sticky;
                    top: 0;
                    z-index: 1;
                }
            }
        }
    }

    @include media-breakpoint-down($breakpoint-name) {
        .container {
            max-width: 100%;
        }
    }
}

.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:hover {
    background-color: transparent;
}

.multilevel-dropdown {
    .dropdown-menu {
        top: 100%;
        width: 100%;
        border: 0;
        border-radius: 0;
        margin: 0;
        padding: rem(32) 0 rem(64);

        .dropdown-item:hover {
            background: none;
        }

        @include media-breakpoint-up($next-breakpoint) {
            box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4);
        }
    }

    .dropdown-menu > .dropdown > .dropdown-menu {

        @include media-breakpoint-up($next-breakpoint) {
            top: -0.65em;
            left: 99%;
        }
    }

    .navbar > .close-menu > .back {
        display: none;
    }

    .close-menu .back {
        .caret-left {
            &::after {
                @include caret-left();
            }
        }
    }

    .dropdown-item {
        .dropdown-link {
            display: block;
        }
    }
}

.top-category {
    width: 100%;
    font-size: 1rem;
    font-weight: $fontweight-bold;
    pointer-events: none;
    color: $black;
    background: transparent;
    left: 0;
}

/* adding the additional styles here */

.main-menu {
    @include media-breakpoint-up(lg) {
        position: static !important;
    }
}

.nb-nav {
    @media screen and (min-width: $xxl) {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.navbar {
    .nav-link {
        font-size: $font-size16;
        font-weight: 300;
        font-family: $body-font-family;
        line-height: 1.25rem;
        color: #000 !important;
        @include media-breakpoint-down(sm) {
            font-weight: $fontweight-semibold;
        }
    }
}

.flyout-footer {
    .country-selector-one {
        display: none !important;
    }

    .nav-label,
    .user .user-message {
        font-size: $font-size16;
        font-weight: $fontweight-light;
        color: $black;
    }

    .nav-link.no-arrow {
        + .language::after {
            content: none;
        }
    }

    .arrow-right {
        right: 1rem;
    }

    @include media-breakpoint-down(md) {
        .country-selector {
            .dropdown-item {
                padding: 1rem;
                font-weight: $fontweight-light;
            }
        }

        .close-menu .language .nav-label {
            font-weight: $fontweight-bold;
        }
    }
}
