.dynamic-shipping-header {
    background-color: $light-grey;
    padding: 16px;
    margin-bottom: 16px;

    .shipping-header-trigger {
        cursor: pointer;

        &[aria-expanded="true"] {
            &::before {
                content: attr(data-show-less);
            }
        }

        &[aria-expanded="false"] {
            &::before {
                content: attr(data-show-more);
            }
        }
    }

    .multi-order-product-summary & {
        padding: 16px 0;
        margin-bottom: 0;
    }
}
