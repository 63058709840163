.hidden {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.modal-background {
    background-color: $black;
    display: none;
    height: 100%;
    position: fixed;
    opacity: 0.5;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
}

input[placeholder] {
    text-overflow: ellipsis;
}

.hide-order-discount {
    display: none;
}

.hide-shipping-discount {
    display: none;
}

.order-discount {
    color: $success;
}

.shipping-discount {
    color: $success;
}

.error-messaging {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
}

.error-hero {
    background-image: url('../images/storelocator.jpg');
    margin-bottom: 0.625em;
}

.error-message {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
}

.error.continue-shopping {
    margin-bottom: 6.25em;
}

.error-unassigned-category {
    color: $red;
}

.js-load-url {
    min-height: 12rem;
}

.skip {
    position: absolute;
    left: 0;
    top: -4.2em;
    overflow: hidden;
    padding: 1em 1.5em;
    background: $white;
    transition: all 0.2s ease-in-out;
}

a.skip:active,
a.skip:focus,
a.skip:hover {
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    z-index: 10000000;
    background: $white;
    transition: all 0.2s ease-in-out;
}

.card-header-custom {
    font-size: 1.5rem;
    margin-bottom: 0;
}

/* adding the additional common styles */

@mixin no-gutters() {
    margin-right: 0;
    margin-left: 0;
}

.no-gutter,
.no-gutters {
    @include no-gutters();
}

a,
a:hover {
    color: $black;
}

input[placeholder] {
    text-overflow: ellipsis;
}

.form-control:focus {
    box-shadow: none;
    border-color: $nb-black;
}

.nb-sprite {
    background: url('../images/nb-sprites.png');
    display: inline-flex;
}

.subscribe-title {
    h2 {
        font-size: $font-size24;
        line-height: 2rem;
        font-weight: bold !important;
    }
}

.subscribe-form {
    .form-control {
        font-size: $font-size14;
        line-height: 1.428;
        font-weight: $fontweight-semibold;
        color: $black;

        &::placeholder {
            color: $black;
        }
    }
}

.body-regular {
    @include body-regular;

    font-weight: 300;
}

.body-semibold {
    @include body-regular;

    font-weight: $fontweight-medium;
}

.body-bold {
    @include body-large-bold;

    font-weight: $fontweight-semibold;
}

.body-large {
    @include body-large;
}

.only-mobile {
    @include display-only-mobile;
}

.only-desktop {
    @include display-only-desktop;
}

.seo-text {
    max-width: 100%;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
        max-width: 65%;
    }

    .seo-content {
        font-size: 12px;
    }
}

.category-seo-text {
    .seo-headline {
        font-size: rem(14);
    }
    @include media-breakpoint-up(lg) {
        margin: 0 30%;

        &.hidefilter {
            margin: 0 auto;
        }
    }
}

.sticky-button-warpper {
    @include media-breakpoint-down(md) {
        position: fixed;
        bottom: 0;
        background: white;
        z-index: 99;
        width: 100%;
    }
}

/* PREORDER MESSAGING */
.preorder-cart-msg {
    background-color: #fee67a;
    font-weight: 300;
    padding: 10px 20px;

    .preorder-only-one-msg {
        font-weight: 700;
    }
}

/* COLOR SWATCHES */
.product-details,
.product-quickview {
    .color-style {
        display: flex;
        flex-flow: row wrap;
        width: auto;
    }

    .style-attribute {
        min-width: auto;
        height: 62px;
        border: 0 !important;
        border-radius: 0 !important;
        margin: 0 7px 7px 0 !important;

        @include media-breakpoint-down(md) {
            width: 58px;
            height: 58px;
            min-width: auto !important;
            margin: 0 5px 5px 0 !important;
            border: none !important;
        }
    }

    .style-value {
        width: 62px;
        height: 62px;
        display: inline-block;
        overflow: hidden;
        background-size: contain;

        @include media-breakpoint-down(md) {
            width: 58px;
            height: 58px;
        }
    }

    .select-attribute-grid {
        display: grid;
    }

    .attribute-grid-3 {
        grid-template-columns: repeat(3, 1fr);
    }

    .attribute-grid-5 {
        grid-template-columns: repeat(5, 1fr);
    }
}
