@mixin button(
    $bg: $white,
    $color: $black,
    $border-color: $black,
    $hover-bg: $white,
    $hover-color: $black,
    $hover-border-color: $black,
    $active-bg: $white,
    $active-color: $black,
    $active-border-color: $black
) {
    background-color: $bg;
    color: $color;
    border-color: $border-color;

    &:hover,
    &:focus {
        background-color: $hover-bg;
        color: $hover-color;
        border-color: $hover-border-color;
    }

    &:active {
        background-color: $active-bg;
        color: $active-color;
        border-color: $active-border-color;
    }
}
