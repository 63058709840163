@import "~base/variables";

$grid-gutter-width: 16px;

$grid-breakpoints: (
    xs: 0,
    sm: 544px,
    md: 769px,
    lg: 992px,
    xl: 1200px
);

$container-max-widths: (
    xs: 480px,
    sm: 767px,
    md: 993px,
    lg: 1290px,
    xl: 1440px
);

//Font sizes
$font-size-base: 0.875rem;
$base-font: 14px;
$font-size10: 0.625rem;
$font-size12: 0.75rem;
$font-size14: 0.875rem;
$font-size16: 1rem;
$font-size18: 1.125rem;
$font-size20: 1.25rem;
$font-size24: 1.5rem;
$font-size32: 2rem;
$font-size40: 2.5rem;
$font-size48: 3rem;
$font-size56: 3.5rem;
$font-size38: 2.375rem;
$font-14px: 0.875rem;

//Font weights
$fontweight-light: 300;
$fontweight-regular: 300;
$fontweight-medium: 500;
$fontweight-semibold: 500;
$fontweight-bold: 700;

//Modals
$modal-header-padding: 1rem;
$modal-footer-border-width: 0;

// Header Font Sizes
$h1-font-size: $font-size32;
$h2-font-size: $font-size24;
$h3-font-size: $font-size16;
$h4-font-size: $font-size14;
$h5-font-size: $font-size12;
$h6-font-size: $font-size12;

//buttons
$button-height: 56px;

//Spacers
$spacer: 1rem;
$spacers :(
    0: 0,
    1: ($spacer * (0.25)), //4px
    2: ($spacer * 0.5), //8px
    3: $spacer, //16px
    4: ($spacer * 1.5), //24px
    5: ($spacer * 3), //48px
    6: ($spacer * 2), //32px
    7: ($spacer * 2.5), //40px
    8: $spacer * 3.5, //56px
);

// These colors are the only exception to being in our palette, they're for the products not the site styles.

$swatch-colors: (
    'beige': #f5f5dc,
    'black': #0f141f,
    'blue': #3579ee,
    'brown': #a06c3a,
    'green': #4dc25b,
    'gray': #ccc,
    'navy': #3e5da9,
    'orange': #ff844b,
    'pink': #ff56a3,
    'offwhite': #ece0d4,
    'purple': #a368d5,
    'red': #fb4141,
    'white': $white,
    'tan': #dfc2a5,
    'yellow': #fff303,
    'silver':#b2b2b3,
    'gold': #ca981e,
);
