.mobile-homepage-quicklinks {
    @include media-breakpoint-down(md) {
        margin-top: $nb-spacing-25;
        margin-bottom: $nb-spacing-25;
    }

    h3 {
        font-size: $font-size18;
        font-weight: 500;
        line-height: 1.375rem;
        margin-bottom: 1rem;

        @include media-breakpoint-down(md) {
            font-size: $font-size14;
            line-height: 1.125rem;
            margin-bottom: 0.75rem;
        }
    }

    .mobile-homepage-quicklinks-ctn {
        column-gap: 0.5rem;

        a.mobile-homepage-button {
            margin-bottom: 0.5rem;
            border-radius: 4px;
            background-color: #f2f2f3;
            border: 0.125rem solid #f2f2f3;
            font-size: $font-size16;
            font-weight: 300;
            padding: 0.25rem 1rem;
            line-height: 1.5rem;

            @include media-breakpoint-down(md) {
                font-size: $font-size14;
                line-height: 1.25rem;
            }
        }
    }
}
