#change-country-modal {
    @include media-breakpoint-down(lg) {
        .modal-header {
            padding: 0;
        }

        .modal-content {
            padding-right: rem(16);
            padding-top: rem(16);
        }

        .modal-body {
            padding: 0;
        }
    }

    .country-logo {
        width: rem(120);
        @include media-breakpoint-down(lg) {
            width: rem(93);
        }
    }
}
