@import "../global/variables";
@import "bootstrap/scss/mixins/breakpoints";

/* mixins for reusable components */

@mixin body-regular {
    color: $black;
    font-size: $font-size14;
    line-height: 1.25rem;
    letter-spacing: 0;
}

@mixin body-large-bold {
    color: $black;
    font-size: $font-size16;
    line-height: 1.25rem;
    letter-spacing: 0;
}

@mixin body-large {
    color: $black;
    font-size: $font-size16;
    line-height: 1.5rem;
    letter-spacing: 0;
    font-weight: $fontweight-light;
}

@mixin display-only-mobile {
    @include media-breakpoint-up(lg) {
        display: none !important;
    }
}

@mixin display-only-desktop {
    @include media-breakpoint-down(md) {
        display: none !important;
    }
}

@include media-breakpoint-up(lg) {
    .pr-lg-25 {
        padding-right: rem(12) !important;
    }

    .mt-lg-55 {
        margin-top: rem(36) !important;
    }

    .mb-lg-55 {
        margin-bottom: rem(36) !important;
    }

    .my-lg-20 {
        margin-top: rem(160) !important;
        margin-bottom: rem(160) !important;
    }

    .p-lg-20 {
        padding: rem(160) !important;
    }

    .mt-lg-21 {
        margin-top: rem(168) !important;
    }

    .mb-lg-16 {
        margin-bottom: rem(128) !important;
    }

    .mb-lg-14 {
        margin-bottom: rem(104) !important;
    }

    .mt-lg-14 {
        margin-top: rem(104) !important;
    }

    .ml-lg-12 {
        margin-left: rem(88) !important;
    }

    .mt-lg-12 {
        margin-top: rem(88) !important;
    }

    .mb-lg-10 {
        margin-bottom: rem(72) !important;
    }

    .px-lg-10 {
        padding-left: rem(72) !important;
        padding-right: rem(72) !important;
    }

    .mb-lg-9 {
        margin-bottom: rem(64) !important;
    }

    .pt-lg-9 {
        padding-top: rem(64) !important;
    }

    .mt-lg-9 {
        margin-top: rem(64) !important;
    }

    .pr-lg-9 {
        padding-right: rem(64) !important;
    }

    .ml-lg-11 {
        margin-left: rem(80) !important;
    }
}

@include media-breakpoint-up(md) {
    .pb-md-16 {
        padding-bottom: rem(128) !important;
    }

    .my-md-10 {
        margin-bottom: rem(72) !important;
        margin-top: rem(72) !important;
    }

    .pt-md-9 {
        padding-top: rem(64) !important;
    }
}

.mb-25 {
    margin-bottom: rem(12) !important;
}

.mt-25 {
    margin-top: rem(12) !important;
}

.mt-14 {
    margin-top: rem(104) !important;
}

.mt-10 {
    margin-top: rem(72) !important;
}

.modal {
    &.show {
        .modal-content {
            .modal-body {
                .invalid-email {
                    .error {
                        color: $nb-red;
                    }
                }

                .subscribe-heading {
                    width: 100%;
                    height: 100%;
                    font-size: 1.5rem;
                    line-height: 2.5rem;
                }

                .hide {
                    display: none;
                }

                .subscribed {
                    &.show {
                        display: inline-block !important;
                    }
                }

                .subscriber-email {
                    border-bottom: 1px solid $black;
                }
            }
        }
    }
}

select {
    border-bottom: 1px solid $black;
    display: inline-block;
    font: inherit;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: 0;

    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $black;
    }

    &.minimal {
        background-image: none;
    }
}

// overriding bootstrap styles

@each $size in map-keys($grid-breakpoints) {
    @include media-breakpoint-down($size) {
        .collapsible-md {
            .title {
                &::after {
                    content: "" !important;
                }
            }
        }
    }
}

.triangle {
    position: absolute;
    left: 33%;
    top: 35%;

    &::after {
        content: '\25bc';
        font-size: 0.7rem;
    }
}

.email-content {
    .subscribed {
        font-weight: 700;
    }
}

a,
button,
h2,
input,
textarea,
select {
    outline: none;
    box-shadow: none;

    .form-group & {
        outline: none;
        box-shadow: none;
    }

    &:focus {
        outline: none;
        box-shadow: none;

        .form-group & {
            outline: none;
            box-shadow: none;
        }
    }
}

.btn:focus,
.btn.focus {
    box-shadow: none;
}

.mouse-focus {
    button,
    input[type=button],
    input[type=reset],
    input[type=submit] {
        outline: 0;
    }
}

.keyboard-focus {
    a,
    button,
    input,
    input[type=button],
    input[type=reset],
    input[type=submit],
    select,
    textarea {
        &:focus {
            outline: none;
            box-shadow: 0 0 0 2px $focus-outline-color, 0 0 0 4px $white;
        }
    }
}

@include media-breakpoint-up(lg) {
    .left-margin-lg {
        margin-left: -8px;
    }
}

.tooltip {
    font-weight: $fontweight-regular;
}
