//Bootstrap styles to be overridden here
.carousel {
    .carousel-control-prev,
    .carousel-control-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 99;
        width: 2.5rem;
        height: 2.5rem;
        background-color: $white;
        border-radius: 100%;
    }

    .carousel-control-prev {
        background-image: url('../images/left-icon.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 1.25rem;
    }

    .carousel-control-next {
        background-image: url('../images/right-icon.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 1.25rem;
    }

    .carousel-item {
        transition-duration: 0.4s;
    }
}
