// Forms
$form-feedback-font-size: $font-size10;
$form-feedback-margin-top: 0.5rem;
$input-padding-x: 0;

@import "~base/components/formFields";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: $nb-black !important;
    -webkit-box-shadow: 0 0 0 100px white inset;
}

textarea.form-control,
input.form-control,
.form-control {
    font-weight: $fontweight-medium;
    border: none;
    color: $black;
    border-radius: 0;
    border-bottom: 1px solid $nb-black;

    &:disabled,
    &[readonly] {
        background-color: $white;
        opacity: 1;
        pointer-events: none;
        touch-action: none;
    }

    &:focus {
        color: $black;
        border-bottom-color: $nb-black;
    }

    &.is-invalid:focus {
        box-shadow: none;//To override bootstrap styles
    }
}

label.form-control-label,
.form-control-label {
    color: $black;
}

.custom-checkbox {
    .custom-control-input + .custom-control-label {
        &::before {
            color: $white;
            border-color: none; // overriding bootstrap style
            background-color: transparent;
        }

        &::after {
            background-image: url('../../images/unselected.svg');
            background-size: cover;
        }
    }

    .custom-control-input:checked + .custom-control-label {
        &::after {
            background-image: url('../../images/selected.svg');
        }
    }

    .custom-control-input:disabled + .custom-control-label {
        &::after {
            background-color: $light-grey;
            background-image: none;
        }
    }
}

.custom-radio {
    position: relative;

    .form-check-input {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    .form-check-label {
        &::before {
            position: absolute;
            top: 0.25rem;
            left: -1.5rem;
            display: block;
            width: 1rem;
            height: 1rem;
            pointer-events: none;
            content: "";
            background-color: $white;
            border: transparent solid 1px;
        }

        &::after {
            position: absolute;
            top: 0.25rem;
            left: 0;
            display: block;
            content: "";
        }
    }

    .form-check-input + .form-check-label {
        &::after {
            background-image: url('../../images/inactive.svg');
            width: 16px;
            height: 16px;
            background-size: cover;
        }
    }

    .form-check-input:checked + .form-check-label {
        &::after {
            background-image: url('../../images/active.svg');
            width: 16px;
            height: 16px;
            background-size: cover;
        }
    }
}

// floating label styles
.form-group {
    position: relative;
}

.form-group > input,
> label {
    padding: var(--input-padding-y) var(--input-padding-x);
}

.form-group > label {
    position: absolute;
    top: 0.5rem;
    left: 0;
    display: block;
    //   width: 100%;
    font-size: $font-size14;
    font-weight: $fontweight-semibold;
    line-height: $font-size20;
    margin-bottom: 0; /* Override default `<label>` margin */
    transition: all 0.1s ease-in-out;
    pointer-events: none;
}

.form-group {
    input {
        &::-webkit-input-placeholder,
        &::placeholder {
            color: transparent;
        }

        &:focus,
        &:not(:placeholder-shown) {
            padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
            padding-bottom: calc(var(--input-padding-y) / 3);
            border-bottom: 1px solid $nb-black;
        }

        &:focus ~ label,
        &:not(:placeholder-shown) ~ label {
            padding-top: calc(var(--input-padding-y) / 3);
            padding-bottom: calc(var(--input-padding-y) / 3);
            font-size: $font-size10;
            color: $boulder;
            top: -0.7rem;
        }
    }

    .subtext {
        margin-top: 1rem;
        display: block;
    }

    &.required {
        .form-control-label {
            &::before {
                content: "";
            }
        }
    }

    &.select-dropdown {
        > label {
            top: -0.7rem;
            font-size: 0.714rem;
        }
    }

    .invalid-feedback {
        color: $nb-red;
    }

    .form-control {
        &.is-invalid {
            border-color: $nb-red;
            color: $nb-red;
        }
    }
}

.shipping-content,
.billing-information,
.giftcard-section,
.promo-content,
.store-finder-form,
.yamato-store-finder,
.contact-cs,
.login-content {
    .form-group {
        > label {
            top: 7px;
            font-size: 12px;
            padding-left: 10px;
            color: $boulder;
        }

        input {
            &:not([type=radio]) {
                &:not([type=checkbox]) {
                    &:focus {
                        border: 1px solid $nb-black;
                        padding-left: 10px;
                    }

                    &:focus ~ label {
                        color: $boulder;
                    }

                    &:not(:placeholder-shown) {
                        padding-left: 10px;
                        font-weight: $fontweight-semibold;
                    }

                    &:not(:placeholder-shown) ~ label {
                        color: $boulder;
                    }
                }
            }

            &:disabled {
                opacity: 0.4;

                ~ label {
                    opacity: 0.4;
                }
            }
        }
    }

    .form-control {
        border-bottom: none;
        border: 1px solid $silver-grey;
        padding-left: 10px !important;
    }

    input.form-control {
        border-bottom: none;
        border: 1px solid $silver-grey;
        padding-left: 10px !important;
    }

    .form-group-select {
        &::after {
            content: "";
            top: 15px;
            right: 15px;
        }
    }

    .form-group.required {
        .form-control-label {
            &::after {
                content: "*";
                color: $silver-grey;
            }
        }
    }
}
