// Base font, color setting
//Body themes
$body-bg: $white;
$body-color: $nb-black;
$font-family-base: $body-font-family;
$font-weight-base: $fontweight-light;
$line-height-base: 1.25;

body {
    font-family: $body-font-family;
    font-size: rem(14);
    color: $body-color;
    line-height: rem(16);
    background-color: $white;
    -webkit-font-smoothing: antialiased;
}

button {
    background: none;
    border: 0;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

// fix to stop zooming in for ios devices

@include media-breakpoint-down(md) {
    input[type='text'],
    input[type='number'],
    input[type='password'],
    input[type='tel'],
    input[type='email'],
    select.custom-select {
        font-size: $font-size16;
    }
}
