html[data-site="NBJP"],
html[data-site="JPOUTLET"] {
    body {
        $font-family-japanese: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Helvetica Neue", "メイリオ", "Meiryo", "Helvetica", Helvetica, Arial, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;

        &,
        * {
            font-family: $font-family-japanese !important;
        }

        .experience-commerce_assets-HeroComponent,
        .experience-commerce_assets-textImageCombinedComponent {
            .font-hero,
            .font-hero font,
            .didot,
            .didot font {
                font-family: $nb-page-heading-font !important;
            }
        }
    }
}
