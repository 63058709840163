@import "bootstrap/scss/mixins/breakpoints";
@import "../../../variables";

#header-rotating-banner {
    width: 100%;
    margin: 0 auto;
    color: $white;

    @include media-breakpoint-up(lg) {
        width: 100%;
    }

    .promo-view {
        display: inline;
        color: $white;
        font-size: 0.8rem;
        font-weight: 500;
        padding: 0;
        @include media-breakpoint-up(lg) {
            font-size: 0.875rem;
        }
    }
    // special class to hide element while still having space in DOM
    .hidden-slider {
        clip: rect(1px, 1px, 1px, 1px);
        clip-path: inset(50%);
        margin: -10000px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        visibility: hidden;
    }

    .slick-autoplay-toggle-button {
        display: none;
    }

    // Show it in the Page Designer iframe
    .experience-component.experience-commerce_assets-RotatingPromoBar[data-sfcc-pd-component-info] {
        clip: initial;
        clip-path: initial;
        height: initial;
        width: initial;
        margin: initial;
        overflow: initial;
        padding: initial;
        position: initial;
        visibility: visible;
    }

    // slick overrides
    .slick-slider {
        margin: 0 auto 10px;
        max-width: 450px;
        min-width: 320px;
        top: 7px;

        &.slick-dotted {
            top: 6px;
        }
    }

    .slick-arrow {
        top: calc(50% + 5px);
        z-index: 2;
        background: $white-smoke;
        height: 48px;

        .slick-prev-icon,
        .slick-next-icon {
            &::before {
                content: '';
                background-image: url(../images/left-icon-white.svg);
                height: rem(16);
                width: rem(16);
                position: absolute;
                transform: translateY(-50%) translateX(-50%);
                background-size: contain;
            }
        }

        .slick-next-icon {
            &::before {
                content: '';
                background-image: url(../images/right-icon-white.svg);
            }
        }

        &.slick-next {
            right: 0;
            @include media-breakpoint-up(lg) {
                right: -20px;
            }
        }

        &.slick-prev {
            left: 0;
            @include media-breakpoint-up(lg) {
                left: -20px;
            }
        }
    }

    .slick-dots {
        left: 0;
        bottom: -24px;

        li,
        li button {
            margin: 0;
            vertical-align: top;
            width: 12px;
            height: 25px;
            cursor: pointer;

            .slick-dot-icon {
                color: $white;
            }
        }

        li button::before {
            content: "";
        }

        li button .slick-dot-icon::before {
            width: initial;
            height: initial;
            font-size: 20px;
        }

        li button {
            &:hover {
                .slick-dot-icon {
                    opacity: 1;
                }
            }

            .slick-dot-icon {
                opacity: 0.5;
            }
        }

        li.slick-active button .slick-dot-icon {
            color: $white;
            opacity: 1;
        }
    }

    &.expanded {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 52;
        height: 36px;

        .promo-bar-details {
            display: block;
        }
    }

    .promo-details-container {
        width: 100% !important;
        margin: 0 auto;
        padding: 20px 0 0;
        position: fixed;
        background: $nb-black;
        max-height: calc(100% - 30px);
        overflow-y: scroll;
        overflow-x: hidden;
        left: 0;
        top: 48px;
        z-index: 1;
        scrollbar-width: none; /* so we don't see the scrollbar */
        -ms-overflow-style: none;  /* Internet Explorer 10+ */

        &::-webkit-scrollbar {
            display: none;  /* Safari and Chrome */
        }

        @include media-breakpoint-up(lg) {
            top: 0;
        }

        .row,
        .details-button-wrapper {
            width: calc(100vw - 50px);
            max-width: 400px;
            min-width: 320px;
            margin: 0 auto;
        }

        .slick-arrow {
            top: 14px;
            background: $nb-black;
        }

        .slick-next {
            right: -6vw;
        }

        .slick-prev {
            left: -6vw;
        }

        .slick-prev-icon,
        .slick-next-icon {
            &::before {
                content: '';
                background-image: url(../images/left-icon-white.svg);
                height: rem(16);
                width: rem(16);
                position: absolute;
                transform: translateY(-50%) translateX(-50%);
                background-size: contain;
            }
        }

        .slick-next-icon {
            &::before {
                content: '';
                background-image: url(../images/right-icon-white.svg);
            }
        }

        .nb-button {
            padding: 0 10px;
            height: 48px;
            line-height: 46px;
            overflow: hidden;
            white-space: nowrap;
            border-radius: 0.4rem;
            border: 1px solid #bec0c3;
            background-color: $white;
        }

        .banner-close {
            display: none;
        }

        .row {
            @include media-breakpoint-up(lg) {
                max-width: 100rem;
                position: relative;
                display: flex;
                flex-flow: row wrap;
                align-content: flex-start;
                justify-content: space-between;
                align-items: stretch;
                padding: 20px 0;
                gap: 20px;

                .promo-bar-details {
                    border-right: 1px solid $white;
                    padding-right: 20px;
                    box-sizing: border-box;
                    flex: 1 1 22%;

                    .banner-close {
                        display: block;
                        right: 1vw;
                    }

                    &:nth-of-type(4),
                    &:last-of-type,
                    &:only-of-type {
                        border-right: none;
                    }

                    .details-button-wrapper,
                    .row {
                        min-width: 1px;
                        width: initial;
                        padding: 0;
                    }

                    .details-button-wrapper {
                        text-align: center;
                        margin: 20px 15px;

                        @include media-breakpoint-up(lg) {
                            text-align: left;
                            margin: 20px 0;
                        }
                    }
                }
            }

            @include media-breakpoint-up(xl) {
                gap: 40px;
                padding: 40px;

                .promo-bar-details {
                    padding-right: 40px;
                }
            }
        }
    }

    .promo-details-close {
        display: block;
        margin: 20px auto 0;
        text-align: center;
        padding: 0 0 20px;
        color: $white;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    .details-body {
        text-align: left;
        color: $black;

        .details-body-headline {
            display: none;

            @include media-breakpoint-up(lg) {
                display: block;
                font-weight: 700;
            }
        }

        ul {
            list-style: disc inside;
        }

        p {
            margin-bottom: 0.5rem;
        }

        span,
        p,
        a,
        li,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6 {
            color: $white !important;
        }
    }
}

#Promo_banner_details_overlay {
    display: none;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 51;

    &.details-open {
        display: block;
    }
}
