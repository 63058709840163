@import "~base/components/pricing";

.price {
    color: $black;

    &.product-price {
        .experience-component & {
            white-space: nowrap;
        }
    }

    .sales {
        font-weight: $fontweight-bold;
        font-size: rem(18);

        @include media-breakpoint-down(md) {
            font-size: rem(16);
            line-height: rem(20);
        }

        &.discount {
            > .value {
                font-weight: normal;
            }
        }
    }

    .savings {
        color: $red;
        font-weight: $fontweight-semibold !important;
        display: inline;

        .percentOff {
            // color: $grey4;
        }
    }
}

.afterpay-pdp-wrapper {
    .price-value {
        .savings {
            display: none !important;
        }
    }
}

.flex-reverse {
    @include media-breakpoint-down(md) {
        display: flex;
        flex-flow: column-reverse;
    }
}

.pgp-grid {
    .price-value {
        .savings {
            .percentOff {
                display: none;
            }
        }
    }
}

.discount.percentOff {
    color: $red;
    font-weight: $fontweight-semibold !important;
    display: block;
    font-size: 0.75rem;
}

.product-tile {
    .promotions {
        .title.callout {
            color: $red;
            font-weight: $fontweight-semibold !important;
            display: block;
        }
    }
}
