$timerPDPBot: 992px;
$timerPDPTop: 1300px;

.timer-countdown {
    display: flex;
    margin: 2rem 0;

    .timerContainer {
        width: auto;
        margin-right: 10px;
        text-align: center;
        font-size: rem(48);
        line-height: 0.7em;

        .label {
            font-weight: 400;
            font-size: 0.3em;
            line-height: 2.5em;
            text-transform: uppercase;
            text-align: left;
        }
    }
}

.experience-component {
    .timer-countdown-container {
        .timer-description {
            display: block !important;
        }

        p {
            font-weight: 700;
            font-size: rem(24);
            margin-top: 1rem;
        }

        .hrItem {
            display: block;
            width: 75px;
            height: 1px;
            border: 0;
            border-top: 4px solid #cf0a2c;
            margin: 1em 0 2em;
            padding: 0;
        }
    }

    .post-launch-banner {
        .hrItem {
            display: none;
        }

        .cta-pre-launch {
            display: none;
        }

        .cta-post-launch {
            display: inline-block !important;
        }
    }
}

.product-detail {
    .timer-countdown-container {
        padding-top: 20px;

        .timer-countdown {
            margin-top: 0;

            @media only screen and (min-width: $timerPDPBot) and (max-width: $timerPDPTop) {
                .timerContainer {
                    font-size: rem(35);

                    .label {
                        font-size: 0.4em;
                    }
                }
            }
        }

        @include media-breakpoint-up(lg) {
            padding-top: 10px;
        }

        @include media-breakpoint-down(md) {
            p {
                margin-bottom: rem(10);
            }

            .timer-countdown {
                margin-bottom: 0;
            }
        }
    }
}

#productDetails {
    .timer-countdown-container {
        .timer-description {
            .hrItem {
                display: none;
            }
        }
    }
}
